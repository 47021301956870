<div class="d-flex">
  <div class="document-buttons">
    <div id="btn-general" class="document-button general selected">
      <button type="submit" class="btn-documents">
        <i class="icon-user"></i>
        {{ "forms.buttons.new" | translate }}
      </button>
    </div>
  </div>

  <div class="card w-100 form-right">

    <div class="back">
      <div class="div-title">
        <h2 class="">
          {{ "forms.names.addClient" | translate }}
        </h2>
      </div>
      <i class="icon-cross2" (click)="isAssigneeMode ? emitBackButtonClick2() : emitBackButtonClick()"></i>
    </div>

    <div class="card-body" [ngSwitch]="step">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-section mb-3" *ngSwitchCase="1">

          <div class="row" *ngIf="showAlertFilter">
            <div class="col-12">
              <div class="" role="alert">
                <div *ngIf="taxIdExists" class="alert alert-warning mt-2">
                  {{ "forms.inputs.tax_id_duplicated" | translate }} <a class="redirect-tax-id"
                    (click)="onDuplicateFound()">aquí</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-3">
                <label for="tax_id" class="form-label">
                  {{ "forms.inputs.tax_id" | translate }} <span class="required">*</span>
                </label>
                <input id="tax_id" class="form-control" formControlName="tax_id"
                  placeholder="{{ 'forms.inputs.tax_id' | translate }}" (input)="onTaxIdChange()" />
              </div>
            </div>
          </div>

          <div *ngIf="!showAdditionalFields">
            <div class="divider-container">
              <hr class="divider-line" />
              <span class="divider-text">{{ "forms.inputs.or_if_you_prefer" | translate }}</span>
              <hr class="divider-line" />
            </div>

            <div>
              <div *ngIf="uploadFile">
                <div class="form-section">
                  <span>{{ "forms.labels.create_client_with_documents" | translate }}</span>

                  <div class="drop-zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)"
                    (dragleave)="onDragLeave($event)" (click)="fileInput.click()">
                    {{ "forms.labels.draganddrop" | translate }}
                    <input #fileInput type="file" multiple style="display: none" (change)="onFileInputChange($event)" />
                  </div>
                </div>

                <ul *ngIf="files.length > 0">
                  <li *ngFor="let file of files; let i = index">
                    <div class="listFiles">
                      <div class="div-thumbnail">
                        <img class="thumbnail" [src]="getThumbnailSrc(file.file)" alt="Thumbnail" />
                        {{ file.file.name }}
                        <span (click)="removeFile(i)">
                          <i class="icon-trash"></i>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Botones para continuar -->
          <div *ngIf="canProceed && !showAdditionalFields" class="button-container">
            <button class="btn btn-primary" (click)="onNextStep()" [disabled]="taxIdExists"> {{"buttons.next_step" |
              translate }}</button>
          </div>

        </div>

        <!-- Segundo paso: Formulario con los campos existentes -->
        <div *ngIf="showAdditionalFields">
          <div class="form-section mb-3 pb-3">

            <div class="row mb-2">
              <div class="col-12">
                <div class="mb-3">
                  <label for="type" class="form-label">{{ "forms.inputs.type" | translate }}
                    <span class="required">*</span>
                  </label>
                  <div class="position-relative">
                    <select id="type" class="form-control" formControlName="type">
                      <option value="" disabled selected>{{ "forms.inputs.type" | translate }}</option>
                      <option *ngIf="!isAssigneeMode" value="organization">{{ "forms.inputs.company" | translate }}
                      </option>
                      <option value="individual">{{ "forms.inputs.individual" | translate }}</option>
                      <option value="self-employed">{{ "forms.inputs.employee" | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row linea"></div>


            <div class="mb-3">
              <div class="row">
                <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
                  <div class="mb-3">
                    <label for="vendor" class="form-label">{{ "forms.inputs.name" | translate }}
                      <span class="required">*</span>
                    </label>
                    <input id="vendor" class="form-control" formControlName="name"
                      placeholder="  {{ 'forms.inputs.name' | translate }}" />
                  </div>
                </div>
                <div class="col-6" *ngIf="form.get('type')?.value !== 'organization'">
                  <div class="mb-3">
                    <label for="supplier" class="form-label">{{ "forms.inputs.lastname" | translate }}</label>
                    <input id="lastname" class="form-control" formControlName="lastname"
                      placeholder=' {{ "forms.inputs.lastname" | translate }}' />
                  </div>
                </div>
              </div>

              <div class="row ">
                <div class="col-12" *ngIf="form.get('type')?.value === 'organization'">
                  <div class="mb-3">
                    <label for="vendor" class="form-label">{{ "forms.inputs.name" | translate }}
                      <span class="required">*</span>
                    </label>
                    <input id="vendor" class="form-control full-width" formControlName="name"
                      placeholder=" {{ 'forms.inputs.name' | translate }}" />
                  </div>
                </div>
              </div>

            </div>

            <div class="row linea" *ngIf="form.get('type')?.value !== 'organization'"></div>

            <div class="row mb-2" *ngIf="form.get('type')?.value !== 'organization'">
              <div class="col-12">
                <div class="mb-3">
                  <label for="birth_date" class="form-label">
                    {{ "forms.inputs.birth_date" | translate }}
                  </label>
                  <div class="calendar-container">
                    <mat-form-field appearance="fill" class="calendar-main">
                      <input matInput [matDatepicker]="birthDatepicker" formControlName="birth_date"
                        placeholder="{{ 'forms.inputs.birth_date' | translate }}" (dateChange)="onDateChange($event)"
                        [min]="minDate" [max]="maxDate">
                      <mat-datepicker-toggle matSuffix [for]="birthDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #birthDatepicker></mat-datepicker>
                    </mat-form-field>
                    <div *ngIf="form.hasError('birthDateRequired') && form.touched">
                      <small class="text-danger">
                        La fecha de nacimiento es requerida
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-4">
                  <div class="mb-3">
                    <label for="vendor" class="form-label">{{ "forms.inputs.birthplace_country" | translate }}
                    </label>
                    <app-select-country-prefix [showPhonePrefix]="false" [selectionMode]="'name'"
                      [selectedCountryName]="form.get('birthplace_country')?.value"
                      (changeBirthplace)="changeBirthplace($event)"
                      (countrySelected)="onBirthplaceCountrySelected($event)">
                    </app-select-country-prefix>
                  </div>
                </div>
                <div class="col-8">
                  <div class="mb-3">
                    <label for="supplier" class="form-label">{{ "forms.inputs.nationality" | translate }}</label>
                    <input id="lastname" class="form-control" formControlName="nationality"
                      placeholder=' {{ "forms.inputs.nationality" | translate }}' />
                  </div>
                </div>
              </div>
            </div>


            <div *ngIf="form.get('type')?.value !== 'organization'" class="row linea"></div>
            <div class="row mb-2" *ngIf="form.get('type')?.value !== 'organization'">

              <div class="col-12">
                <div class="mb-3 ">
                  <label for="birth_date" class="form-label">{{'forms.inputs.tax_id_expiration_date' | translate }}
                  </label>
                  <div class="calendar-container">
                    <mat-form-field appearance="fill" class="calendar-main">
                      <input matInput [matDatepicker]="birthDatepicker" formControlName="document_expiration_date"
                        placeholder="{{'forms.inputs.tax_id_expiration_date' | translate }}"
                        (dateChange)="onDateChange2($event)" [min]="minDate" [max]="maxDateExpiration">

                      <mat-datepicker-toggle matSuffix [for]="birthDatepicker"></mat-datepicker-toggle>
                      <mat-datepicker #birthDatepicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="form.get('type')?.value === 'organization'">
              <div class="row linea"></div>
              <div class="row mb-2">
                <div class="col-12">
                  <div class="mb-3">

                    <label for="client" class="form-label">{{ "forms.inputs.associateClient" | translate }}</label>

                    <ng-select #clientSelector [items]="filteredClients" bindLabel="displayName" bindValue="reference"
                      formControlName="assignee_id" [searchable]="true" placeholder="{{ 'forms.inputs.associateClient' | translate }}"
                      [clearable]="true" class="select-client-associated" [typeahead]="search$" (search)="onSearch($event)"
                      [virtualScroll]="true" [hideSelected]="true"
                      [ngClass]="{'has-value': form.get('assignee_id')?.value}"
                      (open)="reloadClientsList()"
                      >
                    </ng-select>

                  </div>
                  <div class="input-group justify-content-end">
                    <div class="clear">
                      <button class="btn btn-hover clear-button  ml-15" type="button"
                        (click)="openAssigneeCreateForm()">
                        {{"forms.inputs.create_assignee" | translate }} <i class="icon-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row linea"></div>
            <div class="row mb-2">
              <div class="col-12">
                <div class="mb-3">
                  <label for="supplier" class="form-label">{{ "forms.inputs.email" | translate }}
                    <span class="required">*</span></label>
                  <input id="email" class="form-control" formControlName="email"
                    placeholder=' {{ "forms.inputs.email" | translate }}' />
                </div>
                <div *ngIf="isFieldInvalid('email')">
                  <small *ngIf="getFormError('email', 'required')" class="error_validation">El correo es
                    obligatorio.</small>
                  <small *ngIf="getFormError('email', 'email')" class="error_validation">El formato del correo es
                    inválido.</small>
                </div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-4">
                <div class="mb-3">
                  <label for="phone_prefix" class="form-label">{{ "forms.inputs.phone_prefix" | translate }}
                    <span class="required">*</span>
                  </label>
                  <app-select-country-prefix (changePrefix)="changePrefix($event)"
                    [selectedPrefix]="form.get('phonePrefix')?.value"
                    [selectionMode]="'prefix'"></app-select-country-prefix>
                </div>
              </div>

              <div class="col-8">
                <div class="mb-3">
                  <label for="phone" class="form-label">{{ "forms.inputs.phone" | translate }}
                    <span class="required">*</span>
                  </label>
                  <input id="phone" class="form-control phone-number" formControlName="phoneNumber" type="tel"
                    placeholder="{{ 'forms.inputs.phone' | translate }}" (input)="onInput($event)" />
                </div>
                <div *ngIf="form.hasError('invalidPhoneNumber') && form.get('phoneNumber')?.touched">
                  <small class="error_validation">Si el prefijo es +34, el número debe tener 9 dígitos.</small>
                </div>

                <div *ngIf="isFieldInvalid('phoneNumber')">
                  <small class="error_validation">El teléfono debe tener 9 dígitos, o si empieza con +34, debe tener 9
                    dígitos adicionales.</small>
                </div>
              </div>
            </div>

            <div class="row linea"></div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="billing_address" class="form-label">{{
                    "forms.inputs.billing_address" | translate
                    }}
                    <span class="required">*</span></label>
                  <input id="billing_address" class="form-control" formControlName="billing_address" placeholder="{{
                                          'forms.inputs.billing_address' | translate
                                        }}" />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="billing_state" class="form-label">{{
                    "forms.inputs.billing_state" | translate
                    }}<span class="required">*</span></label>
                  <input id="billing_state" class="form-control" formControlName="billing_state" placeholder="{{
                                                          'forms.inputs.billing_state' | translate
                                                        }}" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="name" class="form-label">{{
                    "forms.inputs.billing_postal_code" | translate
                    }}<span class="required">*</span></label>
                  <input id="name" class="form-control" formControlName="billing_postal_code" placeholder="{{
                                          'forms.inputs.billing_postal_code' | translate
                                        }}" />
                </div>
                <div *ngIf="isFieldInvalid('billing_postal_code')">
                  <small class="error_validation">El codigo postal debe tener 4 o 5 números</small>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="lastname" class="form-label">{{
                    "forms.inputs.billing_city" | translate
                    }}<span class="required">*</span></label>
                  <input id="lastname" class="form-control" formControlName="billing_city" placeholder="{{
                                          'forms.inputs.billing_city' | translate
                                        }}" />
                </div>
              </div>
            </div>

          </div>

          <div class="button-create-container">
            <button type="submit" class="btn btn-primary btn-primary-form create-button" [disabled]="taxIdExists">
              {{ "buttons.create_client" | translate }}
            </button>
          </div>
          <!-- </form> -->
        </div>
      </form>
    </div>

  </div>

</div>
