<form [formGroup]="deliveryForm">
  <div class="form-section">
    <div class="row d-flex">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_urgency" class="form-label">{{
            "forms.inputs.delivery_urgency.delivery_urgency" | translate
            }}</label>
          <select id="delivery_urgency" class="form-control" formControlName="delivery_urgency">
            <option *ngFor="let urgency of delivery_urgency_" [value]="urgency">
              {{ "forms.inputs.delivery_urgency." + urgency | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_client_expected_date" class="form-label">{{ "forms.inputs.delivery_client_expected_date"
            | translate }}
          </label>
          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="delivery_client_expected_date"
              formControlName="delivery_client_expected_date" placeholder="{{
                'forms.inputs.delivery_client_expected_date' | translate
              }}" />
            <mat-datepicker-toggle matSuffix [for]="delivery_client_expected_date"></mat-datepicker-toggle>
            <mat-datepicker #delivery_client_expected_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_contact" class="form-label">{{
            "forms.inputs.delivery_contact" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="delivery_contact" required
              formControlName="delivery_contact" placeholder="{{
                'forms.inputs.delivery_contact' | translate
              }}" #inputFieldDeliveryContact />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldDeliveryContact.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_phone" class="form-label">{{
            "forms.inputs.delivery_phone" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="delivery_phone" required formControlName="delivery_phone"
              placeholder="{{
                'forms.inputs.delivery_phone' | translate
              }}" #inputFieldDeliveryPhone />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldDeliveryPhone.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row linea"></div>

    <div class="row d-flex align-items-end">


      <div class="col-12">
        <div class="mb-3">
          <label for="delivery_address" class="form-label">{{
            "forms.inputs.delivery_address" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="delivery_address" required formControlName="delivery_address"
              placeholder="{{
                'forms.inputs.delivery_address' | translate
              }}" #inputFieldDeliveryAddress />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldDeliveryAddress.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_postal_code" class="form-label">{{
            "forms.inputs.delivery_postal_code" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="delivery_postal_code" required
              formControlName="delivery_postal_code" placeholder="{{
                'forms.inputs.delivery_postal_code' | translate
              }}" #inputFieldDeliveryCP />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldDeliveryCP.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_city" class="form-label">{{
            "forms.inputs.delivery_city" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="delivery_city" required formControlName="delivery_city"
              placeholder="{{
                'forms.inputs.delivery_city' | translate
              }}" #inputFieldDeliveryCity />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldDeliveryCity.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row linea"></div>

    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="manufacture_expected_date" class="form-label">{{
            "forms.inputs.manufacture_expected_date" | translate
            }}</label>
          <div class="input-group div-copy">
            <input type="text" class="form-control" id="manufacture_expected_date" required
              formControlName="manufacture_expected_date" placeholder="{{
                'forms.inputs.manufacture_expected_date' | translate
              }}" #inputFieldManufactureExpected />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary copy" type="button"
                (click)="copyToClipboard(inputFieldManufactureExpected.value,$event)">
                <i class="icon-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_status" class="form-label">{{
            "forms.inputs.delivery_status" | translate
            }}</label>
          <select id="delivery_status" class="form-control" formControlName="delivery_status">
            <option value="" disabled selected>
              {{ "forms.inputs.delivery_status" | translate }}
            </option>
            <option *ngFor="let status of delivery_status" [value]="status.key">
              {{ status.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="workshop" class="form-label">{{
            "forms.inputs.workshop" | translate
            }}</label>
          <select id="workshop_id" class="form-control" formControlName="workshop_id">
            <option value="" disabled selected>
              {{ "forms.inputs.workshop" | translate }}
            </option>
            <option *ngFor="let workshop of workshopData" [value]="workshop.reference">
              {{ workshop.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row linea"></div>

    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_place" class="form-label">{{
            "forms.inputs.delivery_place" | translate
            }}</label>
          <select id="delivery_place" class="form-control" formControlName="delivery_place">
            <option value="" disabled selected>
              {{ "forms.inputs.delivery_place" | translate }}
            </option>
            <option *ngFor="let place of delivery_place" [value]="place.key">
              {{ place.name }}
            </option>
          </select>
        </div>
      </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="delivery_dealer" class="form-label">{{
              "forms.inputs.delivery_dealer" | translate
              }}</label>
            <input type="text" class="form-control" id="delivery_dealer" required
              formControlName="delivery_dealer" placeholder="{{
                          'forms.inputs.delivery_dealer'
                            | translate
                        }}" />
          </div>
        </div>
    </div>
    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_first_expected_date" class="form-label">{{
            "forms.inputs.delivery_first_expected_date"
            | translate
            }}<span class="required">*</span></label>
            <mat-form-field appearance="fill">
              <input matInput [matDatepicker]="delivery_first_expected_date" formControlName="delivery_first_expected_date"
                placeholder="{{
                  'forms.inputs.delivery_first_expected_date' | translate
                }}" />
              <mat-datepicker-toggle matSuffix [for]="delivery_first_expected_date"></mat-datepicker-toggle>
              <mat-datepicker #delivery_first_expected_date></mat-datepicker>
            </mat-form-field>
         <!--  <input type="text" class="form-control" id="delivery_first_expected_date" required
            formControlName="delivery_first_expected_date" placeholder="{{
              'forms.inputs.delivery_first_expected_date'
                | translate
            }}" /> -->
        </div>
      </div>
    </div>

    <div class="row d-flex align-items-end">
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_expected_date" class="form-label">{{ "forms.inputs.delivery_expected_date"
            | translate
            }}<span class="required">*</span></label>

          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="delivery_expected_date" formControlName="delivery_expected_date"
              placeholder="{{
                'forms.inputs.delivery_expected_date' | translate
              }}" />
            <mat-datepicker-toggle matSuffix [for]="delivery_expected_date"></mat-datepicker-toggle>
            <mat-datepicker #delivery_expected_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="delivery_date" class="form-label">{{ "forms.inputs.delivery_date" | translate }}
          </label>
          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="delivery_date" formControlName="delivery_date" placeholder="{{
                'forms.inputs.delivery_date' | translate
              }}" />
            <mat-datepicker-toggle matSuffix [for]="delivery_date"></mat-datepicker-toggle>
            <mat-datepicker #delivery_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row linea"></div>

    <div class="row">
      <div class="mb-3">
        <label for="delivery_delay_notes" class="form-label">{{
          "forms.inputs.delivery_delay_notes" | translate
          }}</label>
        <textarea class="form-control textarea100" id="delivery_delay_notes" rows="2" required
          formControlName="delivery_delay_notes"></textarea>
      </div>
    </div>

  </div>
</form>
