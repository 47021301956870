import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { MatInputModule } from '@angular/material/input';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../core/services/model/model.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent {
  accountType: string | null = null;
  searchParams: any = {};
  searchTerms = new Subject<string>();
  vendorAccounts: any[] = [];

  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];
  @Input() type: any = [];

  date_typeData: any[] = [
    { key: 'absolute', name: 'Absoluta', selected: false },
    { key: 'relative', name: 'Relativa', selected: false }
  ];

  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private modelService: ModelService
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngAfterViewInit() {
    this.searchTerms
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.search();
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['filter']) {
        let filter = params['filter'];
        if (filter) {
          this.localStorageService.setItem('searchParams', filter);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.fetchAllData();
      } else {
        this.fetchAllData();
      }
    }
  }

  private fetchAllData() {
    Promise.all([
      this.searchStatus(),
      this.searchSupplier(''),
      this.searchBroker(''),
      this.searchBrands(''),
      this.searchVendor(''),
    ]).then(() => {
      this.preCargarFiltros();
    });
  }


  preCargarFiltros(): void {
    this.displayTextDateType = "Absoluta";
    this.dateTypeSearchTerm = "Absoluta";
    this.optionsVisibleDateType = false;
    this.clearableDateType = true;
    this.filterValues.dateType = "absolute";
    this.selectedFilters.add('dateType');

    this.displayTextStage = "Enviado";
    this.stageSearchTerm = "Enviado";
    this.optionsVisibleStage = false;
    this.clearableStage = true;
    this.filterValues.stage = "sent";
    this.selectedFilters.add('stage');
    this.search();
  }

  search(): void {
    this.searchParams = {};
    if (this.filterValues.startDate) {
      this.searchParams['start_date'] = `${this.filterValues.startDate}`;
    }
    if (this.filterValues.endDate) {
      this.searchParams['end_date'] = `${this.filterValues.endDate}`;
    }
    if (this.filterValues.dateType) {
      this.searchParams[
        'date_type'
      ] = `${this.filterValues.dateType}`;
    }

    if (this.filterValues.supplier) {
      this.searchParams[
        'supplier_reference_id[eq]'
      ] = `${this.filterValues.supplier}`;
    }

    if (this.filterValues.broker) {
      this.searchParams[
        'broker_reference_id[eq]'
      ] = `${this.filterValues.broker}`;
    }

    if (this.filterValues.vehicle_brand) {
      this.searchParams[
        'vehicle_brand[like]'
      ] = `%${this.filterValues.vehicle_brand}%`;
    }
    if (this.filterValues.vehicle_model) {
      this.searchParams[
        'vehicle_model[like]'
      ] = `%${this.filterValues.vehicle_model}%`;
    }


    if (this.filterValues.status) {
      this.searchParams['vehicle_status[eq]'] = `${this.filterValues.status}`;
    }

    if (this.filterValues.vendor) {
      this.searchParams[
        'vendor_reference_id[eq]'
      ] = `${this.filterValues.vendor}`;
    }
    if (this.filterValues.type) {
      this.searchParams[
        'order_type[in]'
      ] = `${this.filterValues.type}`;
    }
    if (this.filterValues.stage) {
      this.searchParams[
        'stage_for_date'
      ] = `${this.filterValues.stage}`;
    }

    let store_searchParams = this.searchParams;
    this.localStorageService.setItem(
      'searchParamsReports',
      JSON.stringify(store_searchParams).replace('%', '').replace('%', '')
    );

    this.emitEvent();
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

  filterValues: {
    broker: string | null;
    stage: string | null;
    supplier: string | null;
    startDate: string | null;
    endDate: string | null;
    vehicle_brand: string | null;
    vehicle_model: string | null;
    status: string | null;
    vendor: string | null;
    type: string | null;
    dateType: string | null;
  } = {
      broker: null,
      stage: null,
      supplier: null,
      startDate: null,
      endDate: null,
      vehicle_brand: null,
      vehicle_model: null,
      status: null,
      vendor: null,
      type: null,
      dateType: null,
    };

  optionsVisibleStage: boolean = false;
  optionsVisibleBroker: boolean = false;
  optionsVisibleSupplier: boolean = false;
  optionsVisibleBrand: boolean = false;
  optionsVisibleModel: boolean = false;
  optionsVisibleStatus: boolean = false;
  optionsVisibleVendor: boolean = false;
  optionsVisibleType: boolean = false;
  optionsVisibleDateType: boolean = false;


  stageSearchTerm = '';
  brokerSearchTerm = '';
  supplierSearchTerm = '';
  brandSearchTerm = '';
  modelSearchTerm = '';
  statusSearchTerm = '';
  vendorSearchTerm = '';
  typeSearchTerm = '';
  dateTypeSearchTerm = '';

  displayTextSupplier: string = '';
  displayTextStage: string = '';
  displayTextBroker: string = '';
  displayTextBrand: string = '';
  displayTextModel: string = '';
  displayTextStatus: string = '';
  displayTextVendor: string = '';
  displayTextType: string = '';
  displayTextDateType: string = '';

  clearableStage = false;
  clearableBroker = false;
  clearableSupplier = false;
  clearableStartDate = false;
  clearableEndDate = false;
  clearableBrand = false;
  clearableModel = false;
  clearableStatus = false;
  clearableVendor = false;
  clearableType = false;
  clearableDateType = false;

  filteredStages: any[] = [
    { key: 'sent', name: 'Enviado', selected: false },
    { key: 'risk_started', name: 'Riesgo', selected: false },
    { key: 'risk_approved', name: 'Riesgo Aprobado', selected: false },
    { key: 'contract_signed', name: 'Contrato firmado', selected: false },
    { key: 'vehicle_delivered', name: 'Vehiculo Entregado', selected: false },
  ];
  types: any[] = [
    { key: 'renting', name: 'Renting', selected: false },
    { key: 'leasing', name: 'Flex', selected: false },
  ];

  brokerAccounts: any[] = [];
  supplierAccounts: any[] = [];

  selectedSupplier: string = '';
  selectedBroker: string = '';
  selectedVendor: string = '';
  selectedType: string = '';
  selectedFilters: Set<string> = new Set();

  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {

      case 'dateType':
        this.optionsVisibleDateType = true;
        break;
      case 'supplier':
        this.optionsVisibleSupplier = true;
        break;
      case 'broker':
        this.optionsVisibleBroker = true;
        break;
      case 'brand':
        this.optionsVisibleBrand = true;
        break;
        case 'model':
          this.optionsVisibleModel = true;
          break;
      case 'status':
        this.optionsVisibleStatus = true;
        break;
      case 'stage':
        this.optionsVisibleStage = true;
        break;
      case 'vendor':
        this.optionsVisibleVendor = true;
        break;
      case 'type':
        this.optionsVisibleType = true;
        break;
    }
  }

  selectOption(
    reference: string,
    name: string,
    filterType: string,
    inputElement: HTMLInputElement
  ): void {
    if (filterType === 'supplier') {
      this.selectedSupplier = reference;
      this.displayTextSupplier = name;
      this.optionsVisibleSupplier = false;
      this.filterValues.supplier = reference;
      this.selectedFilters.add('supplier');
      this.clearableSupplier = true;
    } else if (filterType === 'start_date') {
      this.clearableStartDate = true;
    } else if (filterType === 'vendor') {
      this.selectedVendor = reference;
      this.displayTextVendor = name;
      this.optionsVisibleVendor = false;
      this.filterValues.vendor = reference;
      this.clearableVendor = true;
      this.selectedFilters.add('vendor');
    }
    inputElement.blur();
    this.search();
  }

  hideOptions(): void {
    this.optionsVisibleStage = false;
    this.optionsVisibleSupplier = false;
    this.optionsVisibleBroker = false;
    this.optionsVisibleBrand = false;
    this.optionsVisibleModel = false
    this.optionsVisibleStatus = false;
    this.optionsVisibleStage = false;
    this.optionsVisibleVendor = false;
    this.optionsVisibleType = false;
    this.optionsVisibleDateType = false;
  }

  clearSelectedOption(filter: string): void {
    var flag = true;
    switch (filter) {

      case 'broker':
        this.displayTextBroker = '';
        this.brokerSearchTerm = '';
        this.clearableBroker = false;
        this.selectedFilters.delete('broker');
        this.filterValues.broker = null;
        break;
      case 'supplier':
        this.displayTextSupplier = '';
        this.supplierSearchTerm = '';
        this.clearableSupplier = false;
        this.selectedFilters.delete('supplier');
        this.filterValues.supplier = null;
        break;
        case 'model':
          this.displayTextModel = '';
          this.modelSearchTerm = '';
          this.clearableModel = false;
          this.selectedFilters.delete('model');
          this.filterValues.vehicle_model = null;
          break;
      case 'vendor':
        this.displayTextVendor = '';
        this.vendorSearchTerm = '';
        this.clearableVendor = false;
        this.selectedFilters.delete('vendor');
        this.filterValues.vendor = null;
        break;
      case 'brand':
        this.displayTextBrand = '';
        this.brandSearchTerm = '';
        this.clearableBrand = false;
        this.selectedFilters.delete('brand');
        this.filterValues.vehicle_brand = null;
        break;
      case 'status':
        this.displayTextStatus = '';
        this.statusSearchTerm = '';
        this.clearableStatus = false;
        this.selectedFilters.delete('status');
        this.filterValues.status = null;
        break;
      case 'stage':
        this.displayTextStage = '';
        this.stageSearchTerm = '';
        this.clearableStage = false;
        this.selectedFilters.delete('stage');
        flag = this.filterValues.stage == 'sent' ? false : true;
        this.filterValues.stage = null;
        break;
      case 'type':
        this.displayTextType = '';
        this.typeSearchTerm = '';
        this.clearableType = false;
        this.selectedFilters.delete('type');
        this.filterValues.type = null;
        break;
    }
    if (flag) {
      this.search();
    }
  }

  onSelectStage(stage: any, inputElement: HTMLInputElement): void {
    this.displayTextStage = stage.name;
    this.stageSearchTerm = stage.name;
    this.optionsVisibleStage = false;
    this.clearableStage = true;
    this.filterValues.stage = stage.key;
    this.selectedFilters.add('stage');

    this.search();
    inputElement.blur();
  }

  /******* BROKER *******/



  searchBroker(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.brokerAccounts = this.ladata.brokerData.filter((brokerData: any) =>
        brokerData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectBroker(broker: any, inputElement: HTMLInputElement) {
    this.displayTextBroker = broker.name;
    this.brokerSearchTerm = broker.name;
    this.optionsVisibleBroker = false;
    this.clearableBroker = true;
    this.filterValues.broker = broker.reference;
    this.selectedFilters.add('broker');
    this.search();
    inputElement.blur();
  }

  /******* VENDOR *******/

  searchVendor(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.vendorAccounts = this.ladata.vendorData.filter((vendorData: any) =>
        vendorData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }


  onSelectVendor(vendor: any, inputElement: HTMLInputElement) {
    this.displayTextVendor = vendor.name;
    this.vendorSearchTerm = vendor.name;
    this.optionsVisibleVendor = false;
    this.clearableVendor = true;
    this.filterValues.vendor = vendor.reference;
    this.selectedFilters.add('vendor');
    this.search();
    inputElement.blur();
  }

  /******* TYPE *******/

  onSelectType(type: any, inputElement: HTMLInputElement) {
    this.displayTextType = type.name;
    this.typeSearchTerm = type.name;
    this.optionsVisibleType = false;
    this.clearableType = true;
    this.filterValues.type = type.key;
    this.selectedFilters.add('type');
    this.search();
    inputElement.blur();
  }

  /******* DATE TYPE *******/

  onSelectDateType(dateType: any, inputElement: HTMLInputElement) {
    this.displayTextDateType = dateType.name;
    this.dateTypeSearchTerm = dateType.name;
    this.optionsVisibleDateType = false;
    this.clearableDateType = true;
    this.filterValues.dateType = dateType.key;
    this.selectedFilters.add('dateType');
    this.search();
    inputElement.blur();
  }

  /******* SUPPLIER *******/

  filteredSupplier: string[] = [];

  searchSupplier(term: string): void {
    if (this.ladata && this.ladata.supplierData) {
      this.supplierAccounts = this.ladata.supplierData.filter(
        (supplierData: any) =>
          supplierData.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectSupplier(supplier: any, inputElement: HTMLInputElement) {
    this.displayTextSupplier = supplier.name;
    this.supplierSearchTerm = supplier.name;
    this.optionsVisibleSupplier = false;
    this.clearableSupplier = true;
    this.filterValues.supplier = supplier.reference;
    this.selectedFilters.add('supplier');
    this.search();
    inputElement.blur();
  }

  filteredStatus: any[] = [];

  searchStatus(): void {
    this.filteredStatus = [
      { key: 'new', name: 'Nuevo' },
      { key: 'used', name: 'Usado' },
    ];
  }

  onSelectStatus(status: any, inputElement: HTMLInputElement): void {
    this.displayTextStatus = status.name;
    this.statusSearchTerm = status.name;
    this.optionsVisibleStatus = false;
    this.filterValues.status = status.key;
    this.clearableStatus = true;
    this.selectedFilters.add('status');
    /*   this.updateButtonLabel(); */
    this.search();
    inputElement.blur();
  }

  /************** */

  startDate: string | null = null;
  endDate: string | null = null;

  onDateChange(
    event: MatDatepickerInputEvent<Date>,
    dateType: 'startDate' | 'endDate'
  ): void {
    const selectedDate = event.value;

    if (selectedDate) {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      this.filterValues[dateType] = formattedDate;
      if (dateType === 'startDate') {
        this.clearableStartDate = true;
      }
      if (dateType === 'endDate') {
        this.clearableEndDate = true;
      }
      this.search();
    }
  }

  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();

  clearDate(dateType: 'startDate' | 'endDate'): void {
    this.filterValues[dateType] = null;
    if (dateType === 'startDate') {
      this.clearableStartDate = false;
    }
    if (dateType === 'endDate') {
      this.clearableEndDate = false;
    }

    const dateInput = document.querySelector(
      `#${dateType}Input`
    ) as HTMLInputElement;
    if (dateInput) {
      dateInput.value = '';
    }
    this.search();
  }

  /******** BRAND FILTER **********/

  availableBrands: string[] = [];
  filteredBrands: any[] = [];

  searchBrands(term: string): void {
    if (this.ladata && this.ladata.brands) {
      this.filteredBrands = this.ladata.brands.filter((brand: any) =>
        brand.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectBrand(brand: any, brandInput: HTMLInputElement) {
    this.displayTextBrand = brand.name;
    this.brandSearchTerm = brand.name;
    this.optionsVisibleBrand = false;
    this.filterValues.vehicle_brand = brand.name;
    this.clearableBrand = true;
    this.searchModelForBrand(brand.reference);
    this.search();
    brandInput.blur();
  }

  async searchModelForBrand(value: any) {
    if (!value) return;
    var brand_reference: any = '';
    if (typeof value === 'string') {
      brand_reference = value;
    } else {
      brand_reference = value.target.value;
    }

    try {
      const data = await this.modelService.getDataByBrand(brand_reference).toPromise();
      this.ladata.models = data.data;
      this.searchModels('');

    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  filteredModels: any[] = [];
  searchModels(term: string): void {
    if (this.ladata && this.ladata.models) {
      this.filteredModels = this.ladata.models.filter((model: any) =>
        model.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectModel(model: any, inputElement: HTMLInputElement) {
    this.displayTextModel = model.name;
    this.modelSearchTerm = model.name;
    this.optionsVisibleModel = false;
    this.clearableModel = true;
    this.filterValues.vehicle_model = model.name;
    this.selectedFilters.add('model');
    this.search();
    inputElement.blur();
  }


}
