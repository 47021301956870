import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  Component,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { AlertService } from '../../../core/services/alert/alert.service';
import { InvoiceService } from '../../../core/services/invoice/invoice.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';

import { TabsFormComponent } from './tabs-form/tabs-form.component';
import { TimelineComponent } from './timeline/timeline.component';
import { GeneralFormComponent } from './general-form/general-form.component';
import { FormAddNotesComponent } from './form-add-notes/form-add-notes.component';
import { OrdersFormComponent } from './orders-form/orders-form.component';
import { FormAddDocumentComponent } from '../../shared/form-add-document/form-add-document.component';

interface Model {
  reference: string;
  name: string;
}

@Component({
  selector: 'app-form-add-invoices',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    NgSelectModule,
    TranslateModule,
    TimelineComponent,
    TabsFormComponent,
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    GeneralFormComponent,
    TimelineComponent,
    FormAddNotesComponent,
    OrdersFormComponent,
    FormAddDocumentComponent,
  ],
  templateUrl: './form-add-invoices.component.html',
  styleUrl: './form-add-invoices.component.css',
})
export class FormAddInvoicesComponent {
  @ViewChild(GeneralFormComponent) generalFormComponent?: GeneralFormComponent;
  @ViewChild(FormAddNotesComponent) addNotesComponent?: FormAddNotesComponent;
  @ViewChild(TimelineComponent) timelineComponent?: TimelineComponent;

  @ViewChild(FormAddDocumentComponent)
  addDocumentComponent?: FormAddDocumentComponent;

  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() addInvoice = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();
  @Output() openFormAddOrders = new EventEmitter<any>();
  @Output() updatedOrders = new EventEmitter<any>();
  @Output() emitCloseAddOrdersForm = new EventEmitter<any>();

  @Input() invoiceData?: any; // Objeto opcional
  @Input() document_buttons_chico = false;
  @Input() ladata: any = [];
  @Input() tab?: any;

  account_type = this.localStorageService.getItem('account_type');
  form: FormGroup = this.fb.group({});
  draft = false;
  invoice_reference: string = '';
  client_reference: string = '';
  client: any = '';
  guarantee_reference: string = '';
  guarantee: any = '';
  back = '';
  searchParams: any = {};

  //viene de la data
  selectTypes: any[] = [];

  newStage: string = '';
  title = '';
  activeForm = 'general';
  posiblesTabs: any[] = ['general', 'notes', 'timeline', 'order'];
  docFiles: any[] = [];

  stage?: any;
  rules: any = {};
  role: any;

  updatesTabs: any[] = [];

  //////////////////////////////////SI///////////////////////////////

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private invoiceService: InvoiceService,
    private location: Location,
    private localStorageService: LocalStorageService
  ) {}

  setVariables() {
    this.back = this.invoiceData?.back || '';
    this.stage = this.invoiceData?.stage;
    this.rules = this.invoiceData?.rules;
    this.draft = this.invoiceData ? true : false;
    this.invoice_reference = this.invoiceData?.reference;
  }

  //////////////////////////////////NO///////////////////////////////

  ngOnChanges(changes: SimpleChanges) {
    this.refrescarFormulario();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.form = this.fb.group({
      stage: ['draft', Validators.required],
    });
    this.refrescarFormulario();
  }

  onSubmit() {
    const formValues = this.getFormsValues();
    formValues['type'] = 'standard';
    this.alertService.loading('swal_messages.please_wait_add_invoice');
    this.invoiceService.addData(formValues).subscribe({
      next: (data) => {
        this.alertService
          .success('swal_messages.draft_invoice', 'swal_messages.awesome')
          .then((result) => {
            this.emitAddInvoice();
            this.invoiceData = data.data;
            this.refrescarFormulario();
            const nuevaURL = this.location.path();
            if (!nuevaURL.includes('invoices')) {
              const nuevaURL =
                this.location.path() +
                '/invoices/pending/' +
                this.invoice_reference;
              this.location.go(nuevaURL);
            } else {
              const index = nuevaURL.indexOf('/');
              this.location.go(
                nuevaURL.substring(0, index) +
                  '/invoices/pending/' +
                  this.invoice_reference
              );
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });
  }

  emitUpdatedOrders() {
    this.updatedOrders.emit(true);
  }

  onSubmitOrderFast(stage: string): void {
    const originalOrder = this.invoiceData;
    const formValues = this.getFormsValues();
    const modifiedFields: any = {};

    formValues['stage'] = stage;
    for (const key in formValues) {
      if (
        formValues.hasOwnProperty(key) &&
        formValues[key] !== originalOrder[key]
      ) {
        modifiedFields[key] = formValues[key];
      }
    }

    if (Object.keys(modifiedFields).length === 0) {
      this.alertService.warning('swal_messages.no_changes_made');
      return;
    }

    // Convertir la fecha de entrega al formato esperado
    /*  if (modifiedFields.hasOwnProperty('delivery_date')) {
      modifiedFields['delivery_date'] = moment(modifiedFields['delivery_date']).format('YYYY-MM-DD');
    }
 */

    this.alertService.loading('swal_messages.updating_invoice');

    this.invoiceService
      .updateData(this.invoiceData.reference, modifiedFields)
      .subscribe({
        next: (data) => {
          this.alertService
            .successOk('swal_messages.invoice_updated')
            .then((result) => {
              if (result.isConfirmed) {
                this.invoiceData = data.data;
                this.generalFormComponent?.generalForm.patchValue({
                  notes: '',
                });
                this.refrescarFormulario();
                this.emitAddInvoice();
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });

    this.refrescarFormulario();
  }

  onSubmitOrder(): void {
    const originalOrder = this.invoiceData;
    const formValues = this.getFormsValues();
    const modifiedFields: any = {};

    for (const key in formValues) {
      if (
        formValues.hasOwnProperty(key) &&
        formValues[key] !== originalOrder[key]
      ) {
        modifiedFields[key] = formValues[key];
      }
    }

    if (Object.keys(modifiedFields).length === 0) {
      // Si no hay campos modificados, no hay nada que enviar
      this.alertService.warning('swal_messages.no_changes_made');
      return;
    }

    this.alertService.loading('swal_messages.updating_invoice');

    this.invoiceService
      .updateData(this.invoiceData.reference, modifiedFields)
      .subscribe({
        next: (data) => {
          // Mostrar un mensaje de éxito cuando se actualice correctamente el pedido
          this.alertService
            .successOk('swal_messages.invoice_updated')
            .then((result) => {
              if (result.isConfirmed) {
                this.invoiceData = data.data;
                this.updatesTabs = [];
                this.refrescarFormulario();
                this.emitAddInvoice();
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
  }


  getFormsValues(): any {
    const generalFormValues = this.generalFormComponent?.generalForm.value;
    return { ...this.form.value, ...generalFormValues };
  }

  pristineForms(): any {
    this.generalFormComponent?.generalForm.markAsPristine();
  }

  setTitle() {
    this.title = this.invoiceData?.serial + ' - ' + this.invoiceData?.number;
  }

  refrescarFormulario() {
    this.setVariables();
    this.setTitle();
    if (this.posiblesTabs.includes(this.tab)) {
      if (this.activeForm != this.tab) {
        this.activeForm = this.tab;
      }
    }
    this.pristineForms();
    this.alertService.close();
  }

  refrescar() {
    this.alertService.loading('swal_messages.please_wait');
    this.invoiceService
      .getDataByReference(this.invoiceData.reference)
      .subscribe({
        next: (data) => {
          this.invoiceData = data.data;
          this.refrescarFormulario();
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
  }

  checkUpdateForm(form: string) {
    var result: any = false;
    switch (form) {
      case 'general':
        result = this.generalFormComponent?.checkUpdateForm();
        break;
    }
    if (result) {
      if (!this.updatesTabs.includes(form)) {
        this.updatesTabs.push(form);
      }
    }
  }

  goDocs() {
    this.activeForm = 'doc-order';
  }

  changeForm(form: string) {
    this.checkUpdateForm(this.activeForm);
    if (this.document_buttons_chico) {
      this.emitCloseAddOrdersForm.emit(true);
    }
    this.activeForm = form;
    this.tab = this.activeForm;
  }

  emitBackButtonClick() {
    const nuevaURL = this.location.path();
    const index = nuevaURL.indexOf('/');
    this.location.go(nuevaURL.substring(0, index));
    this.resetFieldDisabled();
    this.draft = false;
    this.invoiceData = null;
    this.invoice_reference = '';
    this.activeForm = 'general';
    this.backButtonClick.emit(true);
  }

  emitAddInvoice() {
    this.addInvoice.emit(true);
  }

  resetFieldDisabled() {
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        this.form.get(field)?.enable();
      }
    }
  }

  actualizarEstadoArchivos(nuevoArrayArchivos: any[]) {
    this.docFiles = nuevoArrayArchivos;
  }

  // refrescar() {
  //   this.alertService.loading('swal_messages.please_wait');
  //   this.invoiceService.getDataByReference(this.invoiceData.reference).subscribe({
  //     next: (data) => {
  //       this.invoiceData = data.data;
  //       this.refrescarFormulario();
  //       this.alertService.close();
  //     },
  //     error: (error) => {
  //       this.alertService.error(error.error).then((result) => {
  //         if (result.isConfirmed) {
  //         }
  //       });
  //     },
  //   });
  // }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
      guarantee: 'text-guarantee',
    };

    return statusColorMap[status] || 'text-individual';
  }

  getBadgeColor(
    stage: string,
    require_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!require_action) {
      return statusColorMap['false'];
    }

    if (require_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  refreshInvoiceData() {
    this.alertService.loading('swal_messages.please_wait');
    this.invoiceService
      .getDataByReference(this.invoiceData.reference)
      .subscribe({
        next: (data) => {
          this.alertService.close();
          this.invoiceData = data.data;
          this.activeForm = 'invoice';
        },
        error: (error) => {
          console.error('Error al obtener la factura:', error);
          this.alertService.error('Error al refrescar los datos de la factura');
        },
      });
  }

  OpenFormAddOrders() {
    this.openFormAddOrders.emit(true);
  }

  onSubmitDraft(): void {
    if (this.docFiles.length > 0 && this.isDocumentForm()) {
      this.addDocumentComponent?.onSubmit();
    } else {
      switch (this.activeForm) {
        case 'notes':
          this.addNotesComponent?.onSubmit();
          break;
        default:
          this.onSubmitOrder();
          break;
      }
    }
    this.refrescarFormulario();
  }

  isDocumentForm(): boolean {
    return this.documentForms.includes(this.activeForm);
  }

  documentForms: string[] = ['doc-order', 'invoice'];
}
