import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private http: HttpClient) {}

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  setToken(token: any) {
    localStorage.setItem('access_token', token);
  }

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  setExpiresIn(expires_in: any) {
    localStorage.setItem('expires_in', expires_in);
  }

  getExpiresIn() {
    return localStorage.getItem('expires_in');
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  // Remove token
  removeToken() {
    localStorage.removeItem('access_token');
  }

  removeExpiresIn() {
    localStorage.removeItem('expires_in');
  }

  setLanguage(lang: any) {
    localStorage.setItem('lang', lang);
  }
  getLanguage() {
    return localStorage.getItem('lang');
  }

  reset() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('account_type');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    localStorage.removeItem('user_reference');
    localStorage.removeItem('account_name');
    localStorage.removeItem('searchParams');
    localStorage.removeItem('account_reference');
    this.removeToken();
    this.removeExpiresIn();
  }
  isAuthenticated(): boolean {
    const token = this.getToken();
    return !!token && !this.isTokenExpired();
  }

  isTokenExpired(): boolean {
    const expirationTime = Number(this.getExpiresIn());
    const expirationDate = new Date(expirationTime * 1000);
    const currentDate = new Date();
    return expirationDate < currentDate;
  }
}
