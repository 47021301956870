import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { LocalStorageService } from '../../../../../../core/services/localStorage/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,

    MatDatepickerModule,
  ],
  providers: [
    { provide: DatePipe },
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent implements OnChanges {

  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];
  @Output() clearSearch = new EventEmitter<void>();
  brands: any[] = [];

  form: FormGroup = this.fb.group({});

  filterValues: {
    start_date: string;
    end_date: string | null;
    stage: string | null;
    broker_reference: string | null;
    vehicle_status: string | null;
    vehicle_brand_id: string | null;
  } = {
      start_date: '',
      end_date: null,
      stage: null,
      broker_reference: null,
      vehicle_status: null,
      vehicle_brand_id: null
    };

  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.form = this.fb.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      stage: ['', Validators.required],
      broker_reference: ['', Validators.required],
      vehicle_status: ['', Validators.required],
      vehicle_brand_id: ['', Validators.required],
    });
  }

  ngAfterViewInit() {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        this.brands = this.ladata.brands;
      }
    }
  }

  searchParams: any = {};
  search(): void {
    this.filterValues = this.form.value;
    this.searchParams = {};
    if (this.filterValues.start_date) {
      let date = new Date(`${this.filterValues.start_date}`);
      let formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.searchParams['start_date'] = formattedDate;
    }

    if (this.filterValues.end_date) {
      let date = new Date(`${this.filterValues.end_date}`);
      let formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.searchParams['end_date'] = formattedDate;
    }
    if (this.filterValues.stage) {
      this.searchParams['stage'] = this.filterValues.stage;
    }

    if (this.filterValues.broker_reference) {
      this.searchParams[
        'broker_reference[eq]'
      ] = `${this.filterValues.broker_reference}`;
    }

    if (this.filterValues.vehicle_status) {
      this.searchParams[
        'vehicle_status[eq]'
      ] = `${this.filterValues.vehicle_status}`;
    }

    if (this.filterValues.vehicle_brand_id) {
      this.searchParams[
        'vehicle_brand_id[eq]'
      ] = `${this.filterValues.vehicle_brand_id}`;
    }



    this.emitEvent();
  }

  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

}
