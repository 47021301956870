import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { CommonModule, Location } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertService } from '../../../core/services/alert/alert.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { FormAddDocumentComponent } from '../../shared/form-add-document/form-add-document.component';
import { FormAddNotesComponent } from '../form-add-notes/form-add-notes.component';
import { TimelineComponent } from '../../timeline/timeline.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { VehicleFormComponent } from './vehicle-form/vehicle-form.component';
import { ContractFormComponent } from './contract-form/contract-form.component';
import { TabsFormComponent } from './tabs-form/tabs-form.component';
import { DeliveryFormComponent } from './delivery-form/delivery-form.component';
import { RiskFormComponent } from './risk-form/risk-form.component';
import { ComissionFormComponent } from './comission-form/comission-form.component';
import { GeneralFormComponent } from './general-form/general-form.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { GuaranteeFormComponent } from './guarantee-form/guarantee-form.component';
import { HelperModalComponent } from './helper-modal/helper-modal.component';
import { MatInputModule } from '@angular/material/input';

interface Model {
  reference: string;
  name: string;
}
interface VehicleData {
  reference: string;
  model: Model[];
  engine: string;
}

@Component({
  selector: 'app-form-add-order',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormAddDocumentComponent,
    FormAddNotesComponent,
    TimelineComponent,
    NgSelectModule,
    FormsModule,
    GeneralFormComponent,
    VehicleFormComponent,
    ContractFormComponent,
    DeliveryFormComponent,
    RiskFormComponent,
    ComissionFormComponent,
    TabsFormComponent,
    ClientFormComponent,
    GuaranteeFormComponent,
    HelperModalComponent,
  ],
  providers: [],
  templateUrl: './form-add-order.component.html',
  styleUrl: './form-add-order.component.css',
})
export class FormAddOrderComponent implements OnChanges {
  @ViewChild(FormAddDocumentComponent)
  addDocumentComponent?: FormAddDocumentComponent;
  @ViewChild(FormAddNotesComponent) addNotesComponent?: FormAddNotesComponent;

  @ViewChild(GeneralFormComponent) generalFormComponent?: GeneralFormComponent;
  @ViewChild(VehicleFormComponent) vehicleFormComponent?: VehicleFormComponent;
  @ViewChild(ContractFormComponent)
  contractFormComponent?: ContractFormComponent;
  @ViewChild(ClientFormComponent) clientFormComponent?: ClientFormComponent;
  @ViewChild(GuaranteeFormComponent)
  guaranteeFormComponent?: GuaranteeFormComponent;
  @ViewChild(DeliveryFormComponent)
  deliveryFormComponent?: DeliveryFormComponent;
  @ViewChild(RiskFormComponent) riskFormComponent?: RiskFormComponent;
  @ViewChild(ComissionFormComponent)
  comissionFormComponent?: ComissionFormComponent;
  @ViewChild(TimelineComponent) timelineComponent?: TimelineComponent;

  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() emitOpenAssigneeForm = new EventEmitter<boolean>();
  @Output() emitOpenAssigneeCreateForm = new EventEmitter<boolean>();
  @Output() emitCloseClientForm = new EventEmitter<boolean>();
  @Output() emitCloseAssigneeForm = new EventEmitter<boolean>();
  @Output() emitOpenClientForm = new EventEmitter<string>();
  @Output() addOrder = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();

  @Input() orderData?: any; // Objeto opcional
  @Input() dataContainer?: any;
  @Input() document_buttons_chico = false;
  @Input() ladata: any = [];
  @Input() tab?: any;

  @Input() clientReference!: string;

  showImportantDates = false;
  account_type = this.localStorageService.getItem('account_type');
  form: FormGroup = this.fb.group({});
  draft = false;
  order_reference: string = '';
  /* client_reference: string = ''; */
  client: any = '';
  guarantee_reference: string = '';
  guarantee: any = '';
  back = '';
  brand_id_modal: any;
  searchParams: any = {};
  @Input() client_reference: any;
  //viene de la data
  selectTypes: any[] = [];

  newStage: string = '';
  title = '';
  activeForm = 'general';
  posiblesTabs: any[] = [
    'general',
    'notes',
    'doc-order',
    'client',
    'guarantee',
    'risk',
    'timeline',
    'contract',
    'vehicle',
    'delivery',
    'commission',
  ];
  docFiles: any[] = [];

  stage?: any;
  rules: any = {};
  role: any;
  helper = false;
  documentForms: string[] = ['doc-order', 'client', 'guarantee'];

  updatesTabs: any[] = [];

  helperStep = 1;
  //////////////////////////////////SI///////////////////////////////

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private orderService: OrdersService,
    private location: Location,
    private localStorageService: LocalStorageService
  ) {}

  setVariables() {
    this.back = this.orderData?.back || '';
    this.stage = this.orderData?.stage;
    this.rules = this.orderData?.rules;
    this.ladata.rules = this.rules;
    this.draft = this.orderData ? true : false;
    this.order_reference = this.orderData?.reference;
    this.client_reference = this.orderData?.client?.reference;
    this.client = this.orderData?.client;
    this.guarantee = this.orderData?.guarantee;
    this.guarantee_reference = this.orderData?.guarantee?.reference;
  }

  //////////////////////////////////NO///////////////////////////////

  ngOnChanges(changes: SimpleChanges) {
    this.refrescarFormulario();
    this.role = this.localStorageService.getItem('role');
  }

  ngAfterViewInit() {
    this.form = this.fb.group({
      stage: ['draft', Validators.required],
    });
    this.refrescarFormulario();
  }

  onSubmit() {
    const formValues = this.getFormsValues();
    formValues.stage = this.role === 'Sales' ? 'sql' : 'draft';
    this.alertService.loading('swal_messages.please_wait_add_order');
    this.orderService.addData(formValues).subscribe({
      next: (data) => {
        this.alertService
          .success('swal_messages.draft_order', 'swal_messages.awesome')
          .then((result) => {
            this.emitAddOrder();
            this.orderData = data.data;
            this.refrescarFormulario();
            const nuevaURL = this.location.path();
            if (!nuevaURL.includes('orders')) {
              const nuevaURL =
                this.location.path() +
                '/orders/contracts/' +
                this.order_reference;
              this.location.go(nuevaURL);
            } else {
              const index = nuevaURL.indexOf('/');
              this.location.go(
                nuevaURL.substring(0, index) +
                  '/orders/contracts/' +
                  this.order_reference
              );
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
            if (error.code == 409) {
              window.location.reload();
            }
          }
        });
      },
    });
  }

  onSubmitDraft(): void {
    if (this.docFiles.length > 0 && this.isDocumentForm()) {
      this.addDocumentComponent?.onSubmit();
    } else {
      switch (this.activeForm) {
        case 'notes':
          this.addNotesComponent?.onSubmit();
          break;
        default:
          this.onSubmitOrder();
          break;
      }
    }
    this.refrescarFormulario();
  }

  onSubmitOrderFast(stage: string): void {
    const originalOrder = this.orderData;
    const formValues = this.getFormsValues();
    const modifiedFields: any = {};

    formValues['stage'] = stage;
    for (const key in formValues) {
      if (
        formValues.hasOwnProperty(key) &&
        formValues[key] !== originalOrder[key]
      ) {
        modifiedFields[key] = formValues[key];
      }
    }

    if (Object.keys(modifiedFields).length === 0) {
      this.alertService.warning('swal_messages.no_changes_made');
      return;
    }


    this.alertService.loading('swal_messages.updating_order');

    this.orderService
      .updateData(this.orderData.reference, modifiedFields)
      .subscribe({
        next: (data) => {
          this.alertService
            .successOk('swal_messages.order_updated')
            .then((result) => {
              if (result.isConfirmed) {
                this.orderData = data.data;
                this.generalFormComponent?.generalForm.patchValue({
                  notes: '',
                });
                this.updatesTabs = [];
                this.refrescarFormulario();
                this.emitAddOrder();
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
              if (error.code == 409) {
                window.location.reload();
              }
            }
          });

        },
      });

    this.refrescarFormulario();
  }

  onSubmitOrder(): void {
    const originalOrder = this.orderData;
    const formValues = this.getFormsValues();
    const modifiedFields: any = {};

    for (const key in formValues) {
      if (
        formValues.hasOwnProperty(key) &&
        formValues[key] !== originalOrder[key]
      ) {
        modifiedFields[key] = formValues[key];
      }
    }

    if (Object.keys(modifiedFields).length === 0) {
      // Si no hay campos modificados, no hay nada que enviar
      this.alertService.warning('swal_messages.no_changes_made');
      return;
    }

    this.alertService.loading('swal_messages.updating_order');
    this.orderService
      .updateData(this.orderData.reference, modifiedFields)
      .subscribe({
        next: (data) => {
          // Mostrar un mensaje de éxito cuando se actualice correctamente el pedido
          this.alertService
            .successOk('swal_messages.order_updated')
            .then((result) => {
              if (result.isConfirmed) {
                this.orderData = data.data;
                this.updatesTabs = [];
                this.refrescarFormulario();
                this.emitAddOrder();
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
              if (error.code == 409) {
                window.location.reload();
              }
            }
          });
        },
      });
  }

  getFormsValues(): any {
    const generalFormValues = this.generalFormComponent?.generalForm.value;
    const vehicleFormValues = this.vehicleFormComponent?.vehicleForm.value;
    const contractFormValues = this.contractFormComponent?.contractForm.value;
    const clientFormValues = this.clientFormComponent?.clientForm.value;
    const guaranteeFormValues =
      this.guaranteeFormComponent?.guaranteeForm.value;
    const deliveryFormValues = this.deliveryFormComponent?.deliveryForm.value;
    const comissionFormValues =
      this.comissionFormComponent?.comissionForm.value;
    const riskFormValues = this.riskFormComponent?.riskForm.value;
    this.generalFormComponent?.generalForm.get('notes')?.setValue('');
    return {
      ...this.form.value,
      ...clientFormValues,
      ...guaranteeFormValues,
      ...generalFormValues,
      ...vehicleFormValues,
      ...contractFormValues,
      ...deliveryFormValues,
      ...comissionFormValues,
      ...riskFormValues,
    };
  }

  pristineForms(): any {
    this.generalFormComponent?.generalForm.markAsPristine();
    this.vehicleFormComponent?.vehicleForm.markAsPristine();
    this.contractFormComponent?.contractForm.markAsPristine();
    this.clientFormComponent?.clientForm.markAsPristine();
    this.guaranteeFormComponent?.guaranteeForm.markAsPristine();
    this.deliveryFormComponent?.deliveryForm.markAsPristine();
    this.comissionFormComponent?.comissionForm.markAsPristine();
    this.riskFormComponent?.riskForm.markAsPristine();
  }
  setTitle() {
    this.title = this.orderData?.client?.name
      ? this.orderData?.client?.name +
        ' ' +
        (this.orderData?.client?.lastname
          ? this.orderData?.client?.lastname
          : '')
      : this.account_type === 'broker'
      ? this.orderData?.broker_reference
      : this.account_type === 'supplier'
      ? this.orderData?.supplier_reference
        ? this.orderData?.supplier_reference
        : this.orderData?.reference
      : this.orderData?.reference;
  }

  refrescarFormulario() {
    this.setVariables();
    this.setTitle();
    if (this.posiblesTabs.includes(this.tab)) {
      if (this.activeForm != this.tab) {
        this.activeForm = this.tab;
      }
    }
    this.pristineForms();
    this.alertService.close();
  }

  checkUpdateForm(form: string) {
    var result: any = false;
    switch (form) {
      case 'general':
        result = this.generalFormComponent?.checkUpdateForm();
        break;
      case 'vehicle':
        result = this.vehicleFormComponent?.checkUpdateForm();
        break;
      case 'currency':
        result = this.contractFormComponent?.checkUpdateForm();
        break;
      case 'client':
        result = this.clientFormComponent?.checkUpdateForm();
        break;
      case 'guarantee':
        result = this.guaranteeFormComponent?.checkUpdateForm();
        break;
      case 'delivery':
        result = this.deliveryFormComponent?.checkUpdateForm();
        break;
      case 'commission':
        result = this.comissionFormComponent?.checkUpdateForm();
        break;
      case 'risk':
        result = this.riskFormComponent?.checkUpdateForm();
        break;
    }
    if (result) {
      if (!this.updatesTabs.includes(form)) {
        this.updatesTabs.push(form);
      }
    }
  }

  goDocs() {
    this.activeForm = 'doc-order';
  }
  changeForm(form: string) {
    this.checkUpdateForm(this.activeForm);
    if (this.document_buttons_chico) {
      this.emitCloseClientForm.emit(true);
      this.emitCloseAssigneeForm.emit(true);
    }
    if (this.docFiles.length > 0) {
      this.alertService
        .confirmation2(
          'swal_messages.files_charged_message_' + this.activeForm,
          'swal_messages.files_charged',
          'swal_messages.confirm',
          'swal_messages.back'
        )
        .then((result) => {
          if (result.isConfirmed) {
            if (this.addDocumentComponent) {
              this.actualizarEstadoArchivos([]);
              this.addDocumentComponent.actualizarArchivos([]);
            }
            this.activeForm = form;
          } else {
          }
        });
    } else {
      this.activeForm = form;
    }
    this.tab = this.activeForm;
  }

  @Input() openedFromClients = false;
  @Output() closeOrderForm = new EventEmitter<void>();

  emitBackButtonClick() {
    const nuevaURL = this.location.path();
    const index = nuevaURL.indexOf('/');
    this.location.go(nuevaURL.substring(0, index));
    this.resetFieldDisabled();
    this.draft = false;
    this.orderData = null;
    this.order_reference = '';
    this.client_reference = '';
    this.guarantee_reference = '';
    this.activeForm = 'general';
    this.backButtonClick.emit(true);
  }

  emitAddOrder() {
    this.addOrder.emit(true);
  }

  resetFieldDisabled() {
    for (const field in this.form.controls) {
      if (this.form.controls.hasOwnProperty(field)) {
        this.form.get(field)?.enable();
      }
    }
  }

  actualizarEstadoArchivos(nuevoArrayArchivos: any[]) {
    this.docFiles = nuevoArrayArchivos;
  }

  refrescar() {
    this.alertService.loading('swal_messages.please_wait');
    this.orderService.getDataByReference(this.orderData.reference).subscribe({
      next: (data) => {
        this.orderData = data.data;
        this.refrescarFormulario();
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
      guarantee: 'text-guarantee',
    };

    return statusColorMap[status] || 'text-individual';
  }
  getImportanteDateColor(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      sent: 'text-draft',
      delivered: 'text-self-employed',
      risk_started: 'text-individual',
      contract_signed: 'text-priority-2',
    };

    return statusColorMap[status] || 'text-individual';
  }

  getBadgeColor(
    stage: string,
    require_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!require_action) {
      return statusColorMap['false'];
    }

    if (require_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  isDocumentForm(): boolean {
    return this.documentForms.includes(this.activeForm);
  }

  CloseClientForm() {
    this.emitCloseClientForm.emit(true);
  }

  //PASA MANOS DE EVENTOS//
  openAssigneeForm(assignee: any) {
    this.document_buttons_chico = true;
    this.emitOpenAssigneeForm.emit(assignee);
  }

  openClientForm(optAdd: string) {
    this.document_buttons_chico = true;
    this.emitOpenClientForm.emit(optAdd);
  }

  duplicateOrder() {
    this.alertService
      .confirmation(
        'swal_messages.duplicate_order_title',
        'swal_messages.duplicate_order_message',
        'swal_messages.confirm',
        'swal_messages.back'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.alertService.loading('swal_messages.please_wait');
          this.orderService.duplicateOrder(this.order_reference).subscribe({
            next: (data) => {
              this.alertService
                .successOk('swal_messages.duplicate_order_success')
                .then((result) => {
                  if (result.isConfirmed) {
                    this.orderData = data.data;
                    this.updatesTabs = [];
                    this.refrescarFormulario();
                    this.emitAddOrder();
                  }
                });
            },
            error: (error) => {
              this.alertService.error('swal_messages.order_not_found');
            },
          });
        } else {
        }
      });
  }

  @ViewChild('helperModal') helperModal!: HelperModalComponent;

  emitChangeBrand(brand: string) {
    this.openSelector(brand);
  }

  openSelector(brand: any) {
    this.helper = true;
    this.brand_id_modal = brand;
  }

  handleOption(option: string) {
    this.helper = false;
    this.alertService.loading();
    this.vehicleFormComponent?.fillVehicleFormForHelper(option);
    this.contractFormComponent?.fillContractFormForHelper(option);
    setTimeout(() => {
      this.alertService.close();
    }, 1000);
  }

  closeHelper() {
    this.helper = false;
  }
  showAssigneeCreateForm = false;
  openAssigneeCreateForm() {
    this.document_buttons_chico = true;
    this.showAssigneeCreateForm = true;
    this.emitOpenAssigneeForm.emit(true);
  }

  activeNotesTab: string = 'public';

  onTabChanged(tab: string) {
    this.activeNotesTab = tab;
  }
}
