<div class="encabezado">
  <div>
    <div>
      <h1 class="titulo1">{{ "pages.titles." + title | translate }}</h1>
    </div>
  </div>

  <div *ngIf="selection.size === 0" class="row">
    <div class="col-8">
      <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"
        (filterChanged2)="filterChanged($event)"></app-filter>
    </div>
  </div>

  <div *ngIf="selection.size > 0" class="actions_buttons_container">
    <button class="actions_buttons btn btn-hover filter" mat-raised-button (click)="updateSelectedNotes(true)"
      [disabled]="selection.size === 0">
      <span class="icon-alarm-ringing"></span>
      <div>{{ "notes.action_required" | translate }}</div>
    </button>

    <button class="actions_buttons btn btn-hover filter" mat-raised-button (click)="updateSelectedNotes(false)"
      [disabled]="selection.size === 0">
      <span class="icon-alarm-snooze"></span>
      <div>{{ "notes.no_action_required" | translate }}</div>
    </button>
  </div>

  <div class="tabla">
    <mat-paginator [length]="paginator.length ? paginator.length : 25" [pageSize]="paginator.pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]" [hidePageSize]="true" (page)="onPageChange($event)">
  </mat-paginator>
    <table mat-table [dataSource]="dataSource" matSort class="full-width-table" aria-label="Clients">

      <ng-container matColumnDef="action_required">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          <i class="pointer" [class.icon-square]="!isAllSelected()" [class.icon-check-square]="isAllSelected()"
            (click)="toggleSelectAll($event)">
          </i>
          {{ 'notes.action_required2' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          <div class="column-list-actions">
            <i style="cursor: pointer;" [class.icon-square]="!selection.has(row)"
              [class.icon-check-square]="selection.has(row)" (click)="toggleSelection(row)">
            </i>

            <!-- Ícono para requiere acción -->
            <span *ngIf="row.action_required" (click)="toggleActionRequired(row)"
              [ngClass]="{'selected-icon': selection.has(row)}" class="icon-alarm-ringing"
              style="margin-left: 20px; font-size: 18pt; cursor: pointer;">
            </span>

            <!-- Ícono para no requiere acción -->
            <span *ngIf="!row.action_required" (click)="toggleActionRequired(row)"
              [ngClass]="{'selected-icon': selection.has(row)}" class="icon-alarm-snooze"
              style="margin-left: 20px; font-size: 18pt; color: #888;cursor: pointer">
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ "tables.columns.references" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">

          <div class="column-list ">
            {{ row?.broker_name }}
            <span *ngIf="row?.broker_reference" class="secondary-text">
              <i class="icon-barcode"></i>
              {{ row?.broker_reference }}
            </span>
          </div>
          <div class="column-list ">
            {{ row?.supplier_name }}
            <span *ngIf="row?.supplier_reference" class="secondary-text">
              <i class="icon-barcode"></i>
              {{ row?.supplier_reference }}
            </span>
          </div>
          <div class="column-list ">
            {{ row?.vendor_name }}
            <span *ngIf="row?.vendor_reference" class="secondary-text">
              <i class="icon-barcode"></i>
              {{ row?.vendor_reference }}
            </span>
          </div>




        </td>
      </ng-container>


      <!-- broker_agent Column -->
      <ng-container matColumnDef="broker_agent">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ "tables.columns.broker_agent" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.agent_name">
            {{
            row?.agent_name + " " + row?.agent_lastname
            }}</span>
        </td>
      </ng-container>

      <!-- supplier_agent Column -->
      <ng-container matColumnDef="supplier_agent">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ "tables.columns.supplier_agent" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">{{ row?.supplier_reference }}</td>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row?.agent_name">
            {{
            row?.agent_name + " " + row?.agent_lastname
            }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ 'notes.client' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          <div class="column-list">
            {{ row?.client_name }}
            <span *ngIf="row?.client_type" class="badge text-badge-list"
              [ngClass]="getBadgeColorClient(row.client_type)">{{
              "forms.inputs." + row.client_type | translate
              }}</span>
          </div>
        </td>
      </ng-container>

      <!-- vehicle Column -->
      <ng-container matColumnDef="vehicle">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ 'notes.vehicle' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          <div class="column-list">
            <span *ngIf="row?.vehicle">{{ row?.vehicle_brand_name }} {{ row?.vehicle_model_name }} {{ row?.vehicle }}
              ({{row?.vehicle_model_year}})</span>
            <span *ngIf="row?.vehicle_status" class="badge text-badge-list"
              [ngClass]="getBadgeColorVehicle(row.vehicle_status)">{{
              "badge."
              + row?.vehicle_status | translate }}</span>
          </div>
        </td>
      </ng-container>

      <!-- note Column -->
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" style="width: 500px !important;">
          {{ 'notes.note' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          {{ row?.content }}
        </td>
      </ng-container>
      <ng-container matColumnDef="stage">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ "tables.columns.stage" | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="badge text-badge-list" [ngClass]="getBadgeColor(row.order_stage,row.require_action,row.priority)">{{
            "badge." + row?.order_stage | translate
            }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cabecera-tabla" (click)="onSortChange2('created_at')">
          {{ 'notes.date' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          <div class="column-list ">
            {{ row?.created_at  | formatDateTimePipe}}
          </div>
          <span *ngIf="row?.created_at" class="secondary-text">
            <i class="icon-user"></i>
            {{ row?.updated_by_fullname }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
          {{ "tables.columns.actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let row" class="default">
          <i class="icon-pencil text-success icon-actions" (click)="getOrder(row.order_id)"
            style="cursor: pointer;"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'tr-selected': row.action_required }">
      </tr>
    </table>

    <mat-paginator [length]="paginator.length" [pageSize]="paginator.pageSize"
      [pageSizeOptions]="paginator.pageSizeOptions" (page)="onPageChange($event)">
    </mat-paginator>

  </div>
  <div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showForm }">
    <app-form-add-order *ngIf="showForm" [orderData]="orderData" [tab]="tab" (backButtonClick)="cerrarFormulario()"
      (addOrder)="refrescar()" (emitOpenClientForm)="openClientForm()" (emitOpenAssigneeForm)="openAssigneeForm($event)"
      (emitCloseClientForm)="closeClientForm()" (emitCloseAssigneeForm)="closeAssigneeForm()"
      [document_buttons_chico]="document_buttons_chico" [ladata]="ladata"></app-form-add-order>
  </div>

    <div class="formulario-sub-derecha-container" [ngClass]="{'mostrar-formulario': showAssigneeForm }">
      <app-form-show-assignee *ngIf="showAssigneeForm" (backButtonClick)="cerrarAssigneeFormulario()" [assignee]="assignee"
        (addClient)="refreshClients()" [ladata]="ladata">
      </app-form-show-assignee>
    </div>
  <div class="overlay" *ngIf="showForm" (click)="cerrarFormulario()"></div>
</div>
