import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Client, Orders } from '../../../core/interfaces/orders';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { debounceTime, distinctUntilChanged, fromEvent, startWith, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ModelService } from '../../../core/services/model/model.service';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.css',
})
export class FilterComponent implements OnChanges {
  @ViewChild('requireActionCheckbox') requireActionCheckbox!: ElementRef;
  @ViewChild('searchBox') searchBox!: ElementRef;
  @Output() filterChanged = new EventEmitter<any>();
  @Input() ladata: any = [];

  submenuOpen: boolean = false;
  closeTimeout: any;
  isSearchTriggeredByEnter = false;

  dataSource = new MatTableDataSource<Orders>();
  orders: any[] = [];

  searchParamsEst: any = {};
  searchParams: any = {};

  showDropdown = false;
  showClear = false;

  accountType: string | null = null;
  selectedFilters: Set<string> = new Set();

  searchTerms: Subject<string> = new Subject<string>();

  filterValues: {
    search: string | null;
    client: Client | null;
    vendor: string | null;
    supplier: string | null;
    agent: string | null;
    broker: string | null;
    brokerReference: string | null;
    vehicle_brand: string | null;
    vehicle_model: string | null;
    stage: string | null;
    deliveryStage: string | null;
    require_action: boolean | null;
    status: string | null;
  } = {
      search: '',
      client: null,
      vendor: null,
      supplier: null,
      agent: null,
      broker: null,
      brokerReference: null,
      vehicle_brand: null,
      vehicle_model: null,
      stage: null,
      deliveryStage: null,
      require_action: null,
      status: null,
    };

  constructor(
    private localStorageService: LocalStorageService,
    private modelService: ModelService,
    private route: ActivatedRoute
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngAfterViewInit() {
    // Detectar cambios en el input con debounce y evitar duplicados
    this.searchTerms
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => {
        if (!this.isSearchTriggeredByEnter) {
          this.search();
        }
        this.isSearchTriggeredByEnter = false; // Resetear bandera después de debounce
      });

    // Detectar eventos de teclado para manejar Enter
    fromEvent<KeyboardEvent>(this.searchBox.nativeElement, 'keyup')
      .subscribe((event: KeyboardEvent) => {
        const term = (event.target as HTMLInputElement).value;
        this.searchTerms.next(term);
        if (event.key === 'Enter') {
          this.isSearchTriggeredByEnter = true; // Marcar como búsqueda por Enter
          this.search(); // Ejecutar búsqueda inmediata
        }
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      let ra = params['require_action'];
      if (ra) {
        this.filterValues.require_action = true;
        this.selectedFilters.add('require_action');
        this.search();

      }
      if (params['filter']) {
        let filter = params['filter'];
        if (filter) {
          this.localStorageService.setItem('searchParams', filter);
        }
      }
    });


  }
  /***********/
  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata'] && changes['ladata'].currentValue) {
      if (this.ladata.flag) {
        this.fetchAllData();
        this.checkFilters();
      }
    }
  }

  checkFilters(): void {
    if (this.localStorageService.getItem('searchParams')) {
      this.searchParams = JSON.parse(this.localStorageService.getItem('searchParams') || '{}');
      if (Object.keys(this.searchParams).length !== 0) {
        this.preCargarFiltros();
      }
    }
  }

  private fetchAllData() {
    Promise.all([
      this.fillFilter('', 'agentData', 'agentAccounts'),
      this.fillFilter('', 'brokerData', 'brokerAccounts'),
      this.fillFilter('', 'vendorData', 'vendorAccounts'),
      this.fillFilter('', 'supplierData', 'supplierAccounts'),
      this.fillFilter('', 'brands', 'filteredBrands'),
      this.fillFilter('', 'stages', 'filteredStages'),
      this.fillFilter('', 'delivery_place', 'filteredDeliveryStages'),

    ]).then(() => { });
  }

  /******** VARIABLES ********/

  supplierAccounts: any[] = [];
  brokerAccounts: any[] = [];
  vendorAccounts: any[] = [];
  agentAccounts: any[] = [];
  filteredStages: any[] = [];
  filteredDeliveryStages: any[] = [];
  filteredBrands: any[] = [];
  filteredModels: any[] = [];
  filteredStatus: any[] = [
    { key: 'new', name: 'Nuevo', selected: false },
    { key: 'used', name: 'Usado', selected: false }
  ];

  brandSearchTerm: string = '';
  modelSearchTerm: string = '';
  agentSearchTerm: string = '';
  stageSearchTerm: string = '';
  deliveryStageSearchTerm: string = '';
  clientSearchTerm: string = '';
  brokerSearchTerm: string = '';
  supplierSearchTerm: string = '';
  vendorSearchTerm: string = '';
  statusSearchTerm: string = '';

  searchText = '';

  optionsVisibleBrand: boolean = false;
  optionsVisibleModel: boolean = false;
  optionsVisibleSupplier: boolean = false;
  optionsVisibleBroker: boolean = false;
  optionsVisibleVendor: boolean = false;
  optionsVisibleClient: boolean = false;
  optionsVisibleStage: boolean = false;
  optionsVisibleDeliveryStage: boolean = false;
  optionsVisibleAgent: boolean = false;
  optionsVisibleStatus: boolean = false;

  displayTextSupplier: string = '';
  displayTextBroker: string = '';
  displayTextVendor: string = '';
  displayTextClient: string = '';
  displayTextStage: string = '';
  displayTextDeliveryStage: string = '';
  displayTextAgent: string = '';
  displayTextStatus: string = '';
  displayTextBrand: string = '';
  displayTextModel: string = '';

  clearableModel = false;
  clearableBrand = false;
  clearableStage = false;
  clearableDeliveryStage = false;
  clearableClient = false;
  clearableBroker = false;
  clearableAgent = false;
  clearableSupplier = false;
  clearableVendor = false;
  clearableStatus = false;

  /********  CARGA Y PRE CARGA DE FILROS **********/

  fillFilter(term: string, dataKey: string, targetKey: string): void {
    if (this.ladata && this.ladata[dataKey]) {
      (this as any)[targetKey] = this.ladata[dataKey].filter((item: any) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      )
        .map((stages: any) => ({
          ...stages,
          selected: false
        }));
    }
  }

  setAllSelectedFalse(targetKey: string): void {
    (this as any)[targetKey] = (this as any)[targetKey].map((item: any) => ({
      ...item,
      selected: false,
    }));
  }
  preCargarFiltros(): void {

    // SIN MULTISELECT
    if (this.searchParams['require_action']) {
      this.selectedFilters.add('require_action');
      this.filterValues.require_action = this.searchParams['require_action'];
      this.requireActionCheckbox.nativeElement.checked = this.searchParams['require_action'];
    }
    if (this.searchParams['search']) {
      this.filterValues.search = this.searchParams['search'];
      this.searchText = this.searchParams['search'];
    }

    if (this.searchParams['vehicle_model[like]']) {
      this.filterValues.vehicle_model = this.searchParams['vehicle_model[like]'];
      this.displayTextModel = this.searchParams['vehicle_model[like]'];
      this.clearableModel = true;
      this.modelSearchTerm = this.displayTextModel;
      this.selectedFilters.add('model');
    }
    if (this.searchParams['client_name[like]']) {
      this.filterValues.client = this.searchParams['client_name[like]'];
      this.displayTextClient = this.searchParams['client_name[like]'];
      this.clearableClient = true;
      this.clientSearchTerm = this.displayTextClient;
      this.selectedFilters.add('client');
    }

    if (this.searchParams['vehicle_status[eq]']) {
      this.filterValues.status = this.searchParams['vehicle_status[eq]'];
      this.displayTextStatus = this.filteredStatus.find(
        (status: any) => status.key === this.filterValues.status
      ).name;
      this.clearableStatus = true;
      this.statusSearchTerm = this.displayTextStatus;
      this.optionsVisibleStatus = false;
      this.selectedFilters.add('status');
    }

    // CON MULTISELECT


    if (this.searchParams['vendor_reference_id[in]']) {
      this.precargarSelectedAttributeVendor(this.searchParams['vendor_reference_id[in]']);
      this.filterValues.stage = this.searchParams['vendor_reference_id[in]'];
      this.updateDisplayTextVendor();
      this.vendorSearchTerm = this.displayTextVendor;
      this.clearableVendor = true;
      this.optionsVisibleVendor = false;
      this.selectedFilters.add('vendor');
    }


    if (this.searchParams['stage[in]']) {
      this.precargarSelectedAttributeStage(this.searchParams['stage[in]']);
      this.filterValues.stage = this.searchParams['stage[in]'];
      this.updateDisplayTextStage();
      this.stageSearchTerm = this.displayTextStage;
      this.clearableStage = true;
      this.optionsVisibleStage = false;
      this.selectedFilters.add('stage');

    }

    if (this.searchParams['delivery_status[in]']) {
      this.precargarSelectedAttributeDeliveryStage(this.searchParams['delivery_status[in]']);
      this.filterValues.deliveryStage = this.searchParams['delivery_status[in]'];
      this.updateDisplayTextDeliveryStage();
      this.deliveryStageSearchTerm = this.displayTextDeliveryStage;
      this.clearableStage = true;
      this.optionsVisibleStage = false;
      this.selectedFilters.add('deliveryStage');

    }


    if (this.searchParams['broker_reference_id[in]']) {
      this.precargarSelectedAttributeBroker(this.searchParams['stage[in]']);
      this.filterValues.broker = this.searchParams['broker_reference_id[in]'];
      this.updateDisplayTextBroker();
      this.brokerSearchTerm = this.displayTextBroker;
      this.clearableBroker = true;
      this.optionsVisibleBroker = false;
      this.selectedFilters.add('broker');
    }

    if (this.searchParams['supplier_reference_id[in]']) {
      this.precargarSelectedAttributeSupplier(this.searchParams['supplier_reference_id[in]']);
      this.filterValues.supplier = this.searchParams['supplier_reference_id[in]'];
      this.updateDisplayTextSupplier();
      this.supplierSearchTerm = this.displayTextSupplier;
      this.clearableSupplier = true;
      this.selectedFilters.add('supplier');

    }

    if (this.searchParams['vehicle_brand_id[in]']) {
      this.precargarSelectedAttributeBrand(this.searchParams['vehicle_brand_id[in]']);
      this.filterValues.vehicle_brand = this.searchParams['vehicle_brand_id[in]'];
      this.updateDisplayTextBrand();
      this.brandSearchTerm = this.displayTextBrand;
      this.clearableBrand = true;
      this.selectedFilters.add('brand');

    }
    if (this.searchParams['broker_agent_id[in]']) {
      this.precargarSelectedAttributeAgent(this.searchParams['broker_agent_id[in]']);
      this.filterValues.agent = this.searchParams['broker_agent_id[in]'];
      this.updateDisplayTextAgent();
      this.agentSearchTerm = this.displayTextAgent;
      this.clearableAgent = true;
      this.agentSearchTerm = this.displayTextAgent;
      this.selectedFilters.add('agent');
    }

    if (this.searchParams['supplier_agent_id[in]']) {
      this.precargarSelectedAttributeAgent(this.searchParams['supplier_agent_id[in]']);
      this.filterValues.agent = this.searchParams['supplier_agent_id[in]'];
      this.updateDisplayTextAgent();
      this.agentSearchTerm = this.displayTextAgent;
      this.clearableAgent = true;
      this.agentSearchTerm = this.displayTextAgent;
      this.selectedFilters.add('agent');
    }

    this.updateButtonLabel();
    this.search()
  }


  /********  FILTER **********/


  /******** STATUS *******/

  onSelectStatus(status: any, inputElement: HTMLInputElement): void {
    this.displayTextStatus = status.name;
    this.statusSearchTerm = status.name; // Limpiamos el término de búsqueda
    this.optionsVisibleStatus = false;
    this.filterValues.status = status.key;
    this.clearableStatus = true;
    this.selectedFilters.add('status');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }


  /******* STAGES *******/

  onSelectStage(stage: any, inputElement: HTMLInputElement): void {
    this.addSelectedAttributeStage(stage.key);
    this.updateDisplayTextStage();
    this.stageSearchTerm = this.displayTextStage;
    this.optionsVisibleStage = false;
    this.filterValues.stage = stage.key;
    this.clearableStage = true;
    this.selectedFilters.add('stage');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeStage(keysToSelect: string) {
    this.filteredStages = this.filteredStages.map(stage => ({
      ...stage,
      selected: stage.key === keysToSelect ? !stage.selected : stage.selected, // Toggle si las claves coinciden
    }));
  }

  precargarSelectedAttributeStage(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.filteredStages = this.filteredStages.map(stage => ({
      ...stage,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(stage.key) ? !stage.selected : stage.selected,
    }));
  }

  updateDisplayTextStage() {
    this.displayTextStage = this.filteredStages
      .filter((stage) => stage.selected)
      .map((stage) => stage.name)
      .join(', ');
  }


  /******* STAGES *******/

  onSelectDeliveryStage(deliveryStage: any, inputElement: HTMLInputElement): void {
    this.addSelectedAttributeDeliveryStage(deliveryStage.key);
    this.updateDisplayTextDeliveryStage();
    this.deliveryStageSearchTerm = this.displayTextDeliveryStage
    this.optionsVisibleStage = false;
    this.filterValues.deliveryStage = deliveryStage.key;
    this.clearableDeliveryStage = true;
    this.selectedFilters.add('deliveryStage');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeDeliveryStage(keysToSelect: string) {
    this.filteredDeliveryStages = this.filteredDeliveryStages.map(deliveryStage => ({
      ...deliveryStage,
      selected: deliveryStage.key === keysToSelect ? !deliveryStage.selected : deliveryStage.selected, // Toggle si las claves coinciden
    }));
  }

  precargarSelectedAttributeDeliveryStage(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.filteredDeliveryStages = this.filteredDeliveryStages.map(deliveryStage => ({
      ...deliveryStage,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(deliveryStage.key) ? !deliveryStage.selected : deliveryStage.selected,
    }));
  }

  updateDisplayTextDeliveryStage() {
    this.displayTextDeliveryStage = this.filteredDeliveryStages
      .filter((stage) => stage.selected)
      .map((stage) => stage.name)
      .join(', ');
  }


  /******* SUPPLIER *******/

  onSelectSupplier(supplier: any, inputElement: HTMLInputElement) {
    this.addSelectedAttributeSupplier(supplier.reference);
    this.updateDisplayTextSupplier();
    this.supplierSearchTerm = this.displayTextSupplier;
    this.optionsVisibleSupplier = false;
    this.clearableSupplier = true;
    this.filterValues.supplier = supplier.reference;
    this.selectedFilters.add('supplier');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeSupplier(keysToSelect: string) {
    this.supplierAccounts = this.supplierAccounts.map(supplier => ({
      ...supplier,
      selected: supplier.reference === keysToSelect ? !supplier.selected : supplier.selected, // Toggle si las claves coinciden
    }));
  }
  precargarSelectedAttributeSupplier(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.supplierAccounts = this.supplierAccounts.map(supplier => ({
      ...supplier,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(supplier.reference) ? !supplier.selected : supplier.selected,
    }));
  }
  updateDisplayTextSupplier() {
    this.displayTextSupplier = this.supplierAccounts
      .filter((stage) => stage.selected)
      .map((stage) => stage.name)
      .join(', ');
  }

  /******* VENDOR *******/

  onSelectVendor(vendor: any, inputElement: HTMLInputElement) {
    this.addSelectedAttributeVendor(vendor.reference);
    this.updateDisplayTextVendor();
    this.vendorSearchTerm = this.displayTextVendor;
    this.optionsVisibleVendor = false;
    this.clearableVendor = true;
    this.filterValues.vendor = vendor.reference;
    this.selectedFilters.add('vendor');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }


  addSelectedAttributeVendor(keysToSelect: string) {
    this.vendorAccounts = this.vendorAccounts.map(vendor => ({
      ...vendor,
      selected: vendor.reference === keysToSelect ? !vendor.selected : vendor.selected,
    }));
  }

  precargarSelectedAttributeVendor(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.vendorAccounts = this.vendorAccounts.map(vendor => ({
      ...vendor,
      selected: keysArray.includes(vendor.reference) ? !vendor.selected : vendor.selected,
    }));
  }

  updateDisplayTextVendor() {
    this.displayTextVendor = this.vendorAccounts
      .filter((vendor) => vendor.selected)
      .map((vendor) => vendor.name)
      .join(', ');
  }


  /******* AGENT *******/

  onSelectAgent(agent: any, inputElement: HTMLInputElement) {
    this.addSelectedAttributeAgent(agent.reference);
    this.updateDisplayTextAgent();
    this.agentSearchTerm = this.displayTextAgent;
    this.optionsVisibleAgent = false;
    this.clearableAgent = true;
    this.filterValues.agent = agent.reference;
    this.selectedFilters.add('agent');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeAgent(keysToSelect: string) {
    this.agentAccounts = this.agentAccounts.map(agent => ({
      ...agent,
      selected: agent.reference === keysToSelect ? !agent.selected : agent.selected,
    }));
  }

  precargarSelectedAttributeAgent(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.agentAccounts = this.agentAccounts.map(agent => ({
      ...agent,
      selected: keysArray.includes(agent.reference) ? !agent.selected : agent.selected,
    }));
  }

  updateDisplayTextAgent() {
    this.displayTextAgent = this.agentAccounts
      .filter((agent) => agent.selected)
      .map((agent) => agent.name + ' ' + agent.lastname)
      .join(', ');
  }

  /******* BROKER *******/

  onSelectBroker(broker: any, inputElement: HTMLInputElement) {
    this.addSelectedAttributeBroker(broker.reference);
    this.updateDisplayTextBroker();
    this.brokerSearchTerm = this.displayTextBroker;
    this.optionsVisibleBroker = false;
    this.clearableBroker = true;
    this.filterValues.broker = broker.reference;
    this.selectedFilters.add('broker');
    this.updateButtonLabel();
    this.search();
    inputElement.blur();
  }

  addSelectedAttributeBroker(keysToSelect: string) {
    this.brokerAccounts = this.brokerAccounts.map(broker => ({
      ...broker,
      selected: broker.reference === keysToSelect ? !broker.selected : broker.selected, // Toggle si las claves coinciden
    }));
  }

  precargarSelectedAttributeBroker(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.brokerAccounts = this.brokerAccounts.map(broker => ({
      ...broker,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(broker.reference) ? !broker.selected : broker.selected,
    }));
  }

  updateDisplayTextBroker() {
    this.displayTextBroker = this.brokerAccounts
      .filter((broker) => broker.selected)
      .map((broker) => broker.name)
      .join(', ');
  }

  /******** BRAND FILTER **********/

  onSelectBrand(brand: any, brandInput: HTMLInputElement) {
    // this.displayTextBrand = brand.name;
    this.addSelectedAttributeBrand(brand.reference);
    this.updateDisplayTextBrand();
    this.brandSearchTerm = this.displayTextBrand;
    this.optionsVisibleBrand = false;
    this.filterValues.vehicle_brand = brand.name;
    this.clearableBrand = true;
    this.updateButtonLabel();
    this.searchModelForBrand(brand.reference);
    this.search();
    brandInput.blur();
    this.clearSelectedOption('model')
  }

  addSelectedAttributeBrand(keysToSelect: string) {
    this.filteredBrands = this.filteredBrands.map(brand => ({
      ...brand,
      selected: brand.reference === keysToSelect ? !brand.selected : brand.selected, // Toggle si las claves coinciden
    }));
  }
  precargarSelectedAttributeBrand(keysToSelect: string) {
    const keysArray = keysToSelect.split(',');
    this.filteredBrands = this.filteredBrands.map(brand => ({
      ...brand,
      // Marca como seleccionado si la clave está en el array y alterna su estado
      selected: keysArray.includes(brand.reference) ? !brand.selected : brand.selected,
    }));
  }

  updateDisplayTextBrand() {
    this.displayTextBrand = this.filteredBrands
      .filter((brand) => brand.selected)
      .map((brand) => brand.name)
      .join(', ');
  }

  /********  MODAL FILTER **********/
  async searchModelForBrand(value: any) {
    if (!value) return;
    var brand_reference: any = '';
    if (typeof value === 'string') {
      brand_reference = value;
    } else {
      brand_reference = value.target.value;
    }

    try {
      const data = await this.modelService.getDataByBrand(brand_reference).toPromise();
      this.ladata.models = data.data;
      this.searchModels('');

    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  searchModels(term: string): void {
    if (this.ladata && this.ladata.models) {
      this.filteredModels = this.ladata.models.filter((model: any) =>
        model.name.toLowerCase().includes(term.toLowerCase())
      );
    }
  }

  onSelectModel(model: any, modelInput: HTMLInputElement) {
    this.displayTextModel = model.name;
    this.modelSearchTerm = model.name;
    this.optionsVisibleModel = false;
    this.filterValues.vehicle_model = model.name;
    this.clearableModel = true;
    this.updateButtonLabel();
    this.search();
    modelInput.blur();
  }


  /*******Filters label*******/

  onFocus(filterType: string): void {
    this.hideOptions();
    switch (filterType) {
      case 'status':
        this.optionsVisibleStatus = true;
        break;
      case 'supplier':
        this.optionsVisibleSupplier = true;
        break;
      case 'broker':
        this.optionsVisibleBroker = true;
        break;
      case 'vendor':
        this.optionsVisibleVendor = true;
        break;
      case 'client':
        this.optionsVisibleClient = true;
        break;
      case 'stage':
        this.optionsVisibleStage = true;
        break;
      case 'agent':
        this.optionsVisibleAgent = true;
        break;
      case 'brand':
        this.optionsVisibleBrand = true;
        break;
      case 'deliveryStage':
        this.optionsVisibleDeliveryStage = true;
        break;
      case 'model':
        this.optionsVisibleModel = true;
        break;
    }
  }


  hideOptions(): void {
    this.optionsVisibleSupplier = false;
    this.optionsVisibleBroker = false;
    this.optionsVisibleVendor = false;
    this.optionsVisibleClient = false;
    this.optionsVisibleBrand = false;
    this.optionsVisibleModel = false;
    this.optionsVisibleStage = false;
    this.optionsVisibleAgent = false;
    this.optionsVisibleStatus = false;
    this.optionsVisibleDeliveryStage = false;
  }

  clearSelectedOption(filter: string): void {
    switch (filter) {
      case 'status':
        this.displayTextStatus = '';
        this.statusSearchTerm = '';
        this.selectedFilters.delete('status');
        this.filterValues.status = null;
        break;
      case 'require_action':
        this.selectedFilters.delete('require_action');
        this.filterValues.require_action = null;
        this.requireActionCheckbox.nativeElement.checked = false;
        break;
      case 'agent':
        this.displayTextAgent = '';
        this.agentSearchTerm = '';
        this.clearableAgent = false;
        this.selectedFilters.delete('agent');
        this.setAllSelectedFalse('agentAccounts');
        break;
      case 'stage':
        this.displayTextStage = '';
        this.stageSearchTerm = '';
        this.clearableStage = false;
        this.selectedFilters.delete('stage');
        this.setAllSelectedFalse('filteredStages');
        break;
      case 'deliveryStage':
        this.displayTextDeliveryStage = '';
        this.deliveryStageSearchTerm = '';
        this.clearableDeliveryStage = false;
        this.selectedFilters.delete('deliveryStage');
        this.setAllSelectedFalse('filteredDeliveryStages');
        break;
      case 'client':
        this.displayTextClient = '';
        this.clientSearchTerm = '';
        this.clearableClient = false;
        this.selectedFilters.delete('client');
        this.filterValues.client = null;
        break;
      case 'broker':
        this.displayTextBroker = '';
        this.brokerSearchTerm = '';
        this.clearableBroker = false;
        this.selectedFilters.delete('broker');
        this.setAllSelectedFalse('brokerAccounts');
        break;
      case 'supplier':
        this.displayTextSupplier = '';
        this.supplierSearchTerm = '';
        this.clearableSupplier = false;
        this.selectedFilters.delete('supplier');
        this.setAllSelectedFalse('supplierAccounts');
        break;
      case 'vendor':
        this.displayTextVendor = '';
        this.vendorSearchTerm = '';
        this.clearableVendor = false;
        this.selectedFilters.delete('vendor');
        this.setAllSelectedFalse('vendorAccounts');
        break;
      case 'brand':
        this.displayTextBrand = '';
        this.brandSearchTerm = '';
        this.clearableBrand = false;
        this.selectedFilters.delete('brand');
        this.filterValues.vehicle_brand = null;
        this.setAllSelectedFalse('filteredBrands');
        break;
      case 'model':
        this.displayTextModel = '';
        this.modelSearchTerm = '';
        this.clearableModel = false;
        this.selectedFilters.delete('model');
        this.filterValues.vehicle_model = null;
        break;
    }

    this.updateButtonLabel();
    this.search();

    if (this.selectedFilters.size === 0) {
      this.showClear = false;
    }
  }

  onCheckboxChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.filterValues.require_action = true;
      this.selectedFilters.add('require_action');
      this.search();
    } else {
      this.clearSelectedOption('require_action');
    }
  }


  clearFilter(event: Event): void {
    event.stopPropagation();
    this.filterValues = {
      search: '',
      client: null,
      vendor: null,
      broker: null,
      agent: null,
      supplier: null,
      vehicle_brand: null,
      vehicle_model: null,
      brokerReference: null,
      stage: null,
      deliveryStage: null,
      require_action: null,
      status: null,
    };

    this.setAllSelectedFalse('filteredStages');
    this.setAllSelectedFalse('filteredBrands');
    this.setAllSelectedFalse('filteredModels');
    this.setAllSelectedFalse('filteredStatus');
    this.setAllSelectedFalse('supplierAccounts');
    this.setAllSelectedFalse('brokerAccounts');
    this.setAllSelectedFalse('vendorAccounts');
    this.setAllSelectedFalse('agentAccounts');
    this.setAllSelectedFalse('filteredDeliveryStages');

    this.searchText = '';

    this.stageSearchTerm = '';
    this.clientSearchTerm = '';
    this.supplierSearchTerm = '';
    this.brokerSearchTerm = '';
    this.vendorSearchTerm = '';
    this.brandSearchTerm = '';
    this.modelSearchTerm = '';
    this.stageSearchTerm = '';
    this.deliveryStageSearchTerm = '';
    this.statusSearchTerm = '';

    this.displayTextStage = '';
    this.displayTextDeliveryStage = '';
    this.displayTextClient = '';
    this.displayTextBroker = '';
    this.displayTextSupplier = '';
    this.displayTextVendor = '';
    this.displayTextBrand = '';
    this.displayTextModel = '';
    this.displayTextAgent = '';
    this.displayTextStatus = '';

    this.clearableStage = false;
    this.clearableDeliveryStage = false;
    this.clearableClient = false;
    this.clearableBroker = false;
    this.clearableAgent = false;
    this.clearableSupplier = false;
    this.clearableVendor = false;
    this.clearableBrand = false;
    this.clearableModel = false;
    this.clearableStatus = false;

    this.localStorageService.removeItem('searchParams');

    this.requireActionCheckbox.nativeElement.checked = false;

    this.showClear = false;
    this.selectedFilters.clear();
    this.searchParams = {};
    this.updateButtonLabel();
    this.hideOptions();
    this.emitEvent();
  }

  /************ SEARCH *************/

  search(): void {
    // SIN MULTISELECT
    this.searchParams = {};
    if (this.filterValues.search) {
      this.searchParams['search'] = `${this.filterValues.search}`;
    }

    if (this.filterValues.require_action) {
      this.searchParams['require_action'] = this.filterValues.require_action;
    }

    if (this.filterValues.client) {
      this.searchParams[
        'client_name[like]'
      ] = `%${this.filterValues.client.name}%`;
    }

    if (this.filterValues.status) {
      this.searchParams[
        'vehicle_status[eq]'
      ] = `${this.filterValues.status}`;
    }

    if (this.filterValues.vehicle_model) {
      this.searchParams[
        'vehicle_model[like]'
      ] = `%${this.filterValues.vehicle_model}%`;
    }

    // CON MULTISELECT
    if (this.vendorAccounts.some(vendor => vendor.selected)) {
      const selectedVendors = this.vendorAccounts
        .filter(vendor => vendor.selected)
        .map(vendor => vendor.reference)
      this.searchParams['vendor_reference_id[in]'] = selectedVendors.join(',');
    }

    if (this.supplierAccounts.some(supplier => supplier.selected)) {
      const selectedSuppliers = this.supplierAccounts
        .filter(supplier => supplier.selected)
        .map(supplier => supplier.reference)
      this.searchParams['supplier_reference_id[in]'] = selectedSuppliers.join(',');
    }

    if (this.brokerAccounts.some(broker => broker.selected)) {
      const selectedBrokers = this.brokerAccounts
        .filter(broker => broker.selected)
        .map(broker => broker.reference)
      this.searchParams['broker_reference_id[in]'] = selectedBrokers.join(',');
    }

    if (this.filteredBrands.some(brand => brand.selected)) {
      const selectedBrands = this.filteredBrands
        .filter(brand => brand.selected)
        .map(brand => brand.reference)
      this.searchParams['vehicle_brand_id[in]'] = selectedBrands.join(',');
    }

    if (this.agentAccounts.some(agent => agent.selected)) {
      const selectedaAgents = this.agentAccounts
        .filter(agent => agent.selected)
        .map(agent => agent.reference)
      if (this.accountType == 'broker') {
        this.searchParams['broker_agent_id[in]'] = selectedaAgents.join(',');
      }
      if (this.accountType == 'supplier') {
        this.searchParams['supplier_agent_id[in]'] = selectedaAgents.join(',');
      }
    }

    if (this.filteredStages.some(stage => stage.selected)) {
      const selectedStages = this.filteredStages
        .filter(stage => stage.selected)
        .map(stage => stage.key)
      this.searchParams['stage[in]'] = selectedStages.join(',');
    }


    if (this.filteredDeliveryStages.some(deliveryStage => deliveryStage.selected)) {
      const selectedStages = this.filteredDeliveryStages
        .filter(deliveryStage => deliveryStage.selected)
        .map(deliveryStage => deliveryStage.key)
      this.searchParams['delivery_status[in]'] = selectedStages.join(',');
    }
    let store_searchParams = this.searchParams;
    this.localStorageService.setItem('searchParams', JSON.stringify(store_searchParams).replace(/\%/g, ""))
    this.showClear = true;

    this.emitEvent();
  }

  /************ FUNCIONES CHICAS *************/
  emitEvent(): void {
    this.filterChanged.emit(this.searchParams);
  }

  updateButtonLabel() {
    const filterCount = this.selectedFilters.size;
    this.showClear = filterCount > 0;
  }

  get filterButtonLabel() {
    const filterCount = this.selectedFilters.size;
    return filterCount > 0 ? `Filtrar (${filterCount})` : 'Filtrar';
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 4000); //  1 seconds
  }

  startCloseTimeout(): void {
    this.closeTimeout = setTimeout(() => {
      this.showDropdown = false;
    }, 500); //  1 seconds
  }

  cancelCloseTimeout(): void {
    this.showDropdown = true;
    clearTimeout(this.closeTimeout);
  }


  keyup(event: KeyboardEvent) {
    this.filterValues.search = (event.target as HTMLInputElement).value;
    this.searchTerms.next(this.filterValues.search);
  }

}
