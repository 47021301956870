import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Client } from '../../core/interfaces/orders';
import { ClientsService } from '../../core/services/clients/clients.service';
import { AlertService } from '../../core/services/alert/alert.service';
import { CommonModule, Location } from '@angular/common';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { TranslateModule } from '@ngx-translate/core';
import { FormAddClientComponent } from './form-add-client/form-add-client.component';
import { FormEditClientComponent } from './form-edit-client/form-edit-client.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { DocumentsTypeService } from '../../core/services/documentType/documents-type.service';
import { FilterComponent } from './filter/filter.component';
import { Title } from '@angular/platform-browser';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { FormAddOrderComponent } from '../orders/form-add-order/form-add-order.component';
import { DataService } from '../../core/services/orders/data.service';
import { FormAddOrdersComponent } from './form-clients-orders/form-add-orders/form-add-orders.component';
import { FormShowAssigneeComponent } from '../shared/form-show-assignee/form-show-assignee.component';
import { FormAddAssigneeComponent } from './form-add-client/form-add-assignee/form-add-assignee.component';

interface ClientData {
  reference: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
}
@Component({
  selector: 'app-clients',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    FormAddClientComponent,
    FormEditClientComponent,
    NgSelectModule,
    FormsModule,
    FilterComponent,
    FormAddOrderComponent,
    FormAddOrdersComponent,
    FormShowAssigneeComponent,
    FormAddAssigneeComponent
  ],
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.css',
})
export class ClientsComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  dataSource = new MatTableDataSource<Client>();
  selectTypes: any;
  showFormEdit = false;
  showForm = false;

  client_reference?: any;
  clientData: ClientData[] = [];

  pageNumber: number = 0;
  pageSize: number = 0;
  pageIndex: number = 0;

  displayedColumns: string[] = [
    'tax_id',
    'type',
    'name',
    'email',
    'phone',
    'actions',
  ];

  tab: any = 'general';

  ladata: any = [];
  @Input() orderData?: any;

  constructor(
    private clientService: ClientsService,
    private alertService: AlertService,
    private location: Location,
    private documentTypeService: DocumentsTypeService,
    private titleService: Title,
    private localStorageService: LocalStorageService,
    private router: Router,
    private dataService: DataService
  ) {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.dataService.ladata$.subscribe((data) => {
      this.ladata = data;
    });
    this.detectReference();
    this.fetchAllData();
  }

  private fetchAllData() {
    this.fetchData()
      .then(() => {
        this.getClients().then(() => {
          this.applyStoredFilters();
        });
      })
      .finally(() => {
        this.alertService.close();
      });
  }

  searchParams: any = {};

  getClients(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clientService
        .getClients({
          search: this.searchParams.search || '',
          type: this.searchParams.type || '',
          page: this.pageNumber,
          pageSize: this.pageSize,
        })
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            this.pageSize = data.meta.per_page;
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
            resolve();
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  applyStoredFilters(): void {
    this.searchParams = JSON.parse(
      this.localStorageService.getItem('searchParamsClients') || '{}'
    );
    if (this.searchParams && Object.keys(this.searchParams).length > 0) {
      this.pageNumber = 1;

      this.getClients()
        .then(() => {
          this.alertService.close();
        })
        .catch((error) => {
          console.error('Error applying filters:', error);
          this.alertService.error('Error applying filters');
        });
    }
  }

  filterChanged(searchParams: any): void {
    this.searchParams = searchParams;
    this.pageNumber = 1;
    this.alertService.loading('swal_messages.please_wait');

    this.getClients()
      .then(() => {
        this.alertService.close();
      })
      .catch((error) => {
        console.error('Error loading clients:', error);
        this.alertService.error('Error loading clients');
      });
  }

  isInitialLoad = true;

  detectReference() {
    this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.tab = params['tab'];
      }
    });

    const nuevaURL = this.location.path();
    let paramReference = this.route.snapshot.paramMap.get('reference');
    if (nuevaURL.includes('clients/add')) {
      const nuevaURL = '/clients';
      this.location.go(nuevaURL);
      this.showForm = true;
    } else {
      if (paramReference) {
        if (!nuevaURL.includes('clients/')) {
          const nuevaURL = this.location.path() + '/clients/' + paramReference;
          this.location.go(nuevaURL);
        }
        this.client_reference = paramReference;
        this.editClient(paramReference, 'general');
      }
    }
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.selectTypes = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  cargaladata: any = [];

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchAllData();
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  currentPage: number = 1;

  refreshClients(): void {
    this.getClients();
  }

  editClient(reference: string, tab?: string): void {
    const nuevaURL = this.location.path();

    if (!nuevaURL.includes('clients/')) {
      this.tab = tab;
      const nuevaURL = this.location.path() + '/' + reference + '?tab=' + tab;
      this.location.go(nuevaURL);
    } else {
      if (tab) {
        this.tab = tab;
        const nuevaURL = this.location.path() + '?tab=' + tab;
        this.location.go(nuevaURL);
      }
    }

    this.alertService.loading('swal_messages.please_wait');
    this.clientService.getClientByReference(reference).subscribe({
      next: (data) => {
        this.alertService.close();
        this.clientData = data.data;
        this.clientTitle = data.data.name;
        this.client_reference = this.clientData;
        this.showForm = false;
        this.showFormEdit = true;
      },
      error: (error) => {
        console.error('There was an error!', error);
        this.alertService.error('');
      },
    });
  }

  onClientUpdated(): void {
    this.getClients();
  }

  typeOrders = 'Clientes';

  cerrarFormulario() {
    this.titleService.setTitle('Clientes - DBM');
    this.showForm = false;
    this.showAssigneeCreateForm = false;
    this.showFormEdit = false;
    this.showAddOrderForm = false;
    this.client_reference = null;
    this.showAssigneeForm = false;
    this.document_buttons_chico = false;
    const currentUrl = this.router.url.split('?')[0];
    this.tab = 'general';
    if (currentUrl.includes('/clients/')) {
      const nuevaURL = '/clients';
      this.location.go(nuevaURL);
    } else {
      const queryParams = { ...this.route.snapshot.queryParams };
      delete queryParams['client_reference'];
      delete queryParams['tab'];
      this.router.navigate([currentUrl], { queryParams });
    }
  }

  cerrarFormulario2() {
    this.showAssigneeCreateForm = false;
    this.document_buttons_chico = false;
  }

  cerrarFormularioEdit() {
    this.showForm = false;
    this.showFormEdit = false;
    this.client_reference = null;
    this.document_buttons_chico = false;
    this.titleService.setTitle('Clientes - DBM');

    const currentUrl = this.router.url.split('?')[0];
    this.tab = 'general';
    if (currentUrl.includes('/clients/')) {
      const nuevaURL = '/clients';
      this.location.go(nuevaURL);
    } else {
      const queryParams = { ...this.route.snapshot.queryParams };
      delete queryParams['client_reference'];
      delete queryParams['tab'];
      this.router.navigate([currentUrl], { queryParams });
    }
  }

  // DELETE CLIENT
  clients: any[] = [];

  deleteClient(reference: string): void {
    this.alertService
      .confirmation(
        'swal_messages.warning_confirm',
        'swal_messages.delete_client',
        'swal_messages.confirm'
      )
      .then((result) => {
        if (result.isConfirmed) {
          const currentPage = this.paginator.pageIndex + 1;
          this.clientService.deleteClient(reference).subscribe(
            () => {
              this.alertService.successOk('swal_messages.complete_success');

              if (this.clients.length === 1 && currentPage > 1) {
                this.paginator.previousPage();
                this.getClients();
              } else {
                this.getClients();
              }
            },
            (error) => {
              this.alertService.error(
                'swal_messages.clients.client_delete_error'
              );
              console.error('Error al eliminar el cliente:', error);
            }
          );
        }
      });
  }

  /******  Show asignee ****/
  assignee = null;
  showAssigneeForm = false;
  showAssigneeCreateForm = false;
  showAddClientForm = false;
  document_buttons_chico = false;

  cerrarAssigneeFormulario() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }

  openClientForm(reference: string) {
    this.showAddClientForm = true;
    this.loadClientDetail(reference);
    this.showForm = false;
  }

  openAssigneeForm(assignee: any) {
    this.assignee = assignee;
    this.document_buttons_chico = true;
    this.showAssigneeForm = true;
  }

  openAssigneeCreateForm() {
    this.document_buttons_chico = true;
    this.showAssigneeCreateForm = true;
  }

  openClientForm2(reference: string) {
    this.showAssigneeCreateForm = true;
    this.loadClientDetail(reference);
    this.showForm = false;
  }

  closeAssigneeCreateForm() {
    this.showAssigneeCreateForm = false;
    this.refreshClients();
  }

  closeClientForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }

  closeAssigneeForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }

  showFinancialDataForm = false;

  openFinancialDataForm() {
    this.document_buttons_chico = true;
    this.showFinancialDataForm = true;
    console.log(this.showFinancialDataForm);
  }

  cerrarFinancialDataFormulario() {
    this.document_buttons_chico = false;
    this.showFinancialDataForm = false;
  }

  showAddOrderForm = false;

  activeFormTab = 'general';

  loadClientDetail(reference: string) {
    const currentPath = this.location.path();

    if (!currentPath.includes(`/clients/${reference}`)) {
      const newURL = `/clients/${reference}?tab=general`;
      this.location.go(newURL);
    }

    this.alertService.loading('swal_messages.please_wait');
    this.clientService.getClientByReference(reference).subscribe({
      next: (data) => {
        this.alertService.close();
        this.client_reference = data.data;
        this.showFormEdit = true;
      },
      error: (error) => {
        this.alertService.error('');
      },
    });
  }

  @Output() openOrderFormFromClients2 = new EventEmitter<void>();
  @Output() clientUpdated2 = new EventEmitter<void>();

  refreshClientData() {
    this.loadClientData(this.client_reference);
  }

  loadClientData(clientReference: string) {
    this.clientService
      .getClientByReference(clientReference)
      .subscribe((clientData) => {
        this.clientData = clientData;
      });
  }

  @Input() backButtonClick2: any;

  cerrarFormularioAddOrder() {
    this.showAddOrderForm = false;
    this.document_buttons_chico = false;
    this.showFormEdit = false;
    this.editClient(this.client_reference, 'general');
    this.titleService.setTitle('Clientes - DBM');
  }

  cerrarFormularioAddAndEdit(reference: any) {
    this.editClient(reference, 'orders');
    this.titleService.setTitle('Clientes - DBM');
  }

  /********************* */
  clientTitle: string = '';

  @Output() formAddOrderOpened = new EventEmitter<string>();
  @Output() formAddOrderOpened2 = new EventEmitter<boolean>();

  openAddOrderForm2(client_reference: any, ladata: any) {
    this.ladata = ladata;
    this.client_reference = client_reference;
    this.document_buttons_chico = true;
    this.showAddOrderForm = true;
    this.formAddOrderOpened2.emit(true);
    this.formAddOrderOpened.emit(this.clientTitle);
  }

  getClientsOrders(reference: string): void {
    if (reference) {
      this.alertService.loading('swal_messages.please_wait');
      this.clientService.getClientsOrders(reference).subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.alertService.close();
        },
        error: (err) => {
          console.error('Error al obtener las ordenes del cliente:', err);
        },
      });
    }
  }
  @Output() assigneeCreated = new EventEmitter<any>();
  handleNewAssigneeInClients(newAssignee: any) {
    console.log('Nuevo responsable en clientsComponent:', newAssignee);
    this.assigneeCreated.emit(newAssignee);
  }
}
