<div class="row m-0 h-100">
  <div class="btn-next div-download" *ngIf="activeForm =='doc-order'">
    <div class="row">
      <div class="col-9">
        <label class="form-label">{{
          "forms.labels.download_all_documents"| translate
          }}</label>
        <label class="form-label subtext"><em>{{
            "forms.labels.download_all_documents_text"| translate
            }}</em></label>
      </div>

      <div class="col-3 d-flex align-items-center justify-content-end">
        <button type="submit" class="btn btn-primary btn-next_ok" (click)="downloadAllDocuments()"> <i
            class="icon-download2"></i>
          {{ "forms.buttons.download" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="tabs" [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
    <div class="tab-buttons">
      <button class="tab-button" *ngFor="let tab of tabs; let i = index" (click)="setActiveTab(i)"
        [class.active]="i === activeDocumentTab">
        {{ "tab."+tab.title | translate }}
      </button>
    </div>
  </div>

  <div class="form p-10" *ngIf="!isDisabled"  [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
    <div class="form-section">
      <div class="form-section-documents justify-content-between" *ngIf="existingDocuments"
        [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
        <label class="form-label">{{
          "forms.labels.exist_documents_" + label | translate
          }} <a class="goDocs" (click)="downloadAllDocuments()"  *ngIf="activeForm =='doc-order'" > <span class="goDocs">{{
              "forms.labels.download_all"| translate
              }}</span></a></label>
        <!-- Botón para expandir/contraer la lista -->
        <div (click)="showExistingDocuments = !showExistingDocuments" style="cursor: pointer">
          <i class="" [ngClass]="{
              'icon-chevron-up': showExistingDocuments,
              'icon-chevron-down': !showExistingDocuments
            }"></i>
        </div>
      </div>

      <div class="form-section-documents justify-content-end" *ngIf="existingDocuments"
        [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
        <label class="form-label orderDocs">{{ "forms.labels.order_document_by" | translate }}
          <a class="orderDocs" [ngClass]="{ 'desactivate': orderDocumentType !='type'  }" (click)="orderDocs('type')">
            {{"forms.labels.type"| translate }}</a> |
          <a class="orderDocs" [ngClass]="{ 'desactivate': orderDocumentType !='date_asc'  }"
            (click)="orderDocs('date_asc')">
            {{"forms.labels.date_asc"| translate }}</a> |
          <a class="orderDocs" [ngClass]="{ 'desactivate': orderDocumentType !='date_desc'  }"
            (click)="orderDocs('date_desc')">
            {{"forms.labels.date_desc"| translate }}</a></label>

      </div>

      <!-- Lista de documentos existentes -->
      <ul *ngIf="showExistingDocuments" [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
        <div class="form-section-documents" *ngFor="let existingDocument of existingDocuments" [ngClass]="{ 'private-background': activeDocumentTab == 1 }">
          <a [href]="existingDocument.file" [download]="existingDocument.name" target="_blank">
            <li class="existDocument">
              <img class="thumbnail" [src]="getThumbnailSrc2(existingDocument.name)" alt="Thumbnail" />
              <i class="icon-download2"></i>
              <div class="type" [ngStyle]="{'background-color': pickAColor(existingDocument.type)}">{{
                "select.document_type." + existingDocument.type | translate }}</div>
              {{ existingDocument.name + " "}} <span class="secondary-text"> ({{ existingDocument.created_at | date:
                'dd/MM/yyyy' }}) </span>
            </li>
          </a>
          <span class='trash'
            *ngIf="account_type == 'broker' || (activeForm == 'doc-order' && account_type == 'supplier' )"
            (click)="deleteFile(existingDocument.reference)">
            <i class="icon-trash"></i>
          </span>
        </div>
      </ul>
    </div>
    <div *ngIf="uploadFile">
      <div class="form-section">
        <div class="drop-zone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
          [ngClass]="{ 'private-dz-background': activeDocumentTab == 1 }" (click)="fileInput.click()">
          {{ "forms.labels.draganddrop" | translate }}
          <input #fileInput type="file" multiple style="display: none" (change)="onFileInputChange($event)" />
        </div>
      </div>
      <ul *ngIf="files.length > 0">
        <li *ngFor="let file of files; let i = index">
          <div class="listFiles">
            <div class="div-thumbnail">
              <img class="thumbnail" [src]="getThumbnailSrc(file.file)" alt="Thumbnail" />
              {{ file.file.name }}
              <span (click)="removeFile(i)">
                <i class="icon-trash"></i>
              </span>
            </div>
            <div class="">
              <select class="select-option form-control" (change)="selectOptionChanged($event, i)">
                <option value="defaultOption">
                  {{ "forms.inputs.type" | translate }}
                </option>
                <option *ngFor="
                    let type of selectTypes | filterSelectTypes : varSelectType
                  " [value]="type.key" [selected]="type.key === file.key">
                  {{ "select.document_type." + type.key | translate }}
                </option>
              </select>
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="btn_submit">
        <div class="btn-upload" *ngIf="files.length > 0">
          <button type="submit" class="btn btn-primary btn-primary-form" (click)="onSubmit()">
            {{ "buttons.upload_" + label | translate }}
            <i class="icon-upload2"></i>
          </button>
        </div>
      </div>

    </div>
  </div>
