import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Component, Output, EventEmitter, Input, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { AlertService } from '../../../../../core/services/alert/alert.service';
import { InvoiceService } from '../../../../../core/services/invoice/invoice.service';
import { LocalStorageService } from '../../../../../core/services/localStorage/local-storage.service';
import { OrdersService } from '../../../../../core/services/orders/orders.service';
import { FilterComponent } from "./filter/filter.component";

@Component({
  selector: 'app-form-add-orders',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatInputModule,
    NgSelectModule,
    TranslateModule,
    MatFormFieldModule,

    MatDatepickerModule,
    ReactiveFormsModule,
    FilterComponent
  ],
  templateUrl: './form-add-orders.component.html',
  styleUrl: './form-add-orders.component.css'
})
export class FormAddOrdersComponent {

  @Input() invoice_reference?: any;
  @Output() openFormAddOrders = new EventEmitter<any>();
  @Output() updatedOrders = new EventEmitter<boolean>();
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Input() ladata: any = [];
  @Input() invoiceData: any = [];
  data: any[] = [];
  brands: any[] = [];

  form: FormGroup = this.fb.group({});

  searchParams: any = {};



  totalComision: number = 0;
  showScrollIndicator: boolean = false; // Estado para mostrar la flecha

  @ViewChild('scrollableTable') scrollableTable?: ElementRef;



  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private invoiceService: InvoiceService,
    private location: Location,
    private localStorageService: LocalStorageService,
    private orderService: OrdersService
  ) {

  }

  ngOnInit() {
    this.alertService.loading('swal_messages.please_wait');
    this.fetchAllData();
    if (this.data.length > 4) {
      this.showScrollIndicator = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        this.brands = this.ladata.brands;

      }
    }
  }

  private fetchAllData() {
    Promise.all([this.fetchData()]).then(
      () => {
        setTimeout(() => {
          this.alertService.close();
        }, 500);
      }
    );
  }

  fetchData(): Promise<void> {

    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getDataToInvoices(
          this.searchParams
        )
        .subscribe({
          next: (data) => {
            this.data = data.data;
            if (this.data.length > 4) {
              this.showScrollIndicator = true;
            }
            setTimeout(() => {
              resolve();
            }, 500); // Espera de 1 segundo antes de resolver la promesa
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  // Chequear si se puede hacer scroll hacia abajo
  checkScroll(event: any) {
    const element = event.target;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

    // Si no está en la parte inferior y hay más de 12 elementos, mostrar el indicador
    if (this.data.length > 12) {
      this.showScrollIndicator = !atBottom;
    }
  }

  // Método para eliminar un elemento
  deleteItem(item: any) {
    this.data = this.data.filter(i => i !== item);
    this.totalComision = this.data.reduce((sum, i) => sum + i.broker_commission_total, 0); // Recalcular total

    // Verificar si hay más de 12 elementos después de eliminar
    if (this.data.length > 12) {
      this.showScrollIndicator = true;
    } else {
      this.showScrollIndicator = false;
    }
  }

  selectedItems: any[] = []; // Array para almacenar los elementos seleccionados

  toggleSelection(item: any): void {
    const index = this.selectedItems.findIndex(selectedItem => selectedItem === item.reference);

    if (index === -1) {
      this.selectedItems.push(item.reference); // Añadir si no está seleccionado
    } else {
      this.selectedItems.splice(index, 1); // Remover si ya está seleccionado
    }
  }

  isSelected(item: any): boolean {
    return this.selectedItems.some(selectedItem => selectedItem.reference === item.reference);
  }


  // Función para determinar si todos los items están seleccionados
  areAllSelected(): boolean {
    return this.data.length > 0 && this.selectedItems.length === this.data.length;
  }

  // Función para seleccionar/deseleccionar todos los items
  toggleSelectAll(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (isChecked) {
      this.selectedItems = [...this.data]; // Seleccionar todos los elementos
    } else {
      this.selectedItems = []; // Deseleccionar todos los elementos
    }
  }
  emitBackButtonClick() {
    this.backButtonClick.emit(true);
  }

  emitUpdatedOrders() {
    this.updatedOrders.emit(true);
  }

  getBadgeColor(stage: string, priority: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
      invoice: 'text-no-action'
    };

    const priorityColor = statusColorMap[priority];
    return priorityColor || 'text-priority-0';
  }

  onSubmit() {
    let formResult = this.form.value;
    formResult.orders = this.selectedItems;

    this.alertService.loading('swal_messages.please_wait');
    this.invoiceService.updateData(this.invoiceData.reference, formResult).subscribe({
      next: (data) => {
        this.alertService
          .successOk('swal_messages.success')
          .then((result) => {
            if (result.isConfirmed) {
              this.emitUpdatedOrders();
            }
          });
      },
      error: (error) => {
        this.alertService.error(error.error).then((result) => {
          if (result.isConfirmed) {
          }
        });
      },
    });

  }

  filterChanged(searchTerm: any) {

    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.orderService
      .getDataToInvoices(
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.data = data.data;
          setTimeout(() => {
            this.alertService.close();
          }, 500);
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

}
