import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';

import { Title } from '@angular/platform-browser';
import { DocumentsService } from '../../../core/services/documents/documents.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';

import { FormAddDocumentComponent } from '../../shared/form-add-document/form-add-document.component';


@Component({
  selector: 'app-form-show-assignee',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    FormAddDocumentComponent
  ],
  templateUrl: './form-show-assignee.component.html',
  styleUrl: './form-show-assignee.component.css',
})
export class FormShowAssigneeComponent {

  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() clientUpdated = new EventEmitter<any>();

  @Input() clientData?: any;
  @Input() selectTypes: any[] = [];
  @Input() assignee?: any;
  @Input() ladata?: any;
  @Input() rules: any = {};

  varSelectType = 'individual';
  activeForm = 'assignee';
  form: FormGroup;

  showExistingDocuments = true;

  clients: any[] = [];
  type = 'individual';
  isEditMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService,
    private titleService: Title,
    private localStorageService: LocalStorageService,
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      type: [''],
      phone: [''],
      billing_address: [''],
      billing_city: [''],
      billing_postal_code: [''],
      billing_state: [''],
      tax_id: ['', Validators.required],
      assignee_id: [''],
      assignee: [''],
      phonePrefix: [''],
      phoneNumber: ['', Validators.required],
    });
  }

  ngOnChanges(): void {
    this.activeForm = 'assignee';
    if (this.clientData) {
      this.isEditMode = true;
      this.type = this.clientData.type;
      const phone = this.clientData.phone || '';
      const phonePrefixMatch = phone.match(/^\(([^)]+)\)/);
      const phonePrefix = phonePrefixMatch ? phonePrefixMatch[1] : '+34';
      const phoneNumber = phone.replace(
        phonePrefixMatch ? phonePrefixMatch[0] : '',
        ''
      );

      this.form.patchValue({
        name: this.clientData.name || '',
        lastname: this.clientData.lastname || '',
        email: this.clientData.email || '',
        type: this.clientData.type || '',
        tax_id: this.clientData.tax_id || '',
        billing_address: this.clientData.billing_address || '',
        billing_city: this.clientData.billing_city || '',
        billing_postal_code: this.clientData.billing_postal_code || '',
        billing_state: this.clientData.billing_state || '',
        assignee_id: this.clientData.assignee?.reference || '',
        assignee: this.clientData.assignee?.reference || '',
        phonePrefix: phonePrefix,
        phoneNumber: phoneNumber,
      });

      this.form.get('type')?.disable();

      this.titleService.setTitle(
        `Cliente ${this.clientData.name} ${this.clientData.lastname ? ` ${this.clientData.lastname}` : ''} - DBM`
      );
    }
  }

  emitBackButtonClick() {
    this.assignee = null;
    this.backButtonClick.emit(true);
  }

  refrescar() {
    this.alertService.loading('Buscando documentos.');
    this.clientService.getClientByReference(this.assignee?.reference).subscribe({
      next: (data: any) => {
        this.assignee = data.data;
        this.alertService.close();
      },
      error: (error: any) => {
        this.alertService.close();
      },
    });
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  /** Documents **/
  docFiles: any[] = [];
  addDocumentComponent?: FormAddDocumentComponent;

  actualizarEstadoArchivos(nuevoArrayArchivos: any[]) {
    this.docFiles = nuevoArrayArchivos;
  }

  changeForm(form: string) {
    if (this.docFiles.length > 0) {
      this.alertService
        .confirmation2(
          'swal_messages.files_charged_message_' + this.activeForm,
          'swal_messages.files_charged',
          'swal_messages.confirm',
          'swal_messages.back'
        )
        .then((result) => {
          if (result.isConfirmed) {
            if (this.addDocumentComponent) {
              this.addDocumentComponent.actualizarArchivos([]);
            }
            this.activeForm = form;
          } else {
          }
        });
    } else {
      this.activeForm = form;
    }
  }

  changePrefix(newPrefix: string) {
    this.form.get('phonePrefix')?.setValue(newPrefix);
  }


  copyToClipboard(value: string, event: Event): void {
    // Convertir el valor a mayúsculas y eliminar acentos
    const transformedValue = this.removeAccents(value.toUpperCase());

    // Crear un textarea para copiar el valor
    const textarea = document.createElement('textarea');
    textarea.value = transformedValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  extractPhoneNumber(phone: string): string {
    const regex = /\)\s*(\d+)/;
    const match = phone.match(regex);
    return match ? match[1] : ''; // Si encuentra el patrón, devuelve el número, de lo contrario devuelve una cadena vacía
  }

  // Función para eliminar acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }




}
