import { Routes } from '@angular/router';
import { TitleResolver } from '../resolver/metatitles/metatitles.resolver';
import { NotesComponent } from '../../pages/notes/notes.component';

export const notesRoutes: Routes = [
  {
          path: 'notes',
          component: NotesComponent,
          resolve: { title: TitleResolver },
          data: { title: 'titles.notes' },
        },
        {
          path: 'notes/:reference',
          component: NotesComponent,
          resolve: { title: TitleResolver },
          data: { title: 'titles.notes' },
        },
];
