import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  Subject,
} from 'rxjs';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';
import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UsersService } from '../../../core/services/users/users.service';
import { Title } from '@angular/platform-browser';

export function phoneNumberValidator(): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const phonePrefix = formGroup.get('phonePrefix')?.value;
    const phoneNumber = formGroup.get('phoneNumber')?.value;

    if (
      phonePrefix === '+34' &&
      (!phoneNumber || !/^[0-9]{9}$/.test(phoneNumber))
    ) {
      return { invalidPhoneNumber: true }; // Devuelve un error si no tiene 9 dígitos
    }

    return null; // Retorna nulo si todo está bien
  };
}

interface FileItem {
  file: File;
  selectOption: string;
  key: string;
}

@Component({
  selector: 'app-form-add-users',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    SelectCountryPrefixComponent],
  templateUrl: './form-add-users.component.html',
  styleUrl: './form-add-users.component.css'
})
export class FormAddUsersComponent {
  @Output() backButtonClick = new EventEmitter<boolean>();
  @Output() addUser = new EventEmitter<boolean>();
  @Input() userData?: any;
  user_reference?: any;

  edit = false;
  form: FormGroup;

  title = "";
  search$ = new Subject<string>();


  showAlertFilter = false;
  showCountFilter = 0;
  showAlertFilterAll = false;
  existingClientReference: string = '';

  uploadFile = true;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private titleService: Title,
    private alertService: AlertService,
    private translate: TranslateService,
    private clientService: ClientsService,
    private userService: UsersService

  ) {
    this.form = this.fb.group(
      {
        status: [true],
        name: ['', Validators.required],
        lastname: ['', Validators.required],
        roles: [null],
        email: ['', [Validators.required, Validators.email]],
        phone: [''],
        phonePrefix: [''],
        phoneNumber: ['', Validators.required],
      },
      { validators: phoneNumberValidator() }
    );


  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userData']) {
      if (this.userData) {
        this.refrescarFormulario();
      }
    }
  }

  refrescarFormulario() {
    this.setVariables();
    this.setTitle();
    for (const key in this.userData) {
      const control = this.form.controls[key];
      const value = this.userData[key];

      if (control) {
        switch (key) {
          case 'phone':
            const phone = value || '';
            const phonePrefixMatch = phone.match(/^\(([^)]+)\)/);
            const phonePrefix = phonePrefixMatch ? phonePrefixMatch[1] : '+34';
            const phoneNumber = phone.replace(
              phonePrefixMatch ? phonePrefixMatch[0] : '',
              ''
            );
            this.form.get('phonePrefix')?.setValue(phonePrefix);
            this.form.get('phoneNumber')?.setValue(phoneNumber);
            break;
          default:
            control.setValue(value);
            break;
        }
      }
    }

    if (this.userData.roles == 'Admin') {
      this.form.get('roles')?.disable();
    }
      if (this.edit) {
        this.form.get('email')?.disable();

      }
    this.alertService.close();
  }

  setVariables() {
    this.edit = this.userData ? true : false;
    this.user_reference = this.userData?.reference;
  }

  setTitle() {
    this.title = this.userData?.client?.name ? this.userData?.client?.name + " " + (this.userData?.client?.lastname ? this.userData?.client?.lastname : '') : this.userData?.reference;
    this.titleService.setTitle(
      this.translate.instant('pages.titles.' + this.title) +
      ' ' +
      this.title +
      '- DBM'
    );

  }

  isFieldInvalid(field: string): any {
    const control = this.form.get(field);
    return control?.invalid && control?.touched;
  }

  getFormError(field: string, errorType: string): boolean {
    const control = this.form.get(field);
    return control?.errors?.[errorType];
  }

  emitAddUser() {
    this.addUser.emit(true);
  }

  changePrefix(event: any) {
    this.form.get('phonePrefix')?.setValue(event);
  }

  onSubmit() {
    this.alertService.loading('swal_messages.creating_user');
    let formData = { ...this.form.value };
    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value || '+34';
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;
      formData.phone = combinedPhoneNumber;
    }

    if (this.edit) {
      this.userService.updateData(formData,this.user_reference).subscribe(
        (response) => {
          this.alertService.successOk('swal_messages.user_created');
          this.emitAddUser();
          this.emitBackButtonClick();
          this.resetForm();
        },
        (error) => {
          this.alertService.error(error.error);
        }
      );
    } else {
      this.userService.addData(formData).subscribe(
        (response) => {
          this.alertService.successOk('swal_messages.user_created');
          this.emitAddUser();
          this.emitBackButtonClick();
          this.resetForm();
        },
        (error) => {
          this.alertService.error(error.error);
        }
      );
    }

  }

  resetForm(): void {
    this.form.reset({
      status: true,
      roles: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      phoneNumber: '',

    });
    this.changePrefix('+34');
  }

  emitBackButtonClick() {
    this.resetForm();
    this.backButtonClick.emit(true);
  }

  getSelectedClientType(): string | null {
    return this.form.get('type')?.value || null;
  }

  get phone() {
    return this.form.get('phone');
  }

  nameValue: string | null = null;
  lastnameValue: string | null = null;


  onSearch(event: any): void {
    const term = event.term || '';
    this.search$.next(term);
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.form.get('birth_date')?.setValue(adjustedDate);
    }
  }

  convertToLocalDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  onPhonePrefixSelected(selectedPrefix: string) {
    this.form.get('phone_prefix')?.setValue(selectedPrefix);
  }
}
