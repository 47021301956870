import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule, Location } from '@angular/common';
import { Component, inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import {
  MatPaginatorModule,
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';

import { AlertService } from '../../core/services/alert/alert.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { DocumentsTypeService } from '../../core/services/documentType/documents-type.service';
import { FilterComponent } from './filter/filter.component';
import { FormAddInvoicesComponent } from './form-add-invoices/form-add-invoices.component';
import { Invoices } from '../../core/interfaces/invoices';
import { InvoiceService } from '../../core/services/invoice/invoice.service';
import { FormAddOrdersComponent } from './form-add-invoices/orders-form/form-add-orders/form-add-orders.component';
import { BrandService } from '../../core/services/brand/brand.service';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any; // ajusta según la estructura real de root_account si es necesario
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
  // Otros campos posibles como error, message, etc.
}
@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FilterComponent,
    RouterModule,
    FormAddInvoicesComponent,
    FormAddOrdersComponent,
  ],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.css',
})
export class InvoicesComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  showAddOrderForm = false;
  showClear = false;

  pageNumber: number = 1;
  pageSize: number = 100;

  invoice_reference?: any;
  order_reference?: any;
  invoiceData?: any;

  typeInvoices: string = '';

  dataSource = new MatTableDataSource<Invoices>();
  title = 'invoices';

  showAlertFilter = false;
  showCountFilter = 0;
  showAlertFilterAll = false;
  searchParams: any = {};
  accountType: string | null = null;

  ladata: any = [];
  cargaladata: any = [];

  document_buttons_chico = false;
  tab = 'general';

  displayedColumnsBroker: string[] = [
    'serial',
    'number',
    'date',
    'organization',
    'dealership',
    'commission',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'serial',
    'number',
    'organization',
    'dealership',
    'date',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumns: string[] = [];

  type: any = 'broker';
  autoFetch: any;

  @Input() selectTypes: any[] = [];

  // Mapa para relacionar rutas con sus títulos y tipos de orden
  routesMap: { [key: string]: string } = {
    invoices: '',
    'invoices/all': '',
    'invoices/paid': 'paid',
    'invoices/drafts': 'drafts',
    'invoices/pending': 'pending',
    'invoices/delivered': 'delivered',
    'invoices/cancelled': 'cancelled',
  };

  constructor(
    private location: Location,
    private titleService: Title,
    private alertService: AlertService,
    private translate: TranslateService,
    private accountService: AccountsService,
    private localStorageService: LocalStorageService,
    private invoiceService: InvoiceService,
    private documentTypeService: DocumentsTypeService
  ) {
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }

  ngOnInit() {
    // this.alertService.loading('swal_messages.please_wait');
    this.detectReference(),
      this.alertService.loading('swal_messages.please_wait');
    this.fetchAllData();
    this.startAutoFetch();
  }

  startAutoFetch(): void {
    if (this.autoFetch) {
      clearInterval(this.autoFetch);
    }
    // this.autoFetch = setInterval(() => {
    //   this.refreshInvoiceData();
    // }, 40000);
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData(),
      this.fetchData2(),
      this.fetchData4(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  agentData: AccountData[] = [];
  accountData: AccountData[] = [];
  organizationData: AccountData[] = [];
  dealershipData: AccountData[] = [];
  expandedRow: any = null;

  toggleRow(row: any): void {
    //this.expandedRow = this.expandedRow === row ? null : row;
    this.expandedRow = row;
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.invoiceService
        .getData(
          this.typeInvoices,
          this.pageNumber,
          this.pageSize,
          this.searchParams
        )
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(
                this.localStorageService.getItem('per_page')
              );
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
            this.alertService.close();
            setTimeout(() => {
              resolve();
            }, 500);
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve();
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.organization = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.dealershipData = data.filter(
            (item) => item.type === 'dealership'
          );

          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  // Filtramos los tipos de documentos para 'invoices' en fetchData4
  fetchData4(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.cargaladata.selectTypes = data.data.filter(
            (type: any) => type.entity === 'invoices'
          );
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  getInvoice(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path();

    var aux =
      this.typeInvoices == '' ? this.typeInvoices : this.typeInvoices + '/';
    if (!nuevaURL.includes('invoices')) {
      const nuevaURL = this.location.path() + '/invoices/' + aux + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(
        nuevaURL.substring(0, index) + '/invoices/' + aux + reference
      );
    }

    this.invoice_reference = reference;
    this.invoiceService.searchOrder(this.invoice_reference).subscribe({
      next: (data) => {
        this.invoiceData = data.data;
        /* let title = data.data.client?.name
          ? data.data.client?.name +
            ' ' +
            (data.data.client?.lastname ? data.data.client?.lastname : '')
          : data.data.broker.reference; */
        this.titleService.setTitle(
          this.translate.instant('pages.titles.' + this.title) +
            ' ' +
            'Invoice' +
            '- DBM'
        );
        this.showForm = true;
        this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  detectReference() {
    this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.tab = params['tab'];
      }
    });
    this.getAccountTypeFromLocalStorage();

    const nuevaURL = this.location.path();
    this.typeInvoices = '';

    if (nuevaURL.includes('invoices/add')) {
      this.showForm = true;
    } else {
      if (nuevaURL.includes('invoices/drafts')) {
        this.typeInvoices = 'drafts';
        const nuevaURL = '/invoices/' + this.typeInvoices;
        this.location.go(nuevaURL);
        this.title = 'drafts';
      }
      if (nuevaURL.includes('invoices/pending')) {
        this.typeInvoices = 'pending';
        const nuevaURL = '/invoices/' + this.typeInvoices;
        this.location.go(nuevaURL);
        this.title = 'pending';
      }
      if (nuevaURL.includes('invoices/all')) {
        this.typeInvoices = 'all';
        const nuevaURL = '/invoices/' + this.typeInvoices;
        this.location.go(nuevaURL);
        this.title = 'all';
      }
      if (nuevaURL.includes('invoices/paid')) {
        this.typeInvoices = 'paid';
        const nuevaURL = '/invoices/' + this.typeInvoices;
        this.location.go(nuevaURL);
        this.title = 'paid';
      }
      if (nuevaURL.includes('invoices/cancelled')) {
        this.typeInvoices = 'cancelled';
        const nuevaURL = '/invoices/' + this.typeInvoices;
        this.location.go(nuevaURL);
        this.title = 'cancelled';
      }

      let paramReference = this.route.snapshot.paramMap.get('reference');
      if (paramReference) {
        this.invoice_reference = paramReference;
        const nuevaURL =
          this.location.path() +
          '/invoices/' +
          this.typeInvoices +
          '/' +
          paramReference;
        this.location.go(nuevaURL);
        this.getInvoice(paramReference);
      }
    }
  }
  toggleForm() {
    this.showForm = !this.showForm;
  }
  getAccountTypeFromLocalStorage(): void {
    this.accountType = localStorage.getItem('account_type');
  }

  cerrarFormulario() {
    this.titleService.setTitle(
      this.translate.instant('pages.titles.' + this.typeInvoices) + ' - DBM'
    );
    const nuevaURL = this.location.path() + '/invoices/' + this.typeInvoices;
    const index = nuevaURL.indexOf('/');
    this.location.go(
      nuevaURL.substring(0, index) + '/invoices/' + this.typeInvoices
    );
    this.invoiceData = null;
    this.showForm = false;
    this.showAddOrderForm = false;
    this.document_buttons_chico = false;
  }
  refrescar() {
    this.fetchData();
  }

  refreshInvoiceData() {
    this.invoiceService
      .getFilterData(
        this.typeInvoices,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(
              this.localStorageService.getItem('per_page')
            );
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.showCountFilter = data.meta.total;

          if (Object.keys(this.searchParams).length === 0) {
            this.showAlertFilter = false;
          } else {
            this.showAlertFilter = true;
          }
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  /******* EVENTO DEL FILTRO  *******/
  filterChanged(searchTerm: any) {
    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.invoiceService
      .getFilterData(
        this.typeInvoices,
        this.pageNumber,
        this.pageSize,
        this.searchParams
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(
              this.localStorageService.getItem('per_page')
            );
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.showCountFilter = data.meta.total;
          if (Object.keys(searchTerm).length === 0) {
            this.showAlertFilter = false;
          } else {
            this.showAlertFilter = true;
          }
          this.startAutoFetch();
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    //this.fetchData();
  }
  getBadgeColor(
    stage: string,
    require_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (stage === 'paid') {
      return statusColorMap['false'];
    }

    if (!require_action) {
      return statusColorMap['false'];
    }

    if (require_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  refreshOrders() {}

  refreshInvoice() {
    this.cerrarOrdersFormulario();
    this.getInvoice(this.invoice_reference);
  }

  cerrarOrdersFormulario() {
    this.document_buttons_chico = false;
    this.showAddOrderForm = false;
  }

  OpenFormAddOrders() {
    this.showAddOrderForm = true;
    this.document_buttons_chico = true;
  }

  CloseFormAddOrders() {
    this.document_buttons_chico = false;
    this.showAddOrderForm = false;
  }
}
