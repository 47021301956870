<div class="encabezado">
  <div class="col-4 getBack "> <a routerLink="/reports" class="getBack"> <span><i class="icon-chevron-left"></i>{{
        "forms.buttons.back" | translate }} </span></a></div>
  <div class="">
    <div class="">
      <div>
        <h5 class=" titulo1">{{ ('reports.'+type+'2' | translate) }}</h5>
      </div>
    </div>
  </div>

  <div class="filter-content row">
    <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)" type="evolution"></app-filter>
  </div>

  <!-- <div class="filterText" *ngIf="showFilterText">
    <h4>{{ "reports.labels.result_title" | translate: { stage:
      stage,start_date:stage_start_date | formatDatePipe ,end_date:stage_end_date | formatDatePipe } }} </h4>
  </div>
 -->

 <canvas id="myLineChart"></canvas>

  <div class="tabla_evo" [hidden]="!dataSource.data.length">
    <table mat-table class="full-width-table" matSort aria-label="Brands" multiTemplateDataRows
        [dataSource]="dataSource">
      <!-- Columna de Mes/Año -->
      <ng-container matColumnDef="month_year">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('month_year')">  {{ "tables.columns.month" | translate }}  <i [ngClass]="getDirection()"></i></th>
        <td mat-cell *matCellDef="let row"> {{ row.date }} </td>
      </ng-container>

      <!-- Columna Sent -->
      <ng-container matColumnDef="sent">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" >  {{ "tables.columns.sent" | translate }}</th>
        <td mat-cell *matCellDef="let row"> {{ row.sent }} </td>
      </ng-container>

      <!-- Columna Risk Started -->
      <ng-container matColumnDef="risk_started">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" >  {{ "tables.columns.risk_started" | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{ row.risk_started }}  <span *ngIf="row?.ratio_risk_started" class="secondary-text">
          ( {{ row?.ratio_risk_started }} )</span></td>
      </ng-container>


      <!-- Columna Risk Approved -->
      <ng-container matColumnDef="risk_approved">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" >  {{ "tables.columns.risk_approved" | translate }} </th>
        <td mat-cell *matCellDef="let row"> {{ row.risk_approved }} <span *ngIf="row?.ratio_risk_approved" class="secondary-text">
          ( {{ row?.ratio_risk_approved }} )</span></td>
      </ng-container>

      <!-- Columna Contract Signed -->
      <ng-container matColumnDef="contract_signed">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" > {{ "tables.columns.contract_signed" | translate }}  </th>
        <td mat-cell *matCellDef="let row"> {{ row.contract_signed }}  <span *ngIf="row?.ratio_contract_signed" class="secondary-text">
          ( {{ row?.ratio_contract_signed }} )</span></td>
      </ng-container>

      <!-- Columna Vehicle Delivered -->
      <ng-container matColumnDef="vehicle_delivered">
        <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" > {{ "tables.columns.vehicle_delivered" | translate }}  </th>
        <td mat-cell *matCellDef="let row"> {{ row.vehicle_delivered }}  <span *ngIf="row?.ratio_vehicle_delivered" class="secondary-text">
          ( {{ row?.ratio_vehicle_delivered }} )</span></td>
      </ng-container>

      <!-- Fila de Encabezado -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

      <!-- Filas de Datos -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <!-- Paginador -->
    <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>

<div class="overlay" *ngIf="showForm"></div>
