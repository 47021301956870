import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { ReportsService } from '../../../core/services/reports/reports.service';
import { FilterComponent } from '../filter/filter.component';
import { AccountsService } from '../../../core/services/accounts/accounts.service';
import { BrandService } from '../../../core/services/brand/brand.service';
import { Chart, registerables } from 'chart.js';
import { OrderTableComponent } from '../order-table/order-table.component';
import { FormatDatePipe } from '../../../core/pipe/custom-pipes';

Chart.register(...registerables);
type SearchParamsEst = {
  [key: string]: any;
};

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
}
@Component({
  selector: 'app-reports-evo-view',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    FilterComponent,
    OrderTableComponent,
    FormatDatePipe
  ],
  templateUrl: './reports-evo-view.component.html',
  styleUrl: './reports-evo-view.component.css',
})

export class ReportsEvoViewComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  showForm = false;
  type: string = '';
  chart: Chart | undefined;
  dataSource = new MatTableDataSource<any>();
  accountType: string | null = null;
  orderData: any[] = [];
  ladata: any = [];
  searchParamsClickBar: SearchParamsEst = {};
  orderDataBar: any[] = [];
  showTable = false;
  typeOrders = "";
  sortColumn = 'stage_updated_at';
  sortDirection = 'desc';

  stage: string = '';
  stage_end_date: string = '';
  stage_start_date: string = '';
  showFilterText: boolean = false;

  pageNumber: number = 0;
  pageSize: number = 10;
  searchParams: any = {};

  cargaladata: any = [];

  brokerData: AccountData[] = [];
  vendorData: AccountData[] = [];
  supplierData: AccountData[] = [];
  brands: any[] = [];

  data: any = [];
  data_table: any = [];

  sortedKeys: string[] = [];

  displayedColumns: string[] = ['month_year', 'sent', 'risk_started', 'risk_approved', 'contract_signed', 'vehicle_delivered'];


  chartLabels: string[] = [];
  datasetSent: number[] = [];
  datasetRiskStarted: number[] = [];
  datasetRiskApproved: number[] = [];
  datasetContractSigned: number[] = [];
  datasetVehicleDelivered: number[] = [];

  myLineChart!: Chart;


  orderStageData: {
    stage: string;
    requiresAction: number;
    noAction: number;
  }[] = [];

  @ViewChild(MatSort) sort!: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private reportsService: ReportsService,
    private accountService: AccountsService,
    private brandService: BrandService,
    private translateService: TranslateService
  ) {
    this.accountType = this.localStorageService.getItem('account_type');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.type = params.get('type') || 'evolution';
      this.fetchAllData();
    });
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData2(),
      this.fetchData3(),
    ]).then(() => {
      this.cargaladata.flag = true;
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve();
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData3(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.brandService.getData().subscribe({
        next: (data) => {
          this.cargaladata.brands = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.fetchOrderStages();
  }

  fetchOrderStages(): Promise<void> {
    this.showFilterText = true;
    return new Promise((resolve, reject) => {
      this.searchParams['type'] = this.type;

      this.reportsService.getDataEvolution(this.pageNumber,
        this.pageSize, this.searchParams).subscribe({
          next: (data) => {
            this.data = data.data.chartData;
            this.data_table = data.data.tableData.data;



            this.pageNumber = data.data.tableData.meta.current_page;
            this.pageSize = data.data.tableData.meta.per_page;

            this.paginator.pageSizeOptions = [5, 10, 20];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.data.tableData.meta.total;




            this.setupTable(this.sortColumn);
            this.setupChart();

            this.alertService.close();
            resolve();
          },

          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }

  setupTable(order: any = 'asc'): void {
    this.dataSource.data = this.data_table;

    const formattedData = Object.keys(this.data_table).map(key => ({
      ...this.data_table[key],
    /*   risk_started: this.data_table[key].ratio_risk_started ? this.data_table[key].risk_started + " (" + this.data_table[key].ratio_risk_started + ")" : this.data_table[key].risk_started,
      risk_approved: this.data_table[key].ratio_risk_approved ? this.data_table[key].risk_approved + " (" + this.data_table[key].ratio_risk_approved + ")" : this.data_table[key].risk_approved,
      contract_signed: this.data_table[key].ratio_contract_signed ? this.data_table[key].contract_signed + " (" + this.data_table[key].ratio_contract_signed + ")" : this.data_table[key].contract_signed,
      vehicle_delivered: this.data_table[key].ratio_vehicle_delivered ? this.data_table[key].vehicle_delivered + " (" + this.data_table[key].ratio_vehicle_delivered + ")" : this.data_table[key].vehicle_delivered,
 */

    }));

    // Ordenamos los datos por fecha (YYYY-MM)
    formattedData.sort((a, b) => {
      const [yearA, monthA] = a.date.split('-').map(Number);
      const [yearB, monthB] = b.date.split('-').map(Number);
      const dateA = new Date(yearA, monthA - 1).getTime();
      const dateB = new Date(yearB, monthB - 1).getTime();

      if (order === 'asc') {
        return dateA - dateB; // Orden ascendente
      } else {
        return dateB - dateA; // Orden descendente
      }
    });

    this.dataSource.data = formattedData;
  }

  async setupChart(): Promise<void> {
    const sortedKeys = Object.keys(this.data).sort();
    this.chartLabels = [];
    this.datasetSent = [];
    this.datasetRiskStarted = [];
    this.datasetRiskApproved = [];
    this.datasetContractSigned = [];
    this.datasetVehicleDelivered = [];

    sortedKeys.forEach(period => {
      const item = this.data[period];

      // Agregamos la etiqueta (el eje X)
      this.chartLabels.push(period);

      // Tomamos directamente los valores, sin sumarlos

      this.datasetSent.push(item.sent);
      this.datasetRiskStarted.push(item.risk_started);
      this.datasetRiskApproved.push(item.risk_approved);
      this.datasetContractSigned.push(item.contract_signed);
      this.datasetVehicleDelivered.push(item.vehicle_delivered);
    });


    // 3. Creamos el gráfico
    this.createLineChart();
  }

  createLineChart() {
    const canvas = document.getElementById('myLineChart') as HTMLCanvasElement;
    if (canvas) {
      if (this.myLineChart) {
        this.myLineChart.data.labels = this.chartLabels;
        this.myLineChart.data.datasets[0].data = this.datasetSent;
        this.myLineChart.data.datasets[1].data = this.datasetRiskStarted;
        this.myLineChart.data.datasets[2].data = this.datasetRiskApproved;
        this.myLineChart.data.datasets[3].data = this.datasetContractSigned;
        this.myLineChart.data.datasets[4].data = this.datasetVehicleDelivered;
        this.myLineChart.update();
      } else {
        this.myLineChart = new Chart(canvas, {
          type: 'line',
          data: {
            labels: this.chartLabels,
            datasets: [
              {
                label: this.translateService.instant('tables.columns.sent'),
                data: this.datasetSent,
                borderColor: '#fdedc4',
                backgroundColor: '#fdedc4',
                tension: 0.1,
                fill: false
              },
              {
                label: this.translateService.instant('tables.columns.risk_started'),
                data: this.datasetRiskStarted,
                borderColor: 'orange',
                backgroundColor: 'orange',
                tension: 0.1,
                fill: false
              },
              {
                label: this.translateService.instant('tables.columns.risk_approved'),
                data: this.datasetRiskApproved,
                borderColor: '#5c8fe0',
                backgroundColor: '#5c8fe0',
                tension: 0.1,
                fill: false
              },
              {
                label: this.translateService.instant('tables.columns.contract_signed'),
                data: this.datasetContractSigned,
                borderColor: '#67b1b5',
                backgroundColor: '#67b1b5',
                tension: 0.1,
                fill: false
              },
              {
                label: this.translateService.instant('tables.columns.vehicle_delivered'),
                data: this.datasetVehicleDelivered,
                borderColor: '#d69deb',
                backgroundColor: '#d69deb',
                tension: 0.1,
                fill: false
              },
            ]
          },
          options: {
            responsive: true,
            aspectRatio: 3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Periodo (YYYY-MM)',
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Acumulado'
                },
                beginAtZero: true
              }
            }
          }
        });
      }

    }

    this.alertService.close();
  }

  filterChanged(searchParams: any): void {
    let aux = searchParams;
    if (!aux.end_date) {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      aux.end_date = `${year}-${month}-${day}`;
    }

    if (!aux.start_date) {
      const today = new Date();
      today.setFullYear(today.getFullYear() - 1); // Restar un año
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();
      aux.start_date = `${year}-${month}-${day}`;
    }
    aux.stage_for_date = aux.stage_for_date || 'sent';
    this.stage = this.translateService.instant('reports.stages.' + aux.stage_for_date);
    this.stage_start_date = searchParams.start_date || ''
    this.stage_end_date = searchParams.end_date || '';
    this.searchParams = searchParams;
    this.pageNumber = 1;
    this.alertService.loading('swal_messages.please_wait');

    this.fetchOrderStages()
      .then(() => {
        this.alertService.close();
      })
      .catch((error) => {
        console.error('Error loading clients:', error);
        this.alertService.error('Error loading clients');
      });
  }
  sortMap: { [key: string]: string } = {
    month_year: 'month_year',
  };

  onSortChange2(event: string) {
    this.sortColumn = this.sortMap[String(event)];
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
      this.setupTable('desc');
    } else {
      this.sortDirection = 'asc';
      this.setupTable('asc');
    }


  }

  getDirection(): string {
    const directionMap: { [key: string]: string } = {
      asc: 'icon-arrow-up',
      desc: 'icon-arrow-down',
    };

    return directionMap[this.sortDirection] || 'icon-arrow-up';
  }
  //GRAFICO
  private async translateLabels(labels: string[]): Promise<string[]> {
    const translations = await Promise.all(
      labels.map((label) =>
        this.translateService.get(`reports.labels.${label}`).toPromise()
      )
    );
    return translations;
  }


}
