import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { FormatDatePipe } from '../../../../core/pipe/custom-pipes';

@Component({
  selector: 'app-delivery-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    FormatDatePipe],
  templateUrl: './delivery-form.component.html',
  styleUrl: './delivery-form.component.css'
})
export class DeliveryFormComponent {
  @Input() orderData?: any;
  @Input() ladata: any = [];

  deliveryForm: FormGroup;
  delivery_place: any[] = [];
  delivery_status: any[] = [];
  delivery_dealer: any[] = [];
  delivery_urgency_: any[] = ['high', 'medium', 'low', 'custom'];
  workshopData: any[] = [];

  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService) {

    this.deliveryForm = this.fb.group({
      delivery_address: ['', Validators.required],
      delivery_postal_code: ['', Validators.required],
      delivery_contact: ['', Validators.required],
      delivery_phone: ['', Validators.required],
      delivery_city: ['', Validators.required],
      delivery_place: ['', Validators.required],
      delivery_dealer: ['', Validators.required],
      delivery_status: ['', Validators.required],
      delivery_first_expected_date: ['', Validators.required],
      delivery_expected_date: ['', Validators.required],
      delivery_date: ['', Validators.required],
      delivery_delay_notes: ['', Validators.required],
      delivery_urgency: ['', Validators.required],
      delivery_client_expected_date: ['', Validators.required],
      manufacture_expected_date: ['', Validators.required],
      workshop_id: ['', Validators.required],
    });
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        this.delivery_place = this.ladata.delivery_place;
        this.delivery_status = this.ladata.delivery_status;
        this.workshopData = this.ladata.workshopData;
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  refrescarFormulario() {
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.deliveryForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
    }
    this.alertService.close();
  }

  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.deliveryForm.controls) {
      if (this.deliveryForm.controls.hasOwnProperty(field)) {
        this.deliveryForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.deliveryForm.get(field)?.enable();
      }
    }

  }

  checkUpdateForm(): boolean {
    if (this.deliveryForm.dirty) {
      return true;
    } else {
      return false;
    }
  }
  copyToClipboard(value: string, event: Event): void {
    // Convertir el valor a mayúsculas y eliminar acentos
    const transformedValue = this.removeAccents(value.toUpperCase());

    // Crear un textarea para copiar el valor
    const textarea = document.createElement('textarea');
    textarea.value = transformedValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  // Función para eliminar acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

}
