<div class="encabezado">
  <div class="col-4 getBack "> <a routerLink="/reports" class="getBack"> <span><i class="icon-chevron-left"></i>{{
        "forms.buttons.back" | translate }} </span></a></div>
  <div class="">
    <div class="">
      <div>
        <h5 class=" titulo1">{{ type === 'contracts' ? ('reports.contracts2' | translate) :
          ('reports.after-sales2' | translate) }}</h5>
      </div>
    </div>
  </div>

  <div class="filter-content row">
    <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"  type="contracts" ></app-filter>
  </div>

  <div class="filterText" *ngIf="showFilterText">
    <h4>{{ "reports.labels.result_title" | translate: { stage:
      stage,start_date:stage_start_date | formatDatePipe ,end_date:stage_end_date | formatDatePipe } }} </h4>
  </div>
  <div class="tabla">
    <div class="d-flex justify-content-start">
      <div class=" p-1">
        <div class="reports-card d-flex">
          <div class="report-title">
            <p>{{ 'reports.orders' | translate }}</p>
          </div>
          <div class="reports-card-p to">
            <div class="text">
              <span>{{ totalOrders }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class=" p-1">
        <div class="reports-card d-flex">
          <div class="report-title">
            <p>{{'reports.not_required_action' | translate}}</p>
          </div>
          <div class="reports-card-p">
            <div class="text">
              <span>{{ notRequireActionTotal }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class=" p-1">
        <div class="reports-card d-flex">
          <div class="total">
            <div class="report-title">
              <p>{{'reports.action_required' | translate}} </p>
            </div>
          </div>
          <div class="reports-card-p to">
            <div class="text">
              <span>{{ requireActionTotal }}</span>
            </div>
          </div>
          <div class="reports-card-p p0">
            <div class="text">
              <span>{{ requireActionPriority0 }}</span>
            </div>
          </div>
          <div class="reports-card-p p1">
            <div class="text">
              <span>{{ requireActionPriority1 }}</span>
            </div>
          </div>
          <div class="reports-card-p p2 ">
            <div class="text">
              <span>{{ requireActionPriority2 }}</span>
            </div>
          </div>
          <div class="reports-card-p p3 ">
            <div class="text">
              <span>{{ requireActionPriority3 }}</span>
            </div>
          </div>
          <div class="reports-card-p p4">
            <div class="text">
              <span>{{ requireActionPriority4 }}</span>
            </div>
          </div>
          <!-- <div class="text">
            <span>( Total : {{ requireActionTotal }} )</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="chart-container  d-flex justify-content-center ">
    <canvas id="myChart" ></canvas>
  </div>

  <div>
    <app-order-table [searchParamsClickBar]="searchParamsClickBar"></app-order-table>
  </div>


</div>

<div class="overlay" *ngIf="showForm"></div>
