import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common'; // Asegúrate de importar CommonModule
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '../../../core/services/alert/alert.service';
import { DocumentsService } from '../../../core/services/documents/documents.service';
import { DocumentsTypeService } from '../../../core/services/documentType/documents-type.service';
import { FilterSelectTypesPipe } from '../../../core/pipe/custom-pipes';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
interface FileItem {
  file: File;
  selectOption: string;
  key: string;
}
interface FormConfig {
  reference: any;
  documents: any;
  private_documents: any;
  label: string;
  type: any;
  ruleId: any;
  documentFunction?: any;
  allDocumentFunction?: any;
}

@Component({
  selector: 'app-form-add-document',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FilterSelectTypesPipe,
  ],
  templateUrl: './form-add-document.component.html',
  styleUrl: './form-add-document.component.css',
})
export class FormAddDocumentComponent implements OnChanges {

  @Output() backButtonClickDocument = new EventEmitter<boolean>();
  @Output() addDocuments = new EventEmitter<boolean>();
  @Output() formSubmit = new EventEmitter<any>();
  @Output() filesArrayChanged: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() goDocs = new EventEmitter<any>();

  account_type = this.localStorageService.getItem('account_type');

  @Input() clientData?: any;
  @Input() orderData?: any;
  @Input() guaranteeData?: any;
  @Input() invoiceData?: any;

  @Input() activeForm?: any;
  @Input() selectTypes?: any;
  @Input() ladata: any = {};
  @Input() rules: any = {};

  @Input() btn_submit?: any;

  existingDocuments: any[] = [];
  showExistingDocuments = false;

  orderDocumentType: string = 'type';

  isDisabled: boolean = true;
  label: string = 'client';
  varSelectType = 'orders';
  uploadFile = true;

  formConfigs: { [key: string]: FormConfig } = {};




  activeDocumentTab: number = 0; // Índice del tab activo
  showDocumentsTab: boolean = false; // Mostrar o no el tab de documentos
  showDocument: string = 'public'; // Tipo de documento a mostrar
  tabs = [
    { title: 'public', key: 'public' },
    { title: 'private', key: 'private' }
  ];

  setActiveTab(index: number) {
    this.activeDocumentTab = index;
    this.showDocument = this.tabs[index].key;
    this.existingDocuments = this.tabs[index].key == 'public' ? this.formConfigs[this.activeForm].documents : this.formConfigs[this.activeForm].private_documents;
  }

  constructor(
    private alertService: AlertService,
    private documentService: DocumentsService,
    private documentTypeService: DocumentsTypeService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this.getTypes();
  }

  getTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.selectTypes = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['activeForm'] || changes['rules'] || changes['orderData'] || changes['clientData'] || changes['guaranteeData'] || changes['invoiceData']) {
      console.log("🚀 ~ FormAddDocumentComponent ~ ngOnChanges ~ changes['activeForm']:", this.clientData)
      this.rules = this.ladata.rules;
      this.resetFormState();
      this.formConfigs = {
        client: {
          reference: this.clientData?.reference,
          documents: this.clientData?.documents,
          private_documents: this.clientData?.private_documents,
          label: 'client',
          type: this.clientData?.type,
          ruleId: this.rules?.client_id,
          documentFunction: this.documentService.getClientDocuments.bind(
            this.documentService
          ),
        },
        assignee: {
          reference: this.clientData?.reference,
          documents: this.clientData?.documents,
          private_documents: this.clientData?.private_documents,
          label: 'assignee',
          type: this.clientData?.type,
          ruleId: this.rules?.client_id,
          documentFunction: this.documentService.getClientDocuments.bind(
            this.documentService
          ),
        },
        guarantee: {
          reference: this.guaranteeData?.reference,
          documents: this.guaranteeData?.documents,
          private_documents: this.guaranteeData?.private_documents,
          label: 'guarantee',
          type: this.guaranteeData?.type,
          ruleId: this.rules?.guarantee_id,
          documentFunction: this.documentService.getClientDocuments.bind(
            this.documentService
          ),
        },
        invoice: {
          reference: this.invoiceData?.reference,
          documents: this.invoiceData?.documents,
          private_documents: this.invoiceData?.private_documents,
          label: 'invoice',
          type: 'invoice',
          ruleId: null,
          documentFunction: this.documentService.getinvoiceDocuments.bind(
            this.documentService
          ),
        },
        'doc-order': {
          reference: this.orderData?.reference,
          documents: this.orderData?.documents,
          private_documents: this.orderData?.private_documents,
          label: 'order',
          type: 'order',
          ruleId: this.rules?.documents,
          documentFunction: this.documentService.getOrderDocuments.bind(
            this.documentService
          ),
          allDocumentFunction: this.documentService.downloadAllDocuments.bind(
            this.documentService
          ),
        },
      };
      this.updateFormState(this.formConfigs[this.activeForm]);
    }
  }

  private resetFormState(): void {
    this.files = [];
    this.showExistingDocuments = false;
    this.existingDocuments = [];
    this.isDisabled = true;
  }

  private updateFormState(config: FormConfig | undefined): void {
    if (config && config.reference) {
      this.existingDocuments = this.showDocument == 'public' ? config.documents : config.private_documents;
      console.log("🚀 ~ FormAddDocumentComponent ~ config.documentFunction ~ this.existingDocuments:", this.existingDocuments)
      this.isDisabled = false;
      this.label = config.label;
      this.varSelectType = config.type;
      this.uploadFile = true;
      if (config.ruleId === 'r') {
        this.uploadFile = false;
      }
      this.showDocumentsTab = config.private_documents.length > 0;
      this.showExistingDocuments = this.existingDocuments.length > 0;
    }
  }

  getDocuments() {
    const config = this.formConfigs[this.activeForm];
    if (config && config.reference && config.documentFunction) {
      this.alertService.loading('swal_messages.search_documents');
      config.documentFunction(config.reference).subscribe({
        next: (data: any) => {
          this.existingDocuments = data.data;
          this.showExistingDocuments = this.existingDocuments.length > 0;
          this.alertService.close();
        },
        error: (error: any) => {
          this.existingDocuments = [];
          this.alertService.close();
        },
      });
    } else {
      this.alertService.close();
    }
  }

  emitBackButtonClick() {
    this.files = [];
    this.existingDocuments = [];
    this.filesArrayChanged.emit(this.files);
    this.backButtonClickDocument.emit(true);
  }

  emitAddDocuments() {
    this.addDocuments.emit(true);
  }

  onSubmit() {
    const isValid = this.checkSelectOptionDiversity(this.files);

    if (!isValid) {
      this.alertService.error('swal_messages.document_type_excess');
    } else {
      this.alertService.loading(
        'Por favor espera mientras se añaden los documentos.'
      );

      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append(`file[${i}]`, this.files[i].file);
        formData.append(`type[${i}]`, this.files[i].selectOption);
      }
      formData.append('visibility', this.showDocument);
      switch (this.activeForm) {
        case 'assignee':
        case 'guarantee':
        case 'client':
          formData.append('client_id', this.clientData.reference);
          break;
        case 'invoice':
          formData.append('invoice_id', this.invoiceData.reference);
          break;
        case 'doc-order':
          formData.append('order_id', this.orderData.reference);
          break;
      }

      this.documentService.addDocument(formData).subscribe({
        next: (data) => {
          this.alertService
            .successOk('swal_messages.add_document_success')
            .then((result) => {
              if (result.isConfirmed) {
                this.files = [];
                this.filesArrayChanged.emit(this.files);
                this.formSubmit.emit(true);
              }
            });
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
    }
  }

  downloadAllDocuments() {
    console.log("🚀 ~ FormAddDocumentComponent ~ downloadAllDocuments ~ downloadAllDocuments:", "downloadAllDocuments")
    this.alertService.loading('swal_messages.please_wait_documents');
    const config = this.formConfigs[this.activeForm];
    if (config && config.reference && config.documentFunction) {
      config.allDocumentFunction(config.reference).subscribe({
        next: (data: any) => {
          let url = data.data.zip_url;
          const a = document.createElement('a');
          a.href = url;
          a.click();
          this.alertService.close();
        },
        error: (error: any) => {
          this.alertService.close();
        },
      });
    } else {
      // Manejar el caso en el que activeForm no sea ninguno de los valores esperados
      this.alertService.close();
    }
  }


  deleteFile(reference: any) {
    this.alertService
      .confirmation(
        'swal_messages.warning_confirm',
        'swal_messages.delete_document',
        'swal_messages.confirm'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.alertService.loading(
            'swal_messages.please_wait'
          );

          this.documentService.deleteDocument(reference).subscribe({
            next: (data) => {
              this.alertService
                .successOk('swal_messages.delete_document_success')
                .then((result) => {
                  if (result.isConfirmed) {
                    this.getDocuments();
                  }
                });
            },
            error: (error) => {
              this.alertService.error(error.error).then((result) => {
                if (result.isConfirmed) {
                }
              });
            },
          });
        }
      });
  }

  checkSelectOptionDiversity(
    data: Array<{ file: any; selectOption: string; key: string }>
  ): boolean {
    if (data.length <= 3) {
      return true; // Si hay 3 o menos elementos, no hay restricciones.
    }
    // Contar las ocurrencias de cada `selectOption`.
    const optionCounts: Record<string, number> = {};
    data.forEach((item) => {
      const option = item.selectOption;
      optionCounts[option] = (optionCounts[option] || 0) + 1;
    });

    // Verificar si algún `selectOption` excede el 70 %.
    const total = data.length;
    for (const option in optionCounts) {
      if (optionCounts['other'] && optionCounts['other'] / total >= 0.7) {
        return false;
      }
    }
    return true; // Si no hay ningún `selectOption` con el 70 % o más, cumple.
  }

  changeForm(form: string) {
    this.activeForm = form;
    this.getDocuments();
  }

  orderDocs(type: string) {
    this.orderDocumentType = type;
    this.sortDocumentsByDate(this.orderDocumentType);

  }

  sortDocumentsByDate(type: string) {
    if (type == 'type') {
      this.existingDocuments = this.existingDocuments.sort((a, b) => {
        return a.type.localeCompare(b.type);
      });
    }
    if (type == 'date_asc') {
      this.existingDocuments = this.existingDocuments.sort((a, b) => {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      });
    }
    if (type == 'date_desc') {
      this.existingDocuments = this.existingDocuments.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });
    }
  }

  //--------------------------------------------------------------------
  //------------------IMGDRAG AND DROP STUFF----------------------------
  //--------------------------------------------------------------------

  @Output() filesDropped = new EventEmitter<FileList>();
  files: FileItem[] = [];

  actualizarArchivos(nuevosArchivos: any[]) {
    this.files = nuevosArchivos;
    this.filesArrayChanged.emit(this.files);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, false);

    const newFiles = event.dataTransfer?.files;
    if (newFiles && newFiles.length > 0) {
      // Add the new files to the existing list
      for (const file of Array.from(newFiles)) {
        if (
          !this.files.some(
            (f) => f.file.name === file.name && f.file.size === file.size
          )
        ) {
          var selectOption = 'defaultOption';
          this.alertService.loading('swal_messages.please_wait');
          var opt = this.activeForm == 'doc-order' ? 'orders' : 'clients';
          this.documentTypeService.decodeType(opt, file.name).subscribe({
            next: (data) => {
              this.alertService.close();
              selectOption = data.data;
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
            error: (error) => {
              this.alertService.close();
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
          });
        }
      }
      this.filesDropped.emit(newFiles);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, true);
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleHighlight(event, false);
  }

  private toggleHighlight(event: DragEvent, isOver: boolean) {
    const target = event.target as HTMLElement;
    if (target) {
      target.classList.toggle('over', isOver);
    }
  }

  isImage(file: File): boolean {
    return file.type.startsWith('image/');
  }

  getThumbnailSrc2(name: string): string {
    const patterns = {
      jpg: /\.(jpg|jpeg|png)$/i,
      pdf: /\.pdf$/i,
      doc: /\.(docx?|dotx?)$/i,
      xls: /\.(xls?|xlsx?)$/i,
      zip: /\.zip$/i,
    };

    for (const [key, pattern] of Object.entries(patterns)) {
      if (pattern.test(name)) {
        return `../../../assets/icons/${key}.svg`;
      }
    }

    return '../../../assets/icons/file.svg';
  }

  getThumbnailSrc3(file: File): string {
    const patterns = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      pdf: 'application/pdf',
      doc: 'application/msword',
      csv: 'text/csv',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xslx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'word-openxml':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      'xls-openxml':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    for (const [key, mimeType] of Object.entries(patterns)) {
      if (file.type === mimeType) {
        switch (key) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            return `../../../assets/icons/jpg.svg`;
            break;
          case 'pdf':
            return `../../../assets/icons/pdf.svg`;
            break;
          case 'word-openxml':
          case 'doc':
          case 'docx':
            return `../../../assets/icons/doc.svg`;
            break;
          case 'xls-openxml':
          case 'xls':
            return `../../../assets/icons/xls.svg`;
            break;
          default:
            return '../../../assets/icons/file.svg';
            break;
        }
      }
    }
    return '../../../assets/icons/file.svg';
  }

  getThumbnailSrc(file: File): string {
    const mimeToIcon: Record<string, string> = {
      'image/jpeg': '../../../assets/icons/jpg.svg',
      'image/png': '../../../assets/icons/jpg.svg',
      'application/pdf': '../../../assets/icons/pdf.svg',
      'application/msword': '../../../assets/icons/doc.svg',
      'text/csv': '../../../assets/icons/file.svg',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '../../../assets/icons/doc.svg',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '../../../assets/icons/xls.svg',
      'application/vnd.ms-excel': '../../../assets/icons/xls.svg'
    };

    return mimeToIcon[file.type] || '../../../assets/icons/file.svg';
  }

  onFileInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const newFiles = input.files;
    if (newFiles && newFiles.length > 0) {
      for (const file of Array.from(newFiles)) {
        if (
          !this.files.some(
            (f) => f.file.name === file.name && f.file.size === file.size
          )
        ) {
          var selectOption = 'defaultOption';
          this.alertService.loading('swal_messages.please_wait');
          var opt = this.activeForm == 'doc-order' ? 'orders' : 'clients';
          this.documentTypeService.decodeType(opt, file.name).subscribe({
            next: (data) => {
              this.alertService.close();
              selectOption = data.data;
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
            error: (error) => {
              this.alertService.close();
              const newFileItem: FileItem = {
                file: file,
                selectOption: selectOption,
                key: selectOption,
              };
              this.files.push(newFileItem);
              this.filesArrayChanged.emit(this.files);
            },
          });
        }
      }
      this.filesDropped.emit(newFiles);
    }
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  selectOptionChanged(event: Event, index: number) {
    const selectElement = event.target as HTMLSelectElement;
    const option = selectElement.value;
    this.files[index].selectOption = option;
  }

  pickAColor(type: string): string {
    const colorMap: Record<string, string> = {
      'identification': '#f0ab96',
      'organization_identification': '#f0ab96',
      'offer': '#f0ab96',

      'payroll': '#f0d3b1',
      'company_deed': '#f0d3b1',
      'offer_signed': '#f0d3b1',

      'employment_record': '#a7cce0',
      'ownership': '#a7cce0',
      'contract_signed': '#a7cce0',

      'bank_account_ownership': '#9daff0',
      'balance': '#9daff0',
      'guarantee_contract': '#9daff0',

      'receipt': '#c19fc2',
      'p_and_l': '#c19fc2',
      'guarantee_contract_signed': '#c19fc2',

      'pension_revaluation': '#f5f3a8',
      '200_organization_income_tax_return': '#f5f3a8',
      'delivery_certificate': '#f5f3a8',

      '037_census_declaration': '#ded9d5',
      '303_vat_return': '#ded9d5',
      'contract': '#ded9d5',

      '100_income_tax_return': '#8db99e',
      '390_vat_summary': '#8db99e',

      '130_quaterly_income_tax_return': '#4fc3c2',
      '131_quaterly_income_tax_return': '#a2d4df',

      'aux1': '#eaa3b5',
      'aux2': '#c2dbbe',
      'axu3': '#f9f6c1',
      'aux4': '#f7bd98'
    };

    return colorMap[type] || '#d0e5e8';
  }

  getDownloadName(documentName: string): string {
    // Aquí puedes personalizar el nombre de descarga como desees
    const timestamp = new Date().getTime();
    return `${documentName}_${timestamp}.pdf`; // Cambia la extensión según el tipo de archivo
  }

  emitgoDocs() {
    this.goDocs.emit(true);
  }


}
