import { Component, ElementRef, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Orders } from '../../../core/interfaces/orders';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { AlertService } from '../../../core/services/alert/alert.service';

@Component({
  selector: 'app-order-table',
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule, MatTableModule,
    MatPaginatorModule],
  templateUrl: './order-table.component.html',
  styleUrl: './order-table.component.css'
})
export class OrderTableComponent {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('contenedor') contenedor!: ElementRef;
  @Input() typeOrders?: any; // Objeto opcional
  @Input() orderData?: any; // Objeto opcional
  @Input() searchParamsClickBar?: any; // Objeto opcional


  type: any = 'broker';
  displayedColumns: string[] = [];
  pageNumber: number = 1;
  pageSize: number = 25;
  sortColumn = 'stage_updated_at';
  sortDirection = 'asc';
  stage = '';
  showTable = false;

  dataSource = new MatTableDataSource<Orders>();
  title = 'orders';

  expandedRow: any = null;
  displayedColumnsBroker: string[] = [
    'broker_reference',
    'broker_agent',
    'supplier',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'supplier_reference',
    'supplier_agent',
    'broker',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private orderService: OrdersService,
    private alertService: AlertService
  ) {
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }
  @ViewChild('filterText') filterText!: ElementRef;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchParamsClickBar'] && changes['searchParamsClickBar'].currentValue) {
      if (!changes['searchParamsClickBar'].isFirstChange()) {
        this.stage = this.searchParamsClickBar['stage[in]'];
        Promise.all([
          this.fetchOrderData()
        ]).then(() => {
          setTimeout(() => {
            this.alertService.close();
            if (this.filterText && this.filterText.nativeElement) {
              this.filterText.nativeElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
              });
            }

          }, 500);
        });



      }
    }
  }


  fetchOrderData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getData(
          this.typeOrders,
          this.pageNumber,
          this.pageSize,
          this.searchParamsClickBar,
          this.sortColumn,
          this.sortDirection
        )
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(
                this.localStorageService.getItem('per_page')
              );
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
            this.showTable = true;
            this.alertService.close();

            resolve();
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }


  toggleRow(row: any): void {
    //this.expandedRow = this.expandedRow === row ? null : row;
    this.expandedRow = row;
  }

  getDirection(): string {
    const directionMap: { [key: string]: string } = {
      asc: 'icon-arrow-up',
      desc: 'icon-arrow-down',
    };

    return directionMap[this.sortDirection] || 'icon-arrow-up';
  }


  getBadgeColor(
    stage: string,
    require_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!require_action) {
      return statusColorMap['false'];
    }

    if (require_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }


  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    this.fetchOrderData();

  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    };
    return statusColorMap[status] || 'text-new';
  }

  redirect(reference: string): void {
    this.router.navigate([`/orders/${reference}`]);
  }


}
