<div class="mb-3 buscador">
  <div class="">
    <button class="btn btn-hover filter" type="button" (click)="toggleDropdown()">
      {{ filterButtonLabel }} <i class="icon-equalizer"></i>
    </button>
    <div *ngIf="showDropdown" class="dropdown-content" role="menu" (mouseenter)="cancelCloseTimeout()"
      (mouseleave)="startCloseTimeout()">
      <div class="filter-content">
        <div class="clear-filter mb-3">
          <div>{{ "buttons.filter" | translate }}</div>
        </div>

            <div class="mb-3" *ngIf="accountType !== 'broker'">
              <div class="main-selector-container">
                <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleBroker }">
                  <input class="text" #brokerInput type="text" (focus)="onFocus('broker')" (blur)="hideOptions()"
                    [(ngModel)]="brokerSearchTerm" (input)="searchBroker(brokerSearchTerm)"
                    placeholder="{{ 'forms.inputs.broker' | translate }}" [value]="displayTextBroker" />
                  <span *ngIf="clearableBroker" class="clear-icon" (click)="
                                clearSelectedOption('broker'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
                  <span class="icon-chevron-down"></span>
                </div>
                <div class="options-container" *ngIf="optionsVisibleBroker" [ngClass]="{ visible: optionsVisibleBroker }">
                  <div *ngFor="let broker of brokerAccounts" class="option-value" (mousedown)="
                                onSelectBroker(broker,brokerInput)">
                    <div>{{ broker.name }}</div>
                  </div>
                </div>
              </div>


            </div>

            <div class="mb-3" *ngIf="accountType !== 'supplier'">
              <div class="main-selector-container">
                <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleSupplier }">
                  <input class="text" #supplierInput type="text" (focus)="onFocus('supplier')" (blur)="hideOptions()"
                    [(ngModel)]="supplierSearchTerm" (input)="searchSupplier(supplierSearchTerm)"
                    placeholder="{{ 'forms.inputs.supplier' | translate }}" [value]="displayTextSupplier" />
                  <span *ngIf="clearableSupplier" class="clear-icon" (click)="
                                clearSelectedOption('supplier'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
                  <span class="icon-chevron-down"></span>
                </div>
                <div class="options-container" *ngIf="optionsVisibleSupplier" [ngClass]="{ visible: optionsVisibleSupplier }">
                  <div *ngFor="
                                let supplier of supplierAccounts
                              " class="option-value" (mousedown)="onSelectSupplier(supplier, supplierInput)">
                    <div>{{ supplier.name }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mb-3" *ngIf="accountType !== 'vendor'">
              <div class="main-selector-container">
                <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleVendor }">
                  <input class="text" #vendorInput type="text" (focus)="onFocus('vendor')" (blur)="hideOptions()"
                    [(ngModel)]="vendorSearchTerm" (input)="searchVendor(vendorSearchTerm)"
                    placeholder="{{ 'forms.inputs.vendor' | translate }}" [value]="displayTextVendor" />
                  <span *ngIf="clearableVendor" class="clear-icon" (click)="
                                clearSelectedOption('vendor'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
                  <span class="icon-chevron-down"></span>
                </div>
                <div class="options-container" *ngIf="optionsVisibleVendor" [ngClass]="{ visible: optionsVisibleVendor }">
                  <div *ngFor="let vendor of vendorAccounts" class="option-value" (mousedown)="
                                onSelectVendor(vendor,vendorInput)">
                    <div>{{ vendor.name }}</div>
                  </div>
                </div>
              </div>
            </div>

        <div class="mb-3">
          <div class="main-selector-container">
            <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleAgent }">
              <input class="text" #agentInput type="text" (focus)="onFocus('agent')" (blur)="hideOptions()"
                [(ngModel)]="agentSearchTerm" (input)="searchAgent(agentSearchTerm)"
                placeholder="{{ 'forms.inputs.agent' | translate }}" [value]="displayTextAgent" />
              <span *ngIf="clearableAgent" class="clear-icon" (click)="
                                clearSelectedOption('agent'); $event.stopPropagation()
                              "><i class="icon-cross2"></i></span>
              <span class="icon-chevron-down"></span>
            </div>
            <div class="options-container" *ngIf="optionsVisibleAgent" [ngClass]="{ visible: optionsVisibleAgent }">
              <div *ngFor="let agent of agentAccounts" class="option-value" (mousedown)="
                                onSelectAgent(agent,agentInput)">
                <div>{{ agent.name }} {{agent.lastname}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="main-selector-container">
            <div class="selector-container-supplier" [ngClass]="{ expanded: optionsVisibleStage }">
              <input class="text" #stageInput type="text" (focus)="onFocus('stage')" (blur)="hideOptions()"
                [(ngModel)]="stageSearchTerm" placeholder="{{ 'filter.stage' | translate }}"
                (input)="searchStages(stageSearchTerm)" [value]="displayTextStage" />
              <span *ngIf="clearableStage" class="clear-icon" (click)="
                            clearSelectedOption('stage'); $event.stopPropagation()
                          "><i class="icon-cross2"></i></span>

              <span class="icon-chevron-down"></span>
            </div>
            <div class="options-container" *ngIf="optionsVisibleStage" [ngClass]="{ visible: optionsVisibleStage }">
              <div class="option-value" *ngFor="let stage of filteredStages" (mousedown)="onSelectStage(stage, stageInput)">
                <div>{{ "forms.inputs.status_select."+stage.key | translate}}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="showClear && hasActiveFilters()" class="clear">
    <button class="btn btn-hover clear-button" type="button" (click)="clearFilter($event)">
      {{ "buttons.clearFilter" | translate }}
    </button>
  </div>

</div>
