<div class="encabezado">
  <h5 class="titulo1">{{ "pages.titles.reports" | translate }}</h5>
  <div class="menu-horizontal">
    <button *ngFor="let boton of botones; let i = index" [class.selected]="i === botonSeleccionado"
      (click)="seleccionarBoton(i)">
      {{ boton | translate }}
    </button>
  </div>
  <div class="row">
    <div class="col-6">
      <a [routerLink]="['/reports', 'contracts']">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <i class="icon-clipboard-text"></i>
          </div>
          <div class="text">
            <h3>{{ "reports.contracts" | translate }}</h3>
            <!-- <p>
              {{ "automation.cardtext" | translate }}
            </p> -->
            <a class="text_see_reports">{{'reports.see-reports' | translate}} </a>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>

    <div class="col-6">
      <a [routerLink]="['/reports', 'after-sales']">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <i class="icon-clipboard-check"></i>
          </div>
          <div class="text">
            <h3>{{ "reports.after-sales" | translate }}</h3>
            <!-- <p>
              {{ "settings.profile.cardtext" | translate }}
            </p> -->
            <a class="text_see_reports">{{'reports.see-reports' | translate}} </a>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <a [routerLink]="['/reports_evo', 'evolution']">
        <div class="setting-card" (click)="changeOpt()">
          <div class="iconleft">
            <i class="icon-signal"></i>
          </div>
          <div class="text">
            <h3>{{ "reports.evolution" | translate }}</h3>
            <!-- <p>
              {{ "automation.cardtext" | translate }}
            </p> -->
            <a class="text_see_reports">{{'reports.see-reports' | translate}} </a>
          </div>
          <div class="iconright">
            <i class="icon-chevron-right"></i>
          </div>
        </div>
      </a>
    </div>
  <div *ngIf="opt" class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
