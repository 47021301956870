import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import {
  MatPaginatorModule,
  MatPaginator,
  PageEvent,
} from '@angular/material/paginator';
import { MatSortModule, MatSort, Sort } from '@angular/material/sort';
import { CommonModule, Location } from '@angular/common';
import { OrdersService } from '../../core/services/orders/orders.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormAddOrderComponent } from './form-add-order/form-add-order.component';
import { AlertService } from '../../core/services/alert/alert.service';
import { Orders } from '../../core/interfaces/orders';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsService } from '../../core/services/clients/clients.service';
import { LocalStorageService } from '../../core/services/localStorage/local-storage.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { UsersService } from '../../core/services/users/users.service';
import { DocumentsTypeService } from '../../core/services/documentType/documents-type.service';
import { BrandService } from '../../core/services/brand/brand.service';
import { FilterComponent } from './filter/filter.component';
import { Title } from '@angular/platform-browser';
import { FormAddClientComponent } from '../clients/form-add-client/form-add-client.component';
import { DataService } from '../../core/services/orders/data.service';
import { FormatDateTimePipe } from '../../core/pipe/custom-pipes';
import { FormShowAssigneeComponent } from '../shared/form-show-assignee/form-show-assignee.component';
import { FormAddAssigneeComponent } from '../clients/form-add-client/form-add-assignee/form-add-assignee.component';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any;
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
}

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    FormAddOrderComponent,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FilterComponent,
    RouterModule,
    FormAddClientComponent,
    FormatDateTimePipe,
    FormShowAssigneeComponent,
    FormAddAssigneeComponent
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css',
})

export class OrdersComponent {
  private route = inject(ActivatedRoute);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  showForm = false;
  showAddClientForm = false;
  showAssigneeForm = false;
  showClear = false;
  pageNumber: number = 1;
  pageSize: number = 100;
  sortColumn = 'updated_at';
  sortDirection = 'desc';
  order_reference?: any;
  orderData?: any;
  typeOrders: string = '';
  dataSource = new MatTableDataSource<Orders>();
  title = 'orders';

  showAlertFilterEst = false;
  showAlertFilterEst_start_date: any = '';
  showAlertFilterEst_end_date: any = '';
  showAlertFilterEst_state: any = '';
  data_est = false;
  showAlertFilter = false;

  showCountFilter = 0;
  showCountFilterEst = 0;
  showAlertFilterAll = false;
  showAlertCancelled = false;
  searchParams: any = {};
  accountType: string | null = null;

  ladata: any = [];
  cargaladata: any = [];

  document_buttons_chico = false;
  tab = 'general';
  displayedColumnsBroker: string[] = [
    'ring',
    'broker_reference',
    'broker_agent',
    'supplier',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumnsSupplier: string[] = [
    'ring',
    'supplier_reference',
    'supplier_agent',
    'broker',
    'vendor',
    'client',
    'vehicle',
    'updated_at',
    'stage_updated_at',
    'stage',
    'actions',
  ];

  displayedColumns: string[] = [];
  type: any = 'broker';
  autoFetch: any;

  // Mapa para relacionar rutas con sus títulos y tipos de orden
  routesMap: { [key: string]: string } = {
    'orders/contracts/add': 'contracts',
    'orders/contracts': 'contracts',
    'orders/drafts': 'drafts',
    'orders/sqls': 'sqls',
    'orders/frozen': 'frozen',
    'orders/offers': 'offers',
    'orders/delivered': 'delivered',
    'orders/all': '',
    'orders/after-sales': 'after-sales',
    'orders/cancelled': 'cancelled',
    'orders/denied': 'denied',
    'orders/rejected': 'rejected',
    'orders/cancellation-requested': 'cancellation-requested',
    'orders/pre-frozen': 'pre-frozen',
    orders: '',
  };

  sortMap: { [key: string]: string } = {
    vehicle: 'vehicle',
    vendor: 'vendor',
    client: 'client',
    broker: 'broker',
    supplier: 'supplier',
    supplier_reference: 'supplier_reference',
    broker_reference: 'broker_reference',
    supplier_agent: 'supplier_agent',
    broker_agent: 'broker_agent',
    updated_at: 'updated_at',
    stage_updated_at: 'stage_updated_at',
    stage: 'stage',
  };

  constructor(
    private orderService: OrdersService,
    private alertService: AlertService,
    private clientService: ClientsService,
    private location: Location,
    private localStorageService: LocalStorageService,
    private accountService: AccountsService,
    private userService: UsersService,
    private documentTypeService: DocumentsTypeService,
    private brandService: BrandService,
    private clientsService: ClientsService,
    private titleService: Title,
    private translate: TranslateService,
    private dataService: DataService
  ) {
    this.type = this.localStorageService.getItem('account_type');
    this.displayedColumns =
      this.type === 'broker'
        ? this.displayedColumnsBroker
        : this.displayedColumnsSupplier;
  }

  ngOnInit() {
    this.detectReference(),
      this.alertService.loading('swal_messages.please_wait');
    this.sortColumn = 'updated_at';
    this.sortDirection = 'desc';
    this.fetchAllData();
    this.startAutoFetch();
  }

  startAutoFetch(): void {
    if (this.autoFetch) {
      clearInterval(this.autoFetch);
    }

    this.autoFetch = setInterval(() => {
      if (!this.showForm && !this.data_est) {
        this.refreshOrdersData();
      }
    }, 40000);
  }

  private fetchAllData() {
    Promise.all([
      this.fetchData(),
      this.fetchData2(),
      this.fetchData3(),
      this.fetchData4(),
      this.fetchData5(),
      this.fetchData6(),
      this.fetchData7(),
      this.fetchData8(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      this.dataService.setLadata(this.ladata);
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  private fetchNewData() {
    Promise.all([this.fetchData()]).then(() => {
      this.ladata = this.cargaladata;
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

  agentData: AccountData[] = [];
  vendorData: AccountData[] = [];
  brokerData: AccountData[] = [];
  supplierData: AccountData[] = [];
  workshopData: AccountData[] = [];
  dealershipData: AccountData[] = [];
  selectTypes: any[] = [];
  brands: any[] = [];
  models: any[] = [];
  expandedRow: any = null;

  toggleRow(row: any): void {
    //this.expandedRow = this.expandedRow === row ? null : row;
    this.expandedRow = row;
  }

  fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getData(
          this.typeOrders,
          this.pageNumber,
          this.pageSize,
          this.searchParams,
          this.sortColumn,
          this.sortDirection
        )
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.dataSource.sort = this.sort;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(
                this.localStorageService.getItem('per_page')
              );
            }
            this.paginator.pageSizeOptions = [25, 50, 100];
            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageNumber - 1;
            this.paginator.length = data.meta.total;
            resolve();
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  }
  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve(); // Termina la ejecución y resuelve la promesa si no hay datos
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          this.cargaladata.workshopData = data.filter(
            (item) => item.type === 'workshop'
          );
          this.cargaladata.dealershipData = data.filter(
            (item) => item.type === 'dealership'
          );
          resolve();
        },
        error: (error) => {
          reject(error); // Rechaza la promesa en caso de error
        },
      });
    });
  }
  fetchData3(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.brandService.getData().subscribe({
        next: (data) => {
          this.cargaladata.brands = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData4(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.cargaladata.selectTypes = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData5(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getUserAgent().subscribe({
        next: (data) => {
          this.cargaladata.agentData = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData6(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clientsService.getData().subscribe({
        next: (data) => {
          const updatedResponse = data.data.map((client: any) => {
            return {
              ...client,
              fullname: `${client.name}${
                client.lastname ? ` ${client.lastname}` : ''
              }`.trim(),
            };
          });
          this.cargaladata.clientData = updatedResponse;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData7(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getStagesMap(this.typeOrders).subscribe({
        next: (data) => {
          this.cargaladata.stages = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  fetchData8(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getDeliveryData().subscribe({
        next: (data) => {
          this.cargaladata.delivery_place = data.data.places;
          this.cargaladata.delivery_status = data.data.status;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  getOrderRing(order: any) {
    this.tab = 'notes';
    this.getOrder(order);
  }
  getOrder(reference: string): void {
    this.alertService.loading('swal_messages.please_wait');
    const nuevaURL = this.location.path();

    var aux = this.typeOrders == '' ? this.typeOrders : this.typeOrders + '/';
    if (!nuevaURL.includes('orders')) {
      const nuevaURL = this.location.path() + '/orders/' + aux + reference;
      this.location.go(nuevaURL);
    } else {
      const index = nuevaURL.indexOf('/');
      this.location.go(
        nuevaURL.substring(0, index) + '/orders/' + aux + reference
      );
    }

    this.order_reference = reference;
    this.orderService.searchOrder(this.order_reference).subscribe({
      next: (data) => {
        this.orderData = data.data;
        let title = data.data.client?.name
          ? data.data.client?.name +
            ' ' +
            (data.data.client?.lastname ? data.data.client?.lastname : '')
          : data.data.broker.reference;
        this.titleService.setTitle(
          this.translate.instant('pages.titles.' + this.title) +
            ' ' +
            title +
            '- DBM'
        );
        this.showForm = true;
        // this.alertService.close();
      },
      error: (error) => {
        this.alertService.error('swal_messages.order_not_found');
      },
    });
  }

  deleteOrder(reference: string): void {
    this.alertService
      .confirmation(
        'swal_messages.warning_confirm',
        'swal_messages.delete_order',
        'swal_messages.confirm'
      )
      .then((result) => {
        if (result.isConfirmed) {
          const currentPage = this.paginator.pageIndex + 1;
          this.order_reference = reference;
          this.orderService.deleteOrder(this.order_reference).subscribe(
            () => {
              this.alertService.successOk('swal_messages.complete_success');
              this.refreshOrdersData();
            },
            (error) => {
              this.alertService.error('swal_messages.order_delete_error');
              console.error('Error al eliminar el cliente:', error);
            }
          );
          this.alertService.close();
        }
      });
  }

  getClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        //this.clients = response.data;
      },
      error: (error) => {},
    });
  }

  detectReference() {
    this.pageSize = Number(this.localStorageService.getItem('per_page'));
    this.sortColumn = String(this.localStorageService.getItem('sortColumn'));
    this.sortDirection = String(
      this.localStorageService.getItem('sortDirection')
    );
    // Capturar el parámetro 'tab'
    this.route.queryParams.subscribe((params) => {
      if (params['tab']) {
        this.tab = params['tab'];
      }
    });

    // Obtener el tipo de cuenta del localStorage
    this.getAccountTypeFromLocalStorage();

    // Obtener la URL actual
    const nuevaURL = this.location.path();

    // Verificar si estamos en la ruta para mostrar el formulario
    this.showForm = nuevaURL.includes('orders/contracts/add');

    // Iterar sobre el mapa de rutas y actualizar typeOrders y title si coincide
    for (const route in this.routesMap) {
      if (nuevaURL.includes(route)) {
        this.typeOrders = this.routesMap[route];
        const nuevaRuta = `/orders/${this.typeOrders}`;
        this.location.go(nuevaRuta);
        this.title = this.typeOrders == '' ? 'all' : this.typeOrders;
        this.showAlertFilterAll =
          this.typeOrders == '' || this.typeOrders == 'cancelled'
            ? false
            : true;
        this.showAlertCancelled =
          this.typeOrders == 'cancelled' || this.showAlertFilterAll == true
            ? false
            : true;
        break; // Salimos del bucle una vez encontrado el match
      }
    }

    // Obtener la referencia de la orden
    let paramReference = this.route.snapshot.paramMap.get('reference');
    if (paramReference) {
      this.order_reference = paramReference;
      const updatedURL = `/orders/${this.typeOrders}/${paramReference}`;
      this.location.go(updatedURL);
      this.getOrder(paramReference);
    }

    this.cargaladata.typeOrders = this.typeOrders;
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex + 1;
    this.localStorageService.setItem('per_page', String(event.pageSize));
    this.pageSize = event.pageSize;
    this.fetchNewData();
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  cerrarFormulario() {
    /* this.titleService.setTitle(
      this.translate.instant('pages.titles.' + this.typeOrders) + ' - DBM'
    ); */
    this.titleService.setTitle('Pedidos - DBM');
    const nuevaURL = this.location.path() + '/orders/' + this.typeOrders;
    const index = nuevaURL.indexOf('/');
    this.location.go(
      nuevaURL.substring(0, index) + '/orders/' + this.typeOrders
    );
    this.assignee = null;
    this.orderData = null;
    this.showForm = false;
    this.showAssigneeForm = false;
    this.document_buttons_chico = false;
  }

  cerrarClienteFormulario() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }

  cerrarAssigneeFormulario() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }
  refrescar() {
    this.fetchData();
  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    };
    return statusColorMap[status] || 'text-new';
  }

  getBadgeColor(
    stage: string,
    require_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!require_action) {
      return statusColorMap['false'];
    }

    if (require_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  getAccountTypeFromLocalStorage(): void {
    this.accountType = localStorage.getItem('account_type');
  }

  refreshOrdersData() {
    this.orderService
      .getFilterData(
        this.typeOrders,
        this.pageNumber,
        this.pageSize,
        this.searchParams,
        this.sortColumn,
        this.sortDirection
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(
              this.localStorageService.getItem('per_page')
            );
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;

          this.showAlertResult(data.meta.total);
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  showAlertResult(count: any) {
    this.showCountFilter = count;
    if (Object.keys(this.searchParams).includes('sent_date[between][]')) {
      this.showCountFilterEst = count;
      this.showAlertFilterEst = true;
      this.showAlertFilterEst_start_date =
        this.searchParams['sent_date[between][]'][0];
      this.showAlertFilterEst_end_date =
        this.searchParams['sent_date[between][]'][1];
      this.showAlertFilterEst_state = 'sent';
      this.data_est = true;
    }

    if (
      Object.keys(this.searchParams).includes('risk_approved_date[between][]')
    ) {
      this.showCountFilterEst = count;
      this.showAlertFilterEst = true;
      this.showAlertFilterEst_start_date =
        this.searchParams['risk_approved_date[between][]'][0];
      this.showAlertFilterEst_end_date =
        this.searchParams['risk_approved_date[between][]'][1];
      this.showAlertFilterEst_state = 'risk_approved';
      this.data_est = true;
    }

    if (
      Object.keys(this.searchParams).includes('contract_signed_date[between][]')
    ) {
      this.showCountFilterEst = count;
      this.showAlertFilterEst = true;
      this.showAlertFilterEst_start_date =
        this.searchParams['contract_signed_date[between][]'][0];
      this.showAlertFilterEst_end_date =
        this.searchParams['contract_signed_date[between][]'][1];
      this.showAlertFilterEst_state = 'contract_signed';
      this.data_est = true;
    }

    if (
      Object.keys(this.searchParams).includes(
        'vehicle_delivered_date[between][]'
      )
    ) {
      this.showCountFilterEst = count;
      this.showAlertFilterEst = true;
      this.showAlertFilterEst_start_date =
        this.searchParams['vehicle_delivered_date[between][]'][0];
      this.showAlertFilterEst_end_date =
        this.searchParams['vehicle_delivered_date[between][]'][1];
      this.showAlertFilterEst_state = 'vehicle_delivered';
      this.data_est = true;
    }

    if (!this.showAlertFilterEst) {
      if (Object.keys(this.searchParams).length === 0) {
        this.showAlertFilter = false;
      } else {
        this.showAlertFilter = true;
      }
    }
  }

  /******* EVENTO DEL FILTRO  *******/
  filterChanged(searchTerm: any) {
    this.alertService.loading('swal_messages.please_wait');
    this.searchParams = searchTerm;
    this.orderService
      .getFilterData(
        this.typeOrders,
        this.pageNumber,
        this.pageSize,
        this.searchParams,
        this.sortColumn,
        this.sortDirection
      )
      .subscribe({
        next: (data) => {
          this.dataSource.data = data.data;
          this.dataSource.sort = this.sort;
          this.pageNumber = data.meta.current_page;
          if (!this.localStorageService.getItem('per_page')) {
            this.localStorageService.setItem('per_page', data.meta.per_page);
            this.pageSize = data.meta.per_page;
          } else {
            this.pageSize = Number(
              this.localStorageService.getItem('per_page')
            );
          }
          this.paginator.pageSizeOptions = [25, 50, 100];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.pageNumber - 1;
          this.paginator.length = data.meta.total;
          this.showAlertResult(data.meta.total);
          this.startAutoFetch();
          this.alertService.close();
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }
  optAdd = 'client';
  openClientForm(optAdd: string) {
    this.document_buttons_chico = true;
    this.showAddClientForm = true;
    this.optAdd = optAdd;
  }
  assignee = null;
  openAssigneeForm(assignee: any) {
    this.assignee = assignee;
    this.document_buttons_chico = true;
    this.showAssigneeForm = true;
  }

  closeClientForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAddClientForm = false;
  }
  closeAssigneeForm() {
    this.assignee = null;
    this.document_buttons_chico = false;
    this.showAssigneeForm = false;
  }
  refreshClients() {
    this.assignee = null;
    this.showAddClientForm = false;
    this.document_buttons_chico = false;
  }
  onSortChange(event: Sort) {
    let column = this.sortMap[String(event.active)];
    if (column == 'actions') {
      if (this.sort) {
        this.sort.direction = ''; // Esto evita que el ordenamiento se aplique visualmente
      }
    } else {
      this.pageNumber = 1;
      this.localStorageService.setItem('sortColumn', column);
      this.localStorageService.setItem(
        'sortDirection',
        String(event.direction)
      );
      this.sortColumn = event.active;
      this.sortDirection = event.direction == '' ? 'asc' : event.direction;
      this.fetchNewData();
    }

    // Implementa la lógica de ordenamiento según tus necesidades
  }

  onSortChange2(event: string) {
    this.sortColumn = this.sortMap[String(event)];
    this.localStorageService.setItem('sortColumn', this.sortColumn);
    if (this.sortDirection == 'asc') {
      this.sortDirection = 'desc';
    } else {
      this.sortDirection = 'asc';
    }

    this.localStorageService.setItem('sortDirection', this.sortDirection);

    this.pageNumber = 1;
    this.fetchNewData();

    // Implementa la lógica de ordenamiento según tus necesidades
  }

  getDirection(): string {
    const directionMap: { [key: string]: string } = {
      asc: 'icon-arrow-up',
      desc: 'icon-arrow-down',
    };

    return directionMap[this.sortDirection] || 'icon-arrow-up';
  }

  showAssigneeCreateForm = false;

  @Output() assigneeCreated = new EventEmitter<any>();
  handleNewAssigneeInClients(newAssignee: any) {
    console.log('Nuevo responsable en clientsComponent:', newAssignee);
    this.assigneeCreated.emit(newAssignee);
  }
  @Input() emitOpenAssigneeCreateForm = new EventEmitter<any>();

  openAssigneeCreateForm() {
    this.document_buttons_chico = true;
    this.showAssigneeCreateForm = true;
    this.emitOpenAssigneeCreateForm.emit(true);
  }

  closeAssigneeCreateForm() {
    this.showAssigneeCreateForm = false;
    this.showForm = true;
    this.refreshClients();
  }
  cerrarFormularioCreateAssignee() {
    this.showAssigneeCreateForm = false;
  }

  getBadgeDeliveryColor(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      logistics: 'text-default',
    };

    return status ? statusColorMap[status] || 'text-default' : 'text-default';
  }
}
