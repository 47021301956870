import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MY_FORMATS } from './date-formats';

export const DATE_PROVIDERS = [

  { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, // Cambiado para España
  {
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    useValue: { useUtc: true }, // Forzar UTC para evitar problemas de desfase
  },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

];
