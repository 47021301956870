import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClientsService } from '../../../../core/services/clients/clients.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectCountryPrefixComponent } from '../../../component/select-country-prefix/select-country-prefix.component';
import { phoneNumberValidator } from '../form-add-client.component';
import { debounceTime, filter } from 'rxjs';

interface FileItem {
  file: File;
  selectOption: string;
  key: string;
}

@Component({
  selector: 'app-form-add-assignee',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    SelectCountryPrefixComponent,
  ],
  templateUrl: './form-add-assignee.component.html',
  styleUrl: './form-add-assignee.component.css',
})
export class FormAddAssigneeComponent {
  @Output() assigneeCreated = new EventEmitter<any>();
  @Output() backButtonClick2 = new EventEmitter<boolean>();
  @Output() clientDuplicateFound = new EventEmitter<string>();
  @Output() closeAssigneeCreateForm = new EventEmitter<void>();
  @Input() document_buttons_chico = false;

  assigneeForm: FormGroup;
  step: number = 1;
  selectedTaxId: string = '';
  showAdditionalFields: boolean = false;
  isDuplicated: boolean = false;

  taxIdExists = false;
  existingClient: any;

  isDragOver = false;
  isUploading = false;
  canProceed = false;
  isCheckingTaxId = false;
  uploadComplete = false;
  uploadFile = true;

  showAlertFilter = false;
  existingClientReference: string = '';

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  files: FileItem[] = [];
  uploadedFiles: File[] = [];

  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();
  maxDateExpiration: Date = new Date(2040, 12, 12);

  showAssigneeForm = false;
  showAssigneeCreateForm = false;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientsService,
    private alertService: AlertService
  ) {
    this.assigneeForm = this.fb.group(
      {
        status: [true],
        type: ['individual', Validators.required],
        tax_id: ['', Validators.required],
        name: ['', Validators.required],
        lastname: [''],
        email: ['', [Validators.required, Validators.email]],
        phone: [''],
        billing_address: ['', Validators.required],
        billing_postal_code: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{4,5}$')],
        ],
        billing_city: ['', Validators.required],
        billing_state: ['', Validators.required],
        phonePrefix: ['+34'],
        birth_date: [null],
        birthplace_country: ['spain'],
        nationality: [''],
        document_expiration_date: [null],
        phoneNumber: ['', Validators.required],
      },
      { validators: phoneNumberValidator() }
    );
  }

  ngOnInit(): void {
    this.assigneeForm
      .get('tax_id')
      ?.valueChanges.pipe(
        debounceTime(200),
        filter((taxId) => !!taxId)
      )
      .subscribe((taxId) => {
        this.checkTaxId(taxId);
      });
  }

  changeBirthplace(birthplace: string) {
    this.assigneeForm.get('birthplace_country')?.setValue(birthplace);
  }

  onDuplicateFound() {
    if (this.existingClientReference) {
      this.clientDuplicateFound.emit(this.existingClientReference);
    }
    this.resetForm();
  }

  emitBackButtonClick() {
    this.resetForm();
    this.backButtonClick2.emit(true);
  }

  changePrefix(event: any) {
    this.assigneeForm.get('phonePrefix')?.setValue(event);
  }

  resetForm(): void {
    this.assigneeForm.reset({
      status: true,
      type: '',
      tax_id: '',
      assignee_id: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      billing_address: '',
      billing_postal_code: '',
      billing_city: '',
      billing_state: '',
      phoneNumber: '',
      phonePrefix: '+34',
      birthplace_country: 'spain',
      nationality: '',
    });
    this.step = 1;
    this.showAdditionalFields = false;
    this.taxIdExists = false;
    this.canProceed = false;
    this.files = [];
    this.changePrefix('+34');
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  resetDuplicateStatus(): void {
    this.taxIdExists = false;
    this.showAlertFilter = false;
    this.isDuplicated = false;
    this.existingClient = null;
    this.existingClientReference = '';
  }

  onTaxIdChange(): void {
    const taxIdControl = this.assigneeForm.get('tax_id');
    if (taxIdControl) {
      const upperTaxId = taxIdControl.value.toUpperCase();
      taxIdControl.setValue(upperTaxId, { emitEvent: false });
      this.checkTaxId(upperTaxId);
    }
  }

  updateCanProceed(): void {
    this.canProceed =
      (!this.taxIdExists && !this.isCheckingTaxId) || this.uploadComplete;
  }

  checkTaxId(taxId: string): void {
    const normalizedTaxId = taxId.toUpperCase();
    this.isCheckingTaxId = true;

    this.clientService.checkTaxId(normalizedTaxId).subscribe({
      next: (response) => {
        if (response.success && response.data?.reference) {
          this.taxIdExists = true;
          this.existingClient = response.data;
          this.showAlertFilter = true;
          this.isDuplicated = true;
          this.existingClientReference = response.data.reference;
          this.canProceed = false;
        } else {
          this.resetDuplicateStatus();
        }
      },
      error: (error) => {
        console.error('Error al verificar el Tax ID:', error);
        this.resetDuplicateStatus();
      },
      complete: () => {
        this.isCheckingTaxId = false;
        this.updateCanProceed();
      },
    });
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      this.handleFilesUpload(droppedFiles);
    }
  }

  handleFilesUpload(files: FileList): void {
    if (files && files.length > 0) {
      const formData = new FormData();
      Array.from(files).forEach((file) => formData.append('file[]', file));

      this.alertService.loading('swal_messages.please_wait');

      this.clientService.uploadDocument(formData).subscribe({
        next: (response) => {
          this.addFiles(files);
          this.alertService.close();

          const clientData = response.data;
          if (clientData.tax_id) {
            this.assigneeForm.patchValue(
              { tax_id: clientData.tax_id },
              { emitEvent: true }
            );
            this.fillFormWithClientData(clientData);
            this.showAdditionalFields = true;
          }
          this.uploadComplete = true;
          this.isUploading = false;
          this.updateCanProceed();
        },
        error: (error) => {
          console.error('Error al subir el documento:', error);
          this.alertService.error(error.error);
          this.fileInput.nativeElement.value = '';
        },
      });
    }
  }

  addFiles(files: FileList): void {
    for (const file of Array.from(files)) {
      if (
        !this.files.some(
          (f) => f.file.name === file.name && f.file.size === file.size
        )
      ) {
        const newFileItem: FileItem = {
          file,
          selectOption: 'defaultOption',
          key: 'defaultOption',
        };
        this.files.push(newFileItem);
      }
    }
  }

  fillFormWithClientData(clientData: any): void {
    if (clientData.tax_id?.startsWith('TEMP-')) {
      this.assigneeForm.patchValue({
        tax_id_temp: clientData.tax_id,
        tax_id: '',
      });
    } else {
      this.assigneeForm.patchValue({
        tax_id: clientData.tax_id,
        tax_id_temp: clientData.tax_id,
      });
    }
    this.assigneeForm.patchValue({
      type: clientData.type || '',
      name: clientData.name || '',
      lastname: clientData.lastname || '',
      email: clientData.email || '',
      phone: clientData.phone || '',
      billing_address: clientData.billing_address || '',
      billing_postal_code: String(clientData.billing_postal_code || ''),
      billing_city: clientData.billing_city || '',
      billing_state: clientData.billing_state || '',
      phonePrefix: clientData.phonePrefix || '+34',
      phoneNumber: clientData.phoneNumber || '',
      document_expiration_date: clientData.document_expiration_date
        ? new Date(clientData.document_expiration_date)
        : null,
      birth_date: clientData.birth_date
        ? new Date(clientData.birth_date)
        : null,
    });

    console.log(clientData.tax_id);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver = false;
  }

  onFileInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files) {
      this.handleFilesUpload(files);
    }
  }

  getThumbnailSrc(file: File): string {
    const patterns = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      pdf: 'application/pdf',
      doc: 'application/msword',
      csv: 'text/csv',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xslx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'word-openxml':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      'xls-openxml':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    for (const [key, mimeType] of Object.entries(patterns)) {
      if (file.type === mimeType) {
        switch (key) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            return `../../../assets/icons/jpg.svg`;
            break;
          case 'pdf':
            return `../../../assets/icons/pdf.svg`;
            break;
          case 'word-openxml':
          case 'doc':
          case 'docx':
            return `../../../assets/icons/doc.svg`;
            break;
          case 'xls-openxml':
          case 'xls':
            return `../../../assets/icons/xls.svg`;
            break;
          default:
            return '../../../assets/icons/file.svg';
            break;
        }
      }
    }
    return '../../../assets/icons/file.svg';
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    this.canProceed = this.files.length > 0;

    if (this.files.length === 0) {
      this.resetForm();
    }
  }

  onNextStep(): void {
    this.selectedTaxId = this.assigneeForm.get('tax_id')?.value || '';
    if (this.selectedTaxId) {
      this.assigneeForm.get('tax_id');
      this.showAdditionalFields = true;
    } else {
      console.warn('El campo Tax ID está vacío');
    }
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.assigneeForm.get('birth_date')?.setValue(adjustedDate);
    }
  }

  onDateChange2(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    if (selectedDate) {
      const adjustedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate()
      );
      this.assigneeForm.get('document_expiration_date')?.setValue(adjustedDate);
    }
  }
  convertToLocalDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  goToForm() {
    this.step = 2;
    this.showAdditionalFields = true;
    this.canProceed = true;
  }

  isFieldInvalid(field: string): any {
    const control = this.assigneeForm.get(field);
    return control?.invalid && control?.touched;
  }

  getFormError(field: string, errorType: string): boolean {
    const control = this.assigneeForm.get(field);
    return control?.errors?.[errorType];
  }

  onBirthplaceCountrySelected(selectedCountryName: string) {
    this.assigneeForm.get('birthplace_country')?.setValue(selectedCountryName);
  }

  onPhonePrefixSelected(selectedPrefix: string) {
    this.assigneeForm.get('phone_prefix')?.setValue(selectedPrefix);
  }
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  birthDateValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const type = form.get('type')?.value;
      const birthDate = form.get('birth_date')?.value;

      if (type !== 'organization' && !birthDate) {
        return { birthDateRequired: true };
      }
      return null;
    };
  }

  onSubmitAssignee() {
    this.alertService.loading('swal_messages.creating_assignee');

    let formData = { ...this.assigneeForm.value };
    formData.tax_id_temp = this.assigneeForm.get('tax_id_temp')?.value;
    formData.tax_id = this.assigneeForm.get('tax_id')?.value;

    const birthDate = this.assigneeForm.get('birth_date')?.value;
    if (birthDate) {
      const formattedDate = this.convertToLocalDate(new Date(birthDate));
      formData.birth_date = formattedDate;
    }

    const expirationDate = this.assigneeForm.get(
      'document_expiration_date'
    )?.value;
    if (expirationDate) {
      const formattedDate = this.convertToLocalDate(new Date(expirationDate));
      formData.document_expiration_date = formattedDate;
    }

    let aux = this.assigneeForm.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.assigneeForm.get('phonePrefix')?.value || '+34';
      const phoneNumber = this.assigneeForm.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;
      formData.phone = combinedPhoneNumber;
    }
    this.clientService.addData(formData).subscribe(
      (response) => {
        this.alertService.successOk('swal_messages.assignee_created');
        this.assigneeCreated.emit(response);
        this.clientService.emitAssigneeCreated(response);
        this.closeAssigneeCreateForm.emit(response);
        this.assigneeForm.reset();
        this.showAssigneeCreateForm = false;
      },
      (error) => {
        this.alertService.error(error.error);
      }
    );
  }
}
