import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tabs-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,

    CommonModule,
  ],
  templateUrl: './tabs-form.component.html',
  styleUrl: './tabs-form.component.css',
})
export class TabsFormComponent {
  @Output() changeForm = new EventEmitter<string>();
  @Input() updatesTabs: any = [];
  @Input() activeFormTab = 'general';
  @Input() role = 'admin';
  @Input() draft = false;
  @Input() document_buttons_chico = false;

  oldForm = 'orders';
  addOrderForm = false;
  constructor(private router: Router, private route: ActivatedRoute) {}

  emitchangeForm($event: any): void {
    this.oldForm = this.activeFormTab;
    this.changeForm.emit($event);
  }
}
