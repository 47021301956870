import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  private apiUrl = environment.apiURL + 'reports/orders-stages';
  private apiUrlEvolution = environment.apiURL + 'reports/evolution';

  constructor(private http: HttpClient) { }

  getData(
    /* pageNumber: number = 1,
    pageSize: number = 10, */
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrl}`;
    return this.http.get(url, {
      params: {
        /* page: pageNumber.toString(),
        pageSize: pageSize.toString(), */
        ...params,
      },
    });

  }

  getDataEvolution(
    pageNumber: number = 1,
    pageSize: number = 10,
    params: any = {}
  ): Observable<any> {
    var url = `${this.apiUrlEvolution}`;
    return this.http.get(url, {
      params: {
        page: pageNumber.toString(),
        pageSize: pageSize.toString(),
        ...params,
      },
    });

  }

}
