import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';

@Component({
  selector: 'app-risk-form',
  standalone: true,
  imports: [ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,

    CommonModule],
  templateUrl: './risk-form.component.html',
  styleUrl: './risk-form.component.css'
})
export class RiskFormComponent {

  @Input() orderData?: any;
  @Input() ladata?: any = [];

  riskForm: FormGroup = new FormGroup({});
  riskStatus: any[] = ['denied', 'approved', 'paused_information', 'paused_guarantee'];

  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService) {
    this.riskForm = this.fb.group({
      risk_result: ['', Validators.required],
      risk_result_notes: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  refrescarFormulario() {
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.riskForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
    }
    this.alertService.close();
  }

  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.riskForm.controls) {
      if (this.riskForm.controls.hasOwnProperty(field)) {
        this.riskForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.riskForm.get(field)?.enable();
      }
    }

  }
  checkUpdateForm():boolean {
    if (this.riskForm.dirty) {
      return true;
    } else {
      return false;
    }
  }
}
