import { CommonModule, Location } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormAddDocumentComponent } from '../../shared/form-add-document/form-add-document.component';
import { SelectCountryPrefixComponent } from '../../component/select-country-prefix/select-country-prefix.component';
import { Title } from '@angular/platform-browser';
import { FinancialDataComponent } from '../financial-data/financial-data.component';
import { TabsFormComponent } from '../tabs-form/tabs-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormClientsOrdersComponent } from '../form-clients-orders/form-clients-orders.component';

@Component({
  selector: 'app-form-edit-client',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgSelectModule,
    FormAddDocumentComponent,
    SelectCountryPrefixComponent,
    FinancialDataComponent,
    TabsFormComponent,
    FormClientsOrdersComponent,
  ],
  templateUrl: './form-edit-client.component.html',
  styleUrl: './form-edit-client.component.css',
})
export class FormEditClientComponent {
  @Input() clientData?: any;
  @Input() selectTypes: any[] = [];
  @Output() backButtonClickEdit = new EventEmitter<boolean>();
  @Output() clientUpdated = new EventEmitter<any>();

  @Input() tab?: any;

  draft = false;

  posiblesTabs: any[] = ['general', 'doc-order', 'orders', 'financ.'];

  updatesTabs: any[] = [];

  activeFormTab = 'general';
  form: FormGroup;

  clients: any[] = [];
  type = 'individual';
  isEditMode: boolean = false;

  minDate: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();
  maxDateExpiration: Date = new Date(2040, 12, 12);

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientService: ClientsService,
    private location: Location,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      type: [''],
      phone: [''],
      billing_address: [''],
      billing_city: [''],
      billing_postal_code: [''],
      billing_state: [''],
      tax_id: ['', Validators.required],
      assignee_id: [''],
      assignee: [''],
      phonePrefix: [''],
      phoneNumber: ['', Validators.required],
      birth_date: [new Date()],
      birthplace_country: [''],
      nationality: [''],
      document_expiration_date: [new Date()],
    });
  }
  @Output() titleUpdated = new EventEmitter<string>();

  ngOnInit() {
    this.setClientTitle();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (tab) {
        this.activeFormTab = tab;
      }
    });
    this.activeFormTab = this.tab;
    if (this.clientData) {
      this.isEditMode = true;
      this.type = this.clientData.type;
            const phone = this.clientData.phone || '';
      const phonePrefixMatch = phone.match(/^\(([^)]+)\)/);
      const phonePrefix = phonePrefixMatch ? phonePrefixMatch[1] : '+34';
      const phoneNumber = phone.replace(
        phonePrefixMatch ? phonePrefixMatch[0] : '',
        ''
      );

      this.setFormValues(this.clientData, phonePrefix, phoneNumber);
      this.loadIndividualClients();
      const title = `Cliente ${this.clientData.name} ${this.clientData.lastname ? ` ${this.clientData.lastname}` : ''
        } - DBM`;

      this.titleService.setTitle(title);
    }
  }

  setFormValues(
    clientData: any,
    phonePrefix: string,
    phoneNumber: string
  ): void {
    this.form.patchValue({
      name: clientData.name || '',
      lastname: clientData.lastname || '',
      email: clientData.email || '',
      type: clientData.type || '',
      tax_id: clientData.tax_id || '',
      billing_address: clientData.billing_address || '',
      billing_city: clientData.billing_city || '',
      billing_postal_code: clientData.billing_postal_code || '',
      billing_state: clientData.billing_state || '',
      assignee_id: clientData.assignee?.reference || '',
      assignee: clientData.assignee?.reference || '',
      phonePrefix: phonePrefix,
      phoneNumber: phoneNumber,
      birthplace_country: this.normalizeCountryName(
        clientData.birthplace_country
      ),
      nationality: clientData.nationality || '',
      birth_date: this.clientData.birth_date
        ? this.convertToLocalDate(new Date(this.clientData.birth_date))
        : null,
      document_expiration_date: this.clientData.document_expiration_date
        ? this.convertToLocalDate(
          new Date(this.clientData.document_expiration_date)
        )
        : null,
    });
    console.log(
      'this.clientData.birthplace_country',
      this.clientData.birthplace_country.toLowerCase()
    );

    this.form.get('type')?.disable();
  }
  normalizeCountryName(country: string): string {
    if (!country) return '';
    return country.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  convertToLocalDate(date: Date): Date {
    const localDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60000
    );
    return new Date(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate()
    );
  }

  emitBackButtonClick() {
    this.location.go('/clients');
    this.backButtonClickEdit.emit(true);
  }
  onClose() {
    this.emitBackButtonClick();
  }

  onSubmit() {
    this.alertService.loading('swal_messages.client_updated_loading');

    let formData = { ...this.form.value };

    let aux = this.form.get('phoneNumber')?.value;
    if (aux) {
      let phonePrefix = this.form.get('phonePrefix')?.value;
      if (!phonePrefix) {
        phonePrefix = '+34';
      }
      const phoneNumber = this.form.get('phoneNumber')?.value;
      const combinedPhoneNumber = `(${phonePrefix})${phoneNumber}`;

      this.form.get('phone')?.setValue(combinedPhoneNumber);
      formData = { ...this.form.value, phone: combinedPhoneNumber };
    }

    this.clientService
      .updateClient(this.clientData.reference, formData)
      .subscribe({
        next: (response) => {
          this.alertService.close();
          this.alertService.successOk('swal_messages.client_updated');
          this.clientUpdated.emit();
        },
        error: (error) => {
          this.alertService.error(error.error).then((result) => {
            if (result.isConfirmed) {
            }
          });
        },
      });
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  filteredClients: Array<{ name: string; reference: string; tax_id: string }> =
    [];

  loadIndividualClients(): void {
    this.clientService.getData().subscribe({
      next: (response) => {
        if (response.success) {
          this.clients = response.data;
          this.clients = this.clients.filter(
            (client) =>
              client.type === 'individual' || client.type === 'self-employed'
          );
          this.filteredClients = this.clients.map((client) => ({
            ...client,
            displayName: `${client.name} ${client.lastname || ''}`.trim(),
          }));

          if (this.clientData.assignee) {
            let singleClient = {
              ...this.clientData.assignee,
              displayName: `${this.clientData.assignee.name}${this.clientData.assignee.lastname
                ? ` ${this.clientData.assignee.lastname}`
                : ''
                }`.trim(),
            };

            const clientExists = this.filteredClients.some(
              (client) => client.reference === singleClient.reference
            );
            if (!clientExists) {
              this.filteredClients.push(singleClient);
            }
          }
        } else {
          console.error('Error en la respuesta del servidor:', response);
        }
      },
      error: (error) =>
        console.error('Hubo un error al obtener los datos:', error),
    });
  }

  /** Documents **/
  docFiles: any[] = [];
  addDocumentComponent?: FormAddDocumentComponent;

  @Output() emitCloseClientForm = new EventEmitter<boolean>();

  actualizarEstadoArchivos(nuevoArrayArchivos: any[]) {
    this.docFiles = nuevoArrayArchivos;
  }


  changeForm(form: string) {
    if (this.document_buttons_chico) {
      this.emitCloseClientForm.emit(true);
      this.emitCloseAssigneeForm.emit(true);
    }
    if (this.docFiles.length > 0) {
      this.alertService
        .confirmation2(
          'swal_messages.files_charged_message_' + this.activeFormTab,
          'swal_messages.files_charged',
          'swal_messages.confirm',
          'swal_messages.back'
        )
        .then((result) => {
          if (result.isConfirmed) {
            if (this.addDocumentComponent) {
              this.addDocumentComponent.actualizarArchivos([]);
            }
            this.updateURL(form);
            this.activeFormTab = form;
          }
        });
    } else {
      this.updateURL(form);
      this.activeFormTab = form;
    }
    this.tab = this.activeFormTab;
  }

  updateURL(form: string) {
    const currentPath = this.location.path();
    const newURL = currentPath.includes('tab=')
      ? currentPath.replace(/tab=[^&]+/, `tab=${form}`)
      : `${currentPath}${currentPath.includes('?') ? '&' : '?'}tab=${form}`;
    this.location.go(newURL);
  }

  changePrefix(newPrefix: string) {
    this.form.patchValue({ phonePrefix: newPrefix });
  }

  changeBirthplace(birthplace: string) {
    this.form.get('birthplace_country')?.setValue(birthplace);
  }

  /******** Assignee ***** */

  @Output() emitCloseAssigneeForm = new EventEmitter<boolean>();
  @Output() emitOpenAssigneeForm = new EventEmitter<boolean>();

  /***************/

  financialDataComponent?: FinancialDataComponent;

  @Input() document_buttons_chico = false;

  @Output() emitOpenClientForm = new EventEmitter<boolean>();

  openAssigneeForm() {
    this.document_buttons_chico = true;
    this.emitOpenAssigneeForm.emit(this.clientData?.assignee);
  }

  closeAssigneeForm() {
    this.document_buttons_chico = false;
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    if (selectedDate) {
      console.log('Fecha seleccionada:', selectedDate);
      this.form.get('birth_date')?.setValue(selectedDate);
    }
  }

  onDateChange2(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    if (selectedDate) {
      console.log('Fecha seleccionada:', selectedDate);
      this.form.get('document_expiration_date')?.setValue(selectedDate);
    }
  }

  @Output() emitOpenOrderForm = new EventEmitter<any>();
  @Output() emitOpenOrderForm2 = new EventEmitter<any>();

  openAddOrderForm() {
    this.emitOpenOrderForm.emit(this.clientData.reference);
    this.tab = 'orders';
  }
  openAddOrderForm2() {
    this.emitOpenOrderForm2.emit(this.clientData.reference);
    this.tab = 'orders';
  }

  /************* */

  onOrderFormClosed() {
    this.refreshClientData();
  }


  refreshClientData() {
    this.alertService.loading('swal_messages.please_wait');
    this.clientService
      .getClientByReference(this.clientData.reference)
      .subscribe({
        next: (data) => {
          this.alertService.close();
          this.clientData = data.data;
          this.activeFormTab = 'client';
        },
        error: (error) => {
          console.error('Error al obtener el cliente:', error);
          this.alertService.error('Error al refrescar los datos del cliente');
        },
      });
  }


  @Output() emitTitle = new EventEmitter<string>();

  setClientTitle() {
    if (this.clientData) {
      const title = `Cliente ${this.clientData.name} ${this.clientData.lastname ? ` ${this.clientData.lastname}` : ''
        } - DBM`;
      this.emitTitle.emit(title);
    }
  }

  @Input() backButtonClick2: any;

  updateOrders() {
    console.log('Órdenes actualizadas:', this.clientData.orders);
  }

  onBirthplaceCountrySelected(selectedCountryName: string) {
    this.form.get('birthplace_country')?.setValue(selectedCountryName);
  }
}
