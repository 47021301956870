import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { ClientsService } from '../../../../core/services/clients/clients.service';
import { OrdersService } from '../../../../core/services/orders/orders.service';
import { Subscription } from 'rxjs';

interface ClientData {
  reference: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  tax_id: string;
  fullname: string;
}

@Component({
  selector: 'app-client-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,

    CommonModule,
  ],
  templateUrl: './client-form.component.html',
  styleUrl: './client-form.component.css',
})
export class ClientFormComponent {
  @Output() emitOpenAssigneeForm = new EventEmitter<boolean>();

  @Output() emitOpenClientForm = new EventEmitter<string>();

  @Input() orderData?: any;
  @Input() ladata?: any = [];
  updatesTabs: any[] = [];

  clientForm: FormGroup = new FormGroup({});

  optionsVisibleClient = false;
  clientSearchTerm = '';
  displayTextClient = '';
  clearableClient = true;
  isClientInputDisabled: boolean = true;
  client_selector_spinner = false;
  filteredClients: any[] = [];

  optionsVisibleGuarantee = false;
  guaranteeSearchTerm = '';
  displayTextGuarantee = '';
  clearableGuarantee = true;
  isGuaranteeInputDisabled: boolean = true;
  filteredGuarantee: any[] = [];

  clientData: ClientData[] = [];
  searchParams: any = {};
  clientAlreadyUpdated = false;

  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private clientsService: ClientsService,
    private orderService: OrdersService
  ) {
    this.clientForm = this.fb.group({
      client_id: ['', Validators.required],
    });
  }

  private clientSubscription!: Subscription;

  ngOnInit() {
    this.clientSubscription = this.clientsService.clientCreated$.subscribe(
      (newClient) => {
        if (newClient && this.orderData && this.orderData.reference) {
          this.assignClientToOrder(newClient);
        } else {
        }
      }
    );
  }

  assignClientToOrder(newClient: any) {
    this.filteredClients = [...this.filteredClients, newClient];

    this.clientForm.patchValue({
      client_id: newClient.reference,
    });

    this.displayTextClient = `${newClient.name} ${newClient.lastname || ''}`;
    this.updateOrderWithNewClient(newClient);
    this.clientsService.resetClient();
  }

  updateOrderWithNewClient(newClient: any) {
    const modifiedFields = {
      client_id: newClient.reference,
    };

    this.orderService
      .updateData(this.orderData.reference, modifiedFields)
      .subscribe({
        next: (data) => {
          this.alertService.successOk('swal_messages.order_updated');
          this.orderData = data.data;
          this.updatesTabs = [];
        },
        error: (error) => {
          this.alertService.error(error.error);
        },
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }

      if (changes['orderData']) {
        if (this.orderData) {
          this.refrescarFormulario();
        }
      }
    }
  }

  refrescarFormulario() {
    +this.setVariables();
    this.updateFieldAccessibility();
    for (const key in this.orderData) {
      const control = this.clientForm.controls[key];
      const value = this.orderData[key];
      if (control) {
        control.setValue(value);
      }
    }

    if (this.orderData?.client) {
      this.displayTextClient = `${this.orderData?.client.name} ${this.orderData?.client.lastname}`;
    }
    this.alertService.close();
  }

  updateFieldAccessibility() {
    var rules = this.orderData?.rules;
    for (const field in this.clientForm.controls) {
      if (this.clientForm.controls.hasOwnProperty(field)) {
        this.clientForm.get(field)?.disable();
      }
    }
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.clientForm.get(field)?.enable();
      }
    }
    this.isClientInputDisabled =
      this.clientForm.get('client_id')?.disabled ?? false;
  }

  setVariables() {
    this.filteredClients = this.orderData?.client
      ? (this.filteredClients = this.addClientIfNotExists(
          this.orderData?.client,
          this.ladata.clientData
        ))
      : this.ladata.clientData;

    // this.client_reference = this.orderData?.client?.reference;
    // this.client = this.orderData?.client;
    // this.guarantee = this.orderData?.guarantee;
    // this.guarantee_reference = this.orderData?.guarantee?.reference;
  }

  addClientIfNotExists(
    singleClient: ClientData,
    clientArray: ClientData[]
  ): ClientData[] {
    const clientExists = clientArray.some((client) =>
      this.areClientsEqual(client, singleClient)
    );
    if (!clientExists) {
      singleClient = {
        ...singleClient,
        fullname: `${singleClient.name}${
          singleClient.lastname ? ` ${singleClient.lastname}` : ''
        }`.trim(),
      };
      clientArray.push(singleClient);
    }
    return clientArray;
  }

  areClientsEqual(client1: ClientData, client2: ClientData): boolean {
    return client1.reference === client2.reference;
  }

  onFocus(term: string) {
    if (term === 'client') {
      this.optionsVisibleClient = true;
    }
    if (term === 'guarantee') {
      this.optionsVisibleGuarantee = true;
    }
  }

  hideOptions() {
    this.optionsVisibleClient = false;
    this.optionsVisibleGuarantee = false;
  }

  filterClients(term: string): ClientData[] {
    term = term.toLowerCase().trim();
    return this.clientData.filter((client) => {
      const nameMatches = client.name.toLowerCase().includes(term);
      const lastnameMatches =
        client.lastname && client.lastname.toLowerCase().includes(term);
      const taxIdMatches = client.tax_id.toLowerCase().includes(term);
      return nameMatches || lastnameMatches || taxIdMatches;
    });
  }

  searchClients(term: any) {
    this.client_selector_spinner = true;
    this.clientSearchTerm = term.value;
    let search = term.value;
    if (this.ladata && this.ladata.clientData) {
      this.filteredClients = this.ladata.clientData.filter((clientData: any) =>
        clientData.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    this.filterClients.length < 3 ? this.fetchClient(search) : null;
  }

  onSelectClient(event: any) {
    this.clientForm.get('client_id')?.setValue(event.reference);
    this.clientForm.get('client_id')?.markAsDirty();
    this.displayTextClient = `${event.name} ${event.lastname}`;
    this.optionsVisibleClient = false;
  }

  fetchClient(term: string): void {
    this.searchParams = {};
    this.searchParams['search'] = `%${term}%`;
    this.clientsService.searchClients(term).subscribe({
      next: (data) => {
        const updatedResponse = data.data.map((client: any) => {
          return {
            ...client,
            fullname: `${client.name}${
              client.lastname ? ` ${client.lastname}` : ''
            }`.trim(),
          };
        });
        this.filteredClients = updatedResponse;
        this.filteredGuarantee = updatedResponse;
        this.client_selector_spinner = false;
      },
      error: (error) => {},
    });
  }

  checkUpdateForm(): boolean {
    if (this.clientForm.dirty) {
      return true;
    } else {
      return false;
    }
  }
  //EMITER
  openAssigneeForm() {
    this.emitOpenAssigneeForm.emit(this.orderData?.client?.assignee);
  }

  openClientForm(optAdd: string) {
    this.emitOpenClientForm.emit(optAdd);
  }

  //OTHER FUNCTIONS
  copyToClipboard(value: string, event: Event): void {
    // Convertir el valor a mayúsculas y eliminar acentos
    const transformedValue = this.removeAccents(value.toUpperCase());

    // Crear un textarea para copiar el valor
    const textarea = document.createElement('textarea');
    textarea.value = transformedValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  // Función para eliminar acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  extractPhoneNumber(phone: string): string {
    const regex = /\)\s*(\d+)/;
    const match = phone.match(regex);
    return match ? match[1] : ''; // Si encuentra el patrón, devuelve el número, de lo contrario devuelve una cadena vacía
  }
}
